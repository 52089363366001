import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ValidatorForm } from "react-form-validator-core";
import WithoutMsgValidation from "../../../../Forms/FormLayouts/WithoutMsgValidation";
import { CONSTANTS } from "../../../../../helpers/constants";
import { Typeahead } from "react-bootstrap-typeahead";
import { Input } from "reactstrap";

const EditQuestion = ({ visible, currentData, closeHandler, successCallback, failCallback }) => {
  const [questionData, setQuestionData] = useState({});
  const [checkEntities, setCheckEntities] = useState(false);
  const [entitiesList, setEntitiesList] = useState([]);

  const getEntities = async () => {
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/entity`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': CONSTANTS.TOKEN
      }
    });
    const res = await req.json();
    if (res.success && res.data.length) setEntitiesList(res.data);
  }

  useEffect(() => {
    (async () => {
      if (!entitiesList.length) await getEntities();
      const entities = currentData.question_allowed_entities ? JSON.parse(currentData.question_allowed_entities) : null;
      setQuestionData({
        questionText: currentData.question_text,
        showInEntities: entities
      });
      setCheckEntities((entities && entities.length) || false);
    })()
  }, [currentData])

  useEffect(() => {
    if (!checkEntities) setQuestionData({ ...questionData, showInEntities: [] });
  }, [checkEntities])

  const handleClose = () => {
    closeHandler();
  }
  const handleUpdateQuestion = async () => {
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/participante_questions/${currentData.id}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': CONSTANTS.TOKEN
      },
      body: JSON.stringify({
        question_text: questionData?.questionText,
        question_allowed_entities: questionData?.showInEntities && questionData?.showInEntities.length ? JSON.stringify(questionData?.showInEntities) : null
      })
    });
    const res = await req.json();
    res.success ? successCallback({ ...questionData, id: currentData.id }) : failCallback(res);
  }


  return (
    <Modal show={visible} onHide={handleClose} onEscapeKeyDown={handleClose} onExit={handleClose}>
      <ValidatorForm instantValidate={true} onSubmit={handleUpdateQuestion} className="p-t-15">
        <Modal.Header closeButton>
          <h4>Editar pregunta</h4>
        </Modal.Header>
        <Modal.Body>
          <p className="small-text">{currentData.question_key}</p>
          {currentData.question_type !== 'base' &&
            <div className="row">
              <div className="col-md-12">
                <WithoutMsgValidation
                  onChange={(e) => setQuestionData({ ...questionData, questionText: e.target.value })} value={questionData?.questionText || ''}
                  name="questionText" placeholder="Texto de la pregunta" type="text"
                  validators={["required"]} errorMessages={["Este campo es obligatorio"]} className={"form-control"} label="Pregunta" require="true"
                />
              </div>
            </div>
          }
          <div className="row">
            <div className="col-md-12 m-b-10">
              <div className="form-check form-check-inline complete">
                <Input type="checkbox" name="conditionalEntityCheck" id="conditionalEntityCheck" checked={checkEntities} onChange={() => setCheckEntities(!checkEntities)} />
                <label htmlFor="conditionalEntityCheck">Activación condicional por entidad</label>
              </div>
              {checkEntities &&
                <Typeahead id="conditionalEntityList" multiple options={entitiesList.map(entity => ({ value: entity.entity_id, label: entity.entity_name }))}
                  defaultSelected={questionData.showInEntities ? questionData.showInEntities.map(id => entitiesList.filter(entity => id === entity.entity_id ?? entity).map(obj => ({ value: obj.entity_id, label: obj.entity_name }))[0]) : []}
                  onChange={(e) => setQuestionData({ ...questionData, showInEntities: e.map(option => option.value) })}
                  placeholder="Seleccionar entidad(es)..."
                />
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary btn-cons" type="submit">Guardar</button>
          <div className="btn btn-cons" onClick={closeHandler}>Cancelar</div>
        </Modal.Footer>
      </ValidatorForm>
    </Modal>
  );
};

export default EditQuestion;
