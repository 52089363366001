import React, { useState, useEffect } from "react";
import './style.css'

function PaginatedTabs({ dynamicForm, tabIdx, setTabActive, setTabIdx, validations }) {
  const [paginationItems, setPaginationItems] = useState([]);

  useEffect(() => {
    setPaginationTabs(dynamicForm);
  }, []);

  const setPaginationTabs = (group) => {
    const items = [];
    Object.keys(group).map((key, index) => {
        items.push(key);
    });
    setPaginationItems(items);
  };

  return paginationItems && (
    <React.Fragment>
        <div className="paginationDots">
        {Object.keys(dynamicForm).map((key, index) => (
            <li
            className={`ml-1 nav-item circles-list-item ${
                tabIdx == index + 1 ? "active" : ""
            }`}
            onClick={() => {
                setTabActive(key);
                setTabIdx(index + 1);
                // validations();
            }}
            >
            <a
                className={`font-weight-bold d-flex align-items-center ${
                tabIdx == index + 1 ? "circle-active" : "circle-inactive"
                }`}
                data-toggle="tab"
                href="#"
                onClick={(e) => e.preventDefault()}
                data-target={`tab${key}`}
                role="tab"
                aria-selected={tabIdx == index ? "true" : "false"}
            >
                <div className="col p-2 d-flex">
                    <span className="col-11" style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', color: '#000', textAlign:'left'}}>{key}</span>
                    <span className="col circle-number-tab">{index+1}</span>
                </div>
            </a>
            </li>
        ))}
        </div>
        <div className="d-flex justify-content-center paginationArrowsContainer">
            <li
                className={`nav-item circles-list-item`}
                style={{marginTop: '0.5rem'}}
                onClick={() => {
                    if(tabIdx >= 1){
                        setTabIdx(tabIdx - 1);
                        setTabActive(paginationItems[tabIdx-2]);
                    }
                    if(tabIdx <= 1){
                        setTabIdx(1);
                        setTabActive(paginationItems[0])
                    }
                }}
                >
                <a
                    className={`font-weight-bold d-flex align-items-center circle-inactive`}
                    data-toggle="tab"
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    role="tab"
                >
                    <div className="col">
                    <span className="circle-number-tab">{"<"}</span>
                    </div>
                </a>
            </li>
            <li
                className={`nav-item circles-list-item`}
                style={{marginTop: '0.5rem'}}
                onClick={() => {
                    if(tabIdx < paginationItems.length){
                        // validations();
                        setTabIdx(tabIdx + 1);
                        setTabActive(paginationItems[tabIdx]);
                    }else{
                        setTabIdx(paginationItems.length);
                        setTabActive(paginationItems[paginationItems.length-1])
                    }
                }}
                >
                <a
                    className={`font-weight-bold d-flex align-items-center circle-inactive`}
                    data-toggle="tab"
                    href="#"
                    onClick={(e) => e.preventDefault()}
                    role="tab"
                >
                    <div className="col">
                    <span className="circle-number-tab">{">"}</span>
                    </div>
                </a>
            </li>
        </div>
    </React.Fragment>
  )
}

export default PaginatedTabs;
