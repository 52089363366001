import React, { useEffect, useState } from "react";

import Select from "react-select";
import { CONSTANTS } from "../../../helpers/constants";

const companyOptions = [];

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const CompaniesList = ({ setCompany }) => {
  useEffect(() => {
    const getCompanies = async () => {
      const fetchCompanies = await fetch(`${CONSTANTS.base_url}${CONSTANTS.api_url}/data/company`)
      const result = await fetchCompanies.json();
      result.data.forEach((company) => {
        companyOptions.push({
          value: company.company_id,
          label: company.company_name
        })
      })
    }
    getCompanies();
  }, [])

  return (
    <Select
      options={companyOptions}
      placeholder={"Selecciona la entidad a la que perteneces"}
      formatGroupLabel={formatGroupLabel}
      onChange={(e) => setCompany(e.value)}
      required={true}
    />
  );
};

export default CompaniesList;
