import React, { useState, useEffect } from "react";
import { isLogged } from "../helpers/utils";
import Dashboard from "../components/Theme-Condensed/Dashboard";
import Login from "../components/Extra/Login";
import Social from "../components/Social";
import Calendar from "../components/Calendar/Basic";
import CalendarLang from "../components/Calendar/Languages";
import CalendarMonth from "../components/Calendar/Month";
import CalendarLazy from "../components/Calendar/Lazyload";
import CalendarDocs from "../components/Calendar/Documentation";
import Meddi from "../components/Forms/Meddi";
import Participants from "../components/Forms/Participants";
import Users from "../components/Forms/Users";
import Entities from '../components/Forms/Entities';
import SistemaApoyos from "../components/Forms/SistemaApoyos";
import FormElement from "../components/Forms/Elements";
import FormWizard from "../components/Forms/Wizard";
import UserProfile from "../views/user/Profile";
import TableBasic from "../components/Tables/Basic";
import TableForms from "../components/Tables/Admin/Forms";
import TableFormParticipante from "../components/Tables/Admin/Participante";
import TableData from "../components/Tables/Data";
import Dashboards from "../components/Dashboard";
import UserDashboard from "../components/Theme-Condensed/UserDashboard";
import ExtraLogin from "../components/Extra/Login";
import EntityDashboard from "../components/Theme-Condensed/EntityDashboard";
import ExtraRegister from "../components/Extra/register";
import Header from "../components/Theme-Condensed/Header";
import Sidebar from "../components/Theme-Condensed/Sidebar";
import Search from "../components/Search";
import Footer from "../components/Footer";
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import LandingHome from "../landing";

const Condensed = ({ location }) => {
  let path = location.pathname;
  const [toggleInboxHeader, setToggleInboxHeader] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    window.addEventListener("resize", (e) => {
      setToggleInboxHeader(false);
    });
    return () => window.removeEventListener("resize", null);
  });

  useEffect(() => {
    (async () => {
      const logged = await isLogged();
      setLoggedIn(logged);
      if (!logged && path !== "/" && path !== "/condensed/extra/register" && path !== '/login')
        window.location.href = "/";
      if (logged) setUser(JSON.parse(sessionStorage.getItem("user")));
    })();
  }, []);

  return loggedIn ? (
    <div>
      {!path.includes("boxed_layout") &&
        !path.includes("/login") &&
        !path.includes("/register") &&
        !path.includes("/lock_screen") &&
        !path.includes("/404") &&
        !path.includes("/landing") &&
        !path.includes("/500") && (
          <div>
            <Header
              location={location}
              inboxHeader={toggleInboxHeader}
              setInboxHeader={(value) => setToggleInboxHeader(value)}
              user={user}
            />
            <Sidebar location={location} user={user} />
          </div>
        )}

      {/* LANDING PAGE */}
      <Route exact={true} path="/" component={LandingHome} />

      <Route exact={true} path="/login" component={Login} />
      <Route exact={true} path="/home" component={Dashboard} />
      <Route exact={true} path="/usuarios" component={UserDashboard} />
      <Route exact={true} path="/entidades" component={EntityDashboard} />
      <Route path="/participants_form" component={Participants} />
      <Route path="/users_form" component={Users} /> 
      <Route path="/entities_form" component={Entities} />
      {/* <Route path="/condensed/sistemaApoyos_form" component={SistemaApoyos} /> */}
      <Route path="/sistemaApoyos_form" component={SistemaApoyos} />
      <Route path="/matriz_meddi" component={Meddi} />
      <Route path="/register" component={ExtraRegister} />
      <Route path="/admin/forms" component={TableForms} />
      <Route path="/condensed/calendar/basic" component={Calendar} />
      <Route path="/condensed/calendar/languages" component={CalendarLang} />
      <Route path="/condensed/calendar/Month" component={CalendarMonth} />
      <Route path="/condensed/calendar/lazyload" component={CalendarLazy} />
      <Route path="/condensed/calendar_docs" component={CalendarDocs} />
      <Route path="/condensed/form_wizard" component={FormWizard} />
      <Route path="/condensed/form_elements" component={FormElement} />
      <Route path="/condensed/social" component={Social} />
      <Route path="/condensed/extra/login" component={ExtraLogin} />
      <Route path="/condensed/basic_tables" component={TableBasic} />
      <Route path="/condensed/data_tables" component={TableData} />

      {/* USER ROUTES */}
      <Route path="/user/profile" component={UserProfile} />
      {/* USER ROUTES */}

      {/* ADMIN ROUTES */}
                              <Route exact path="/condensed/admin/forms/participante" component={TableFormParticipante} />
      <Route path="/condensed/admin/forms" component={TableForms} />
      {/* ADMIN ROUTES */}

      {/* ADMIN DASHBOARDS */}
      <Route path="/dashboards" component={Dashboards} />

      {/* ADMIN DASHBOARDS */}
      <Route path="/condensed/admin/dashboards" component={Dashboards} />

      {sessionStorage.getItem("logged") && !path.includes("/landing") && (
        <React.Fragment>
          <Footer />
        </React.Fragment>
      )}
    </div>
  ) : (
    <div>
      <Route exact={true} path="/login" component={Login} />
      {/* LANDING PAGE */}
      <Route exact={true} path="/" component={LandingHome} />
      <Route path="/condensed/extra/register" component={ExtraRegister} />
    </div>
  );
};

export default withRouter(Condensed);