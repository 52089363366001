import React, { useEffect, useState } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport, Search } from "react-bootstrap-table2-toolkit";
import DataTable from "../../DataTable";
import PageBreadcrumb from "../../../UIElements/Breadcrumb";
import PageContainer from "../../../UIElements/Containers";
import PageScroll from "../../../UIElements/PageScroll";
import FlipBarNotifyModule from '../../../UIElements/Notification/FlipBarNotification/FlipBarNotifyModule';
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "../../style.css";
import { CONSTANTS } from "../../../../helpers/constants";
import NewFormModal from "./Modals/NewForm";
import NewQuestionModal from "./Modals/NewQuestion";
import EditFormModal from "./Modals/EditForm";
import ConfirmDelete from "./Modals/ConfirmDelete";
import ViewFormModal from "./Modals/ViewForm";

const content = ({ path }) => {
  const TABLE_COLUMNS = [
    {
      dataField: 'form_id',
      text: 'ID',
      headerAlign: 'center',
      align: 'center',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      editable: false
    }, {
      dataField: 'form_name',
      text: 'NOMBRE',
      headerAlign: 'center',
      align: 'center',
      headerStyle: { backgroundColor: '#f0f0f073' },
      headerTitle: true,
      sort: true,
      editable: false
    }, {
      dataField: 'form_description',
      text: 'DESCRIPCIÓN',
      headerAlign: 'center',
      align: 'center',
      headerStyle: { backgroundColor: '#f0f0f073' },
      headerTitle: true,
      editable: false
    }, {
      dataField: 'form_type',
      text: 'TIPO',
      headerAlign: 'center',
      align: 'center',
      headerStyle: { backgroundColor: '#f0f0f073' },
      headerTitle: true,
      sort: true,
      editable: false
    }, {
      dataField: 'Questions.length',
      text: 'PREGUNTAS',
      headerAlign: 'center',
      align: 'center',
      headerStyle: { backgroundColor: '#f0f0f073' },
      headerTitle: true,
      sort: true,
      editable: false
    }, {
      dataField: '',
      text: 'ACTIONS',
      headerAlign: 'center',
      align: 'center',
      headerStyle: { backgroundColor: '#f0f0f073' },
      headerTitle: true,
      editable: false,
      formatter: (_cell, row) => {
        return (
          <div className="dropdown d-lg-block d-none">
            <button className="btn btn-default" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="actions dropdown">
              <i className="pg-icon">more_vertical</i>
            </button>
            <div className="dropdown-menu" role="menu">
              <a href="#" onClick={() => handleOpenViewForm(row)} className="dropdown-item"><i className="pg-icon">eye</i> Ver</a>
              {row.user_id === JSON.parse(sessionStorage.getItem('user')).id || JSON.parse(sessionStorage.getItem('user')).rol === 'supersu' || JSON.parse(sessionStorage.getItem('user')).rol === 'admin' && row.form_id !== 2 ?
                <>
                  <a href="#" onClick={() => handleOpenEditForm(row)} className="dropdown-item"><i className="pg-icon">edit</i> Editar</a>
                  <a href="#" onClick={() => handleOpenCreateQuestion(row)} className="dropdown-item"><i className="pg-icon">add</i> Agregar preguntas</a>
                  <a href="#" onClick={() => handleOpenConfirmDelete(row)} className="dropdown-item"><i className="pg-icon">trash_alt</i> Eliminar</a>
                </> : null
              }
            </div>
          </div>
        )
      }
    }
  ];
  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;

  const [forbidden, setForbidden] = useState(false);
  const [showNewFormModal, setShowNewFormModal] = useState(false);
  const [showNewQuestionModal, setShowNewQuestionModal] = useState(false);
  const [showEditFormModal, setShowEditFormModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showViewFormModal, setShowViewFormModal] = useState(false);
  const [currentFormData, setCurrentFormData] = useState({});
  const [forms, setForms] = useState([]);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    getForms();
  }, [])

  useEffect(() => {
    if (notification && notification.timeout) {
      setTimeout(() => {
        setNotification(null);
      }, notification.timeout);
    }
  }, [notification])

  const getForms = async () => {
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/form`, {
      headers: {
        'x-access-token': CONSTANTS.TOKEN
      }
    });
    const res = await req.json();
    if (req.status === 403) setForbidden(true);
    if (res.success) setForms(res.data);
  }

  const handleSuccessCreateForm = (res) => {
    setShowNewFormModal(false);
    setForms([...forms, { ...res.data, Questions: [] }]);
    setNotification({ message: res.mensaje, timeout: 5000 });
  }
  const handleFailCreateForm = (_data) => {
    setShowNewFormModal(false);
    setNotification({ message: 'Error al crear el formulario', type: 'error', timeout: 5000 });
  }
  const handleOpenCreateQuestion = (data) => {
    setCurrentFormData(data);
    setShowNewQuestionModal(true);
  }
  const handleCloseCreateQuestion = () => {
    setCurrentFormData({});
    setShowNewQuestionModal(false);
  }
  const handleSuccessCreateQuestion = (data) => {
    setShowNewQuestionModal(false);
    let newArr = Array.from(forms);
    newArr.find(form => form.form_id === data.form_id).Questions.push(data);
    setForms(newArr);
    setCurrentFormData({});
    setNotification({ message: 'Pregunta agregada con exito', timeout: 5000 });
  }
  const handleFailCreateQuestion = (_data) => {
    setShowNewQuestionModal(false);
    setNotification({ message: 'Error al crear la pregunta', type: 'error', timeout: 5000 });
  }
  const handleOpenEditForm = (data) => {
    setCurrentFormData(data);
    setShowEditFormModal(true);
  }
  const handleCloseEditForm = () => {
    setCurrentFormData({});
    setShowEditFormModal(false);
  }
  const handleSuccessEditForm = (data) => {
    setShowEditFormModal(false);
    setForms(forms.map(form => form.form_id !== data.formId ? form : {
      ...form,
      form_name: data.formName,
      form_description: data.formDescription,
      form_type: data.formType
    }));
    setCurrentFormData({});
    setNotification({ message: 'Formulario actualizado con exito', timeout: 5000 });
  }
  const handleFailEditForm = (_data) => {
    setShowEditFormModal(false);
    setNotification({ message: 'Error al editar el formulario', type: 'error', timeout: 5000 });
  }
  const handleOpenConfirmDelete = (data) => {
    setCurrentFormData(data);
    setShowConfirmDeleteModal(true);
  }
  const handleCloseConfirmDelete = () => {
    setCurrentFormData({});
    setShowConfirmDeleteModal(false);
  }
  const handleSuccessConfirmDelete = (data) => {
    setShowConfirmDeleteModal(false);
    setForms(forms.filter(({ form_id }) => form_id !== data.form_id));
    setCurrentFormData({});
    setNotification({ message: 'Formulario eliminado con exito', timeout: 5000 });
  }
  const handleFailConfirmDelete = (_data) => {
    setShowConfirmDeleteModal(false);
    setNotification({ message: 'Error al eliminar el formulario', type: 'error', timeout: 5000 });
  }
  const handleOpenViewForm = (data) => {
    console.log("open-view-form", data)
    setCurrentFormData(data);
    setShowViewFormModal(true);
  }
  const handleCloseViewForm = () => {
    setCurrentFormData({});
    setShowViewFormModal(false);
  }

  return (
    <div className="page-content-wrapper">
      <div className="content" style={{ paddingTop: path && path.includes("/executive") ? "170px" : path.includes("/casual") ? "100px" : "60px" }}>
        {notification && <FlipBarNotifyModule notifications={[{ type: notification.type || 'success', desc: notification.message }]} position={notification.position || 'top-right'} />}
        <NewFormModal visible={showNewFormModal} closeHandler={() => setShowNewFormModal(false)} successCallback={handleSuccessCreateForm} failCallback={handleFailCreateForm} />
        <NewQuestionModal visible={showNewQuestionModal} currentForm={currentFormData} closeHandler={handleCloseCreateQuestion} successCallback={handleSuccessCreateQuestion} failCallback={handleFailCreateQuestion} />
        <EditFormModal visible={showEditFormModal} currentForm={currentFormData} closeHandler={handleCloseEditForm} successCallback={handleSuccessEditForm} failCallback={handleFailEditForm} />
        <ConfirmDelete visible={showConfirmDeleteModal} currentForm={currentFormData} closeHandler={handleCloseConfirmDelete} successCallback={handleSuccessConfirmDelete} failCallback={handleFailConfirmDelete} />
        <ViewFormModal visible={showViewFormModal} currentForm={currentFormData} closeHandler={handleCloseViewForm} handleUpdater={getForms} />

        <div className="jumbotron" data-pages="parallax">
          <PageContainer className={path.includes("/executive") || path.includes("/casual") ? "container sm-p-l-0 sm-p-r-0" : "sm-p-l-0 sm-p-r-0 "}>
            <PageScroll>
              <PageBreadcrumb>
                <li className="breadcrumb-item active">Editor de formularios</li>
              </PageBreadcrumb>
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="card card-transparent">
                    <div className="card-header">
                    </div>
                    <div className="card-body">
                      <h3>Formularios.</h3>
                      <p>Administrar tabla de formularios</p>
                    </div>
                  </div>
                </div>
              </div>
            </PageScroll>
          </PageContainer>
        </div>

        <PageContainer className={ path.includes("/executive") || path.includes("/casual") ? "container" : ""}>
          <div className="card card-transparent">
            <div className="card-header ">
              {/* {!forbidden &&
                <div className="pull-right">
                  <div className="col-xs-12">
                    <button aria-label="" id="show-modal" className="btn btn-primary btn-cons" onClick={() => setShowNewFormModal(true)}>
                      <i className="pg-icon">add</i> Agregar formulario
                    </button>
                  </div>
                </div>
              } */}
              <div className="clearfix"></div>
            </div>
            <div className="card-body">
              <ToolkitProvider keyField="form_id" bootstrap4 data={forms} columns={TABLE_COLUMNS} search exportCSV={{ fileName: `${forms.length && forms[0].form_name.replaceAll(' ', '_') || 'spreadsheet'}.csv` }}>
                {(props) => {
                  return (
                    <React.Fragment>
                      {!forbidden &&
                        <>
                          <ExportCSVButton {...props.csvProps}>Export CSV</ExportCSVButton>
                          <SearchBar {...props.searchProps} placeholder='Buscar' style={{width: "20%", float: "right", marginBottom: "5px"}} />
                        </>
                      }
                      <DataTable
                        {...props.baseProps}
                        hover={true}
                        bordered={false}
                        condensed={true}
                        key={"formularios"}
                        noDataIndication={forbidden ? 'No tienes permisos suficientes, contacta con tu administrador.' : 'No se encontraron datos.'}
                        pagination={paginationFactory({
                          hideSizePerPage: true,
                          hidePageListOnlyOnePage: false,
                          showTotal: true,
                          paginationTotalRenderer: (from, to, size) => (<span className="react-bootstrap-table-pagination-total">Mostrando {from} - {to} de {size} registros</span>),
                        })}
                      />
                    </React.Fragment>
                  )
                }}
              </ToolkitProvider>
            </div>
          </div>
        </PageContainer>
      </div>
    </div>
  );
};

export default content;
