import React, { useState, useEffect } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import DataTable from "../../Tables/DataTable";
import { CONSTANTS } from "../../../helpers/constants";
import "./style.css";
import ConfirmDelete from "./Modals/ConfirmDelete";

const Content = () => {
  const TABLE_COLUMNS = [
    {
      dataField: "entity_id",
      text: "Id de entidad",
      headerAlign: "center",
      align: "center",
      headerStyle: { backgroundColor: "#f0f0f073" },
      sort: true,
      editable: false,
    },
    {
      dataField: "entity_name",
      text: "NOMBRE",
      headerAlign: "center",
      align: "center",
      headerStyle: { backgroundColor: "#f0f0f073" },
      headerTitle: true,
      sort: true,
      editable: false,
    },
    {
      dataField: "entity_city",
      text: "Ciudad",
      headerAlign: "center",
      align: "center",
      headerStyle: { backgroundColor: "#f0f0f073" },
      headerTitle: true,
      sort: true,
      editable: false,
    },
    {
      dataField: "entity_representative_name",
      text: "Nombre del representante",
      headerAlign: "center",
      align: "center",
      headerStyle: { backgroundColor: "#f0f0f073" },
      headerTitle: true,
      sort: true,
      editable: false,
    },
    {
      dataField: "entity_representative_phone",
      text: "Teléfono del representante",
      headerAlign: "center",
      align: "center",
      headerStyle: { backgroundColor: "#f0f0f073" },
      headerTitle: true,
      sort: true,
      editable: false,
    },
    {
      dataField: '',
      text: 'ACTIONS',
      headerAlign: 'center',
      align: 'center',
      headerStyle: { backgroundColor: '#f0f0f073' },
      headerTitle: true,
      editable: false,
      formatter: (_cell, row) => {
        return (
          <div className="dropdown d-lg-block d-none">
            <button className="btn btn-default" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="actions dropdown">
              <i className="pg-icon">more_vertical</i>
            </button>
            <div className="dropdown-menu" role="menu">
              <a href={`/entities_form?entity_id=${row.entity_id}`} className="dropdown-item"><i className="pg-icon">eye</i> Ver</a>
              {row.user_id === JSON.parse(sessionStorage.getItem('user')).id || JSON.parse(sessionStorage.getItem('user')).rol === 'supersu' || JSON.parse(sessionStorage.getItem('user')).rol === 'admin' && row.form_id !== 2 ?
                <>
                  <a href="#" onClick={() => handleOpenConfirmDelete(row)} className="dropdown-item"><i className="pg-icon">trash_alt</i> Eliminar</a>
                </> : null
              }
            </div>
          </div>
        )
      }
    }
  ];
  const [users, setUsers] = useState();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [currentFormData, setCurrentFormData] = useState({});
  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;

  useEffect(() => {
    const userLogged = JSON.parse(sessionStorage.getItem("user"));
    console.log("userInfo", userLogged);
    (async () => {
      // get users
      const reqUsers = await fetch(`${CONSTANTS.base_url}api/v1/data/entity/`, {
        headers: {
          "x-access-token": CONSTANTS.TOKEN,
        },
      });
      const resUsers = await reqUsers.json();
      setUsers(resUsers.data);
      console.log("users", resUsers);
    })();
  }, []);

  const handleOpenConfirmDelete = (data) => {
    setCurrentFormData(data);
    setShowConfirmDeleteModal(true);
  }
  const handleCloseConfirmDelete = () => {
    setCurrentFormData({});
    setShowConfirmDeleteModal(false);
  }
  const handleSuccessConfirmDelete = (data) => {
    setShowConfirmDeleteModal(false);
    setCurrentFormData({});
    location.reload()
  }
  const handleFailConfirmDelete = (_data) => {
    setShowConfirmDeleteModal(false);
  }

  // const tableRowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     location.assign(`/entities_form?entity_id=${row.entity_id}`);
  //   },
  // };

  return (
    <div className="page-content-wrapper ">

      <ConfirmDelete visible={showConfirmDeleteModal} currentForm={currentFormData} closeHandler={handleCloseConfirmDelete} successCallback={handleSuccessConfirmDelete} failCallback={handleFailConfirmDelete} />
      {users ? (
        <div className="container p-5">
          <div className="row">
            <div className="col-12  pt-5">
              <div className="widget-11-2 card  no-margin widget-loader-circle full-height d-flex flex-column">
                <div className="p-l-20 p-r-20 p-b-10 p-t-5">
                  <div className="clearfix"></div>
                </div>
                <ToolkitProvider
                  keyField="user_id"
                  bootstrap4
                  data={users}
                  columns={TABLE_COLUMNS}
                  search
                  exportCSV={{
                    fileName: `${"users_spreadsheet"}.csv`,
                  }}
                >
                  {(props) => {
                    return (
                      <React.Fragment>
                        {/* <ExportCSVButton {...props.csvProps}>
                            Export CSV
                          </ExportCSVButton> */}
                        <div className="card-header">
                          <div className="row">
                            <div className="col-6">
                              <h3 className="text-primary no-margin">
                                Entidades
                              </h3>
                            </div>
                            <div className="col-2"></div>
                            <div className="col-4 d-flex justify-content-center align-items-center">
                              <a
                                className="btn btn-primary btn-cons"
                                href="/entities_form"
                              >
                                Crear nueva Entidad
                              </a>
                            </div>
                          </div>
                        </div>
                        <SearchBar
                          {...props.searchProps}
                          placeholder="Buscar... "
                          style={{
                            marginBottom: "5px",
                          }}
                        />
                        <DataTable
                          {...props.baseProps}
                          hover={true}
                          bordered={false}
                          condensed={true}
                          key={"entidades"}
                          noDataIndication={"No tienes entidades registrados."}
                          // rowEvents={tableRowEvents}
                          pagination={paginationFactory({
                            hideSizePerPage: true,
                            hidePageListOnlyOnePage: false,
                            showTotal: true,
                            paginationTotalRenderer: (from, to, size) => (
                              <span className="react-bootstrap-table-pagination-total">
                                Mostrando {from} - {to} de {size} registros
                              </span>
                            ),
                          })}
                        />
                      </React.Fragment>
                    );
                  }}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container p-5">
          <div className="row">
            <div className="col-12 pt-5">
              <div className="widget-11-2 card  no-margin widget-loader-circle full-height d-flex flex-column">
                <div className="p-l-20 p-r-20 p-b-10 p-t-5">
                  <div className="clearfix"></div>
                </div>
                <ToolkitProvider
                  keyField="participante_code"
                  bootstrap4
                  data={users}
                  columns={TABLE_COLUMNS}
                  search
                  exportCSV={{
                    fileName: `${"participants_spreadsheet"}.csv`,
                  }}
                >
                  {(props) => {
                    return (
                      <React.Fragment>
                        {/* <ExportCSVButton {...props.csvProps}>
                            Export CSV
                          </ExportCSVButton> */}
                        <div className="card-header">
                          <div className="row">
                            <div className="col-6">
                              <h3 className="text-primary no-margin">
                                Usuarios
                              </h3>
                            </div>
                            <div className="col-2"></div>
                            <div className="col-4 d-flex justify-content-center align-items-center">
                              <a
                                className="btn btn-primary btn-cons"
                                href="/entities_form"
                              >
                                Crear nueva entidad
                              </a>
                            </div>
                          </div>
                        </div>
                        <h3>No tienes entidades registradas</h3>
                      </React.Fragment>
                    );
                  }}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* END PAGE CONTENT */}
    </div>
  );
};

export default Content;
