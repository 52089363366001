import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-form-validator-core";

import WithoutMsgValidation from "../FormLayouts/WithoutMsgValidation";
import PageContainer from "../../UIElements/Containers";
import { CONSTANTS } from "../../../helpers/constants";

import "react-quill/dist/quill.snow.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";

import "../Elements/styles.css";

const Edit = ({ path }) => {
  const [name, setName] = useState("");
  const [id, setId] = useState();
  const [city, setCity] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [repName, setRepName] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  let handleFormSubmit = async () => {
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/entity/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": CONSTANTS.TOKEN,
      },
      body: JSON.stringify({
        entity_name: name,
        entity_city: city,
        entity_address: address,
        entity_description: description,
        entity_representative_name: repName,
        entity_representative_mail: email,
        entity_representative_phone: phone,
      }),
    });
    const res = await req.json();
    if (res.success) {
      setName("");
      setCity("");
      setDescription("");
      setEmail("");
      setAddress("");
      setRepName("");
      setPhone("");
      location.assign("/entidades");
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    (async () => {
      const req = await fetch(
        `${CONSTANTS.base_url}api/v1/data/entity/${urlParams.get("entity_id")}`,
        {
          headers: {
            "x-access-token": CONSTANTS.TOKEN,
          },
        }
      );
      const res = await req.json();
      if (res.success) {
        setId(res.data.entity_id);
        setName(res.data.entity_name);
        setCity(res.data.entity_city);
        setDescription(res.data.entity_description);
        setEmail(res.data.entity_representative_mail);
        setAddress(res.data.entity_address);
        setRepName(res.data.entity_representative_name);
        setPhone(res.data.entity_representative_phone);
      }
    })();
  },[]);

  return (
    <div className="page-content-wrapper ">
      {/* START PAGE CONTENT */}
      <div
        className="content "
        style={{
          paddingTop: "60px",
        }}
      >
        <PageContainer>
          <div className="row">
            <div className="col-8">
              <h3>Editar Entidad</h3>
            </div>
            <div className="col-2"></div>
            <div className="col-2"></div>
          </div>
          <div
            className="row"
            // style={{ overflowY: "scroll"}}
          >
            <ValidatorForm
              instantValidate={true}
              onSubmit={handleFormSubmit}
              className="p-t-15 col-12"
            >
              <div className="row">
                <div className="col-md-12">
                  <WithoutMsgValidation
                    onChange={(e) => setName(e.target.value)}
                    name="name"
                    id="name"
                    value={name}
                    validators={["required"]}
                    errorMessages={["Este campo es obligatorio"]}
                    className={"form-control"}
                    label={"Nombre de la entidad"}
                    require="true"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <WithoutMsgValidation
                    onChange={(e) => setCity(e.target.value)}
                    name="city"
                    id="city"
                    value={city}
                    validators={[]}
                    errorMessages={[]}
                    className={"form-control"}
                    label={"Ciudad"}
                  />
                </div>
                <div className="col-md-6">
                  <WithoutMsgValidation
                    onChange={(e) => setAddress(e.target.value)}
                    name="address"
                    id="address"
                    value={address}
                    className={"form-control"}
                    label={"Dirección"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <WithoutMsgValidation
                    onChange={(e) => setDescription(e.target.value)}
                    name="description"
                    id="description"
                    value={description}
                    validators={[]}
                    errorMessages={["Este campo es obligatorio"]}
                    className={"form-control"}
                    label={"Descripción"}
                  />
                </div>
                <div className="col-md-6">
                  <WithoutMsgValidation
                    onChange={(e) => setRepName(e.target.value)}
                    name="repName"
                    id="repName"
                    value={repName}
                    validators={[]}
                    errorMessages={[]}
                    className={"form-control"}
                    label={"Nombre del representante legal"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <WithoutMsgValidation
                    onChange={(e) => setEmail(e.target.value)}
                    name="mail"
                    id="mail"
                    value={email}
                    validators={[]}
                    errorMessages={[]}
                    className={"form-control"}
                    label={"Correo del representante legal"}
                  />
                </div>
                <div className="col-md-6">
                  <WithoutMsgValidation
                    onChange={(e) => setPhone(e.target.value)}
                    name="phone"
                    id="phone"
                    value={phone}
                    validators={[]}
                    errorMessages={[]}
                    className={"form-control"}
                    label={"Teléfono del representante legal"}
                  />
                </div>
              </div>
              <button
                aria-label=""
                className="btn btn-primary btn-cons m-t-10"
                type="submit"
                disabled={loading}
              >
                {!loading ? "Editar entidad" : "Editando entidad..."}
              </button>
            </ValidatorForm>
          </div>
        </PageContainer>
      </div>
      {/* END PAGE CONTENT */}
    </div>
  );
};

export default Edit;
