import React from "react";

import DefaultLayout from "../../shared/Layouts/Default";
import FormWizardWrapperComponent from "./Content";
import Edit from "./Edit.jsx";

const Component = ({ location }) => {
  const urlParams = new URLSearchParams(window.location.search);
  let path = location.pathname;
  return (
    //<DefaultLayout location={location}>
    <div className={"page-container"}>
      {/* START PAGE CONTENT WRAPPER */}
	  {urlParams.get("create") ? (
		<FormWizardWrapperComponent path={path} />
      ) : (
        <Edit />
      )}
      {/* END PAGE CONTENT WRAPPER */}
    </div>
    //</DefaultLayout>
  );
};

export default Component;
