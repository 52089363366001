import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ValidatorForm } from "react-form-validator-core";
import WithoutMsgValidation from "../../../../Forms/FormLayouts/WithoutMsgValidation";
import Select from 'react-select';
import { CONSTANTS } from "../../../../../helpers/constants";

const NewFormModal = ({ visible, closeHandler, successCallback, failCallback }) => {
  const [formData, setFormData] = useState({});

  const handleSendForm = async () => {
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/form`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': CONSTANTS.TOKEN
      },
      body: JSON.stringify({
        user_id: JSON.parse(sessionStorage.getItem('user')).id,
        form_name: formData.formName,
        form_description: formData.formDescription,
        form_departamento: 'n/a',
        form_type: formData.formType || 'matriz_meddi'
      })
    });
    const res = await req.json();
    res.success ? successCallback(res) : failCallback(res);
    setFormData({});
  }
  const handleClose = () => {
    setFormData({});
    closeHandler();
  }

  return (
    <Modal show={visible} onHide={handleClose} onEscapeKeyDown={handleClose} onExit={handleClose}>
      <ValidatorForm instantValidate={true} onSubmit={handleSendForm} className="p-t-15">
        <Modal.Header closeButton>
          <h4>Nuevo formulario</h4>
        </Modal.Header>
        <Modal.Body>
          <p className="small-text">Crea un nuevo formulario, asegúrate de completarlos todos los campos</p>
          <div className="row">
            <div className="col-md-12">
              <WithoutMsgValidation
                onChange={(e) => setFormData({ ...formData, formName: e.target.value })} value={formData?.formName || ''}
                name="formName" placeholder="Nombre del formulario" type="text"
                validators={["required"]} errorMessages={["Este campo es obligatorio"]} className={"form-control"} label="Nombre" require="true"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <WithoutMsgValidation
                onChange={(e) => setFormData({ ...formData, formDescription: e.target.value })} value={formData?.formDescription || ''}
                name="formDescription" placeholder="Descripción del formulario" type="text"
                validators={["required"]} errorMessages={["Este campo es obligatorio"]} className={"form-control"} label="Descripción" require="true"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Select name="formType" defaultValue={{ value: '', label: 'Tipo de formulario' }} options={[
                { value: 'matriz_meddi', label: 'Matriz Meddi' },
                { value: 'sistema_de_apoyos', label: 'Sistema de apoyos' },
              ]}
                onChange={(e) => setFormData({ ...formData, formType: e.value })}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary btn-cons" type="submit">Guardar</button>
          <div className="btn btn-cons" onClick={closeHandler}>Cancelar</div>
        </Modal.Footer>
      </ValidatorForm>
    </Modal>
  );
};

export default NewFormModal;
