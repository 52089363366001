import React from "react";
import FormsElementWrapperComponent from "./Content";
import Edit from "./Edit";

const Component = ({ location }) => {
  let path = location.pathname;
  const urlParams = new URLSearchParams(window.location.search);
  return (
    //<DefaultLayout location={location}>
    <div className={"page-container"}>
      {/* START PAGE CONTENT WRAPPER */}
      {!urlParams.get("user_id") ? (
        <FormsElementWrapperComponent path={path} />
      ) : (
        <Edit />
      )}
      {/* END PAGE CONTENT WRAPPER */}
    </div>
    //</DefaultLayout>
  );
};

export default Component;
