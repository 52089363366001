import React, { useState, useEffect } from "react";
import { ValidatorForm } from "react-form-validator-core";
import PageContainer from "../../UIElements/Containers";
import { getMatrizForm } from "../../../lib/MatrizMeddi";
import PaginatedTabs from "./PaginateTabs";
import StageSelect from "../Wizard/StageSelect";
import { CONSTANTS } from "../../../helpers/constants";
import Question from "./Question";
import "../Elements/styles.css";

const Edit = ({ path }) => {
  const [participant, setParticipant] = useState();
  const [tabActive, setTabActive] = useState("");
  const [tabIdx, setTabIdx] = useState(1);
  const [answers, setAnswers] = useState();
  const [dynamicForm, setDynamicForm] = useState(null);
  const [formData, setFormData] = useState({});
const [lastAnswer, setLastAnswer] = useState();
  const urlParams = new URLSearchParams(window.location.search);

  let handleFormSubmit = async () => {
    let counter = 0;
    Object.entries(formData).map(async ([_key, value]) => {
      if (value.answer_id) {
        const req = await fetch(
          `${CONSTANTS.base_url}api/v1/data/answer/${value.answer_id}`,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-access-token": CONSTANTS.TOKEN,
            },
            body: JSON.stringify(value),
          }
        );
        const res = await       location.assign(
          `/participants_form?participant_id=${urlParams.get(
            "participante_id"
          )}`
        );
        if (res.succes) counter++;
      } else {
        console.log(JSON.stringify(value));
        const req = await fetch(`${CONSTANTS.base_url}api/v1/data/answer`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": CONSTANTS.TOKEN,
          },
          body: JSON.stringify(value),
        });
        const res = await req.json();
        if (res.succes)       location.assign(
          `/participants_form?participant_id=${urlParams.get(
            "participante_id"
          )}`
        );
      }
    });
  
    
  };

  //getAnswers
  useEffect(() => {
    (async () => {
      const req = await fetch(
        `${
          CONSTANTS.base_url
        }api/v1/data/question/form_participant/2/${urlParams.get(
          "participante_id"
        )}/${urlParams.get("date")}`,
        {
          headers: {
            "x-access-token": CONSTANTS.TOKEN,
          },
        }
      );
      const res = await req.json();
      // console.log(res);
      if (res.success) {
        const groups = res.data.reduce((acc, currentValue) => {
          let groupKey = currentValue.question_group;
          if (!acc[groupKey]) acc[groupKey] = [];
          acc[groupKey].push(currentValue);
          return acc;
        }, {});
        setAnswers(groups);
      }
    })();
  }, []);

  //getQuestions
  useEffect(() => {
    (async () => {
      const groups = await getMatrizForm();
      setDynamicForm(groups);
      setTabActive(`${groups[Object.keys(groups)[0]][0].question_group}`);
    })();
  }, []);

  //getParticipant
  useEffect(() => {
    (async () => {
      const req = await fetch(
        `${CONSTANTS.base_url}api/v1/data/participants/${urlParams.get(
          "participante_id"
        )}`,
        {
          headers: {
            "x-access-token": CONSTANTS.TOKEN,
          },
        }
      );
      const res = await req.json();
      setParticipant(res.data);
    })();
  }, []);

  const setAnswer = (questionId, name, id, answer, answer_id) => {
    const checkboxes = document.querySelectorAll(
      `input[name="${name}${questionId}"]`
    );
    if (checkboxes.length >= 1) {
      checkboxes.forEach((checkbox) => {
        if (checkbox.id === id) {
          if (!checkbox.checked) checkbox.checked = true;
          setFormData({
            ...formData,
            [`question_id_${questionId}`]: {
              answer_id: answer_id,
              participante_id: urlParams.get("participante_id"),
              question_id: questionId,
              answer_value: answer,
              answer_date: urlParams.get("date"),
              answer_createdAt: urlParams.get("date"),
            },
          });
        } else {
          checkbox.checked = false;
        }
      });
    } else {
      setFormData({
        ...formData,
        [`question_id_${questionId}`]: {
          answer_id: answer_id,
          participante_id: urlParams.get("participante_id"),
          question_id: questionId,
          answer_value: answer,
          answer_date: urlParams.get("date"),
          answer_createdAt: urlParams.get("date"),
        },
      });
    }
setLastAnswer(answer)
    console.log(formData);
  };

  const goNext = () => {
console.log(lastAnswer)
    setTabIdx(tabIdx + 1);
    setTabActive(Object.values(dynamicForm)[tabIdx][0].question_group);
  };

  return (
    <div className="page-content-wrapper ">
      {participant && answers && dynamicForm && (
        <div className="content " style={{ paddingTop: "60px" }}>
          <PageContainer>
            <div className="row">
              <div className="col-8">
                <h3>
                  Matriz de desinstitucionalización de{" "}
                  {participant.participante_name}
                </h3>
                <small>Registrado el: {urlParams.get("date")}</small>
              </div>
              <div className="col">
                <button
                  className="btn btn-primary btn-cons m-t-10 "
                  onClick={handleFormSubmit}
                >
                  Editar matriz
                </button>
                <a
                  className="btn btn-secondary btn-cons m-t-10"
                  href={`/participants_form?participant_id=${participant.participante_id}`}
                >
                  Cancelar
                </a>
              </div>
            </div>
            <div className="row">
              <div className="nav-tab-dropdown cs-wrapper full-width d-lg-none d-xl-none d-md-none">
                <StageSelect onSelectTabs={setTabActive} />
              </div>
              <ul
                className="col-4 d-none d-md-block d-lg-block d-xl-block p-2 pagination-container justify-content-center p-2 m-b-4"
                role="tablist"
                data-init-reponsive-tabs="dropdownfx"
              >
                {dynamicForm && (
                  <PaginatedTabs
                    dynamicForm={dynamicForm}
                    tabIdx={tabIdx}
                    setTabActive={setTabActive}
                    setTabIdx={setTabIdx}
                  />
                )}
              </ul>
              <div className="col-8 p-2 tab-content right-container m-b-4">
                <ValidatorForm
                  instantValidate={true}
                  onSubmit={handleFormSubmit}
                  className="p-t-15"
                >
                  <div
                    className={`tab-pane padding-20 sm-no-padding slide-left questions-container ${
                      tabActive ? "active" : ""
                    }`}
                    id={`tab${tabActive}`}
                  >
                    <div className="row row-same-height">
                      <div className="col-md-12">
                        { tabActive === "AUTOCONCEPTO - AUTOVALORACIÓN" ?
                          <Question
                            question={
                              dynamicForm["AUTOCONCEPTO - AUTOVALORACIÓN"][10]
                            }
                            answerSaved={Object.values(answers).flat().find((answer) => answer.Answers[0].question_id == 109).Answers[0]}
                            options={[
                              "Entrevista de entrada",
                              "Entrevista de seguimiento",
                              "Entrevista de salida",
                            ]}
                            setAnswer={setAnswer}
                            tabActive={tabActive}
                            required={true}
                          /> : ''
                        }
                      </div>
                      <div className="col-md-12">
                        <h4 className="text-start font-weight-bold">
                          {dynamicForm && `${tabIdx}${lastAnswer === 1 && tabIdx === 25 ? 'b' : 
                                    lastAnswer === 0 && tabIdx === 25 ? 'a' : ''}. ${tabActive}`}
                        </h4>
                        {/* <h5 className="text-success text-start font-weight-bold">
                            TARJETA {tabIdx}
                            </h5> */}
                        {dynamicForm &&
                          Object.values(dynamicForm).map((tab, idx) => {
                            return tab.map((key, index) => {
                              let options = [];
                              let answerSaved = "";
                              let allAnswers = Object.values(answers).flat();
                              const currentQuestion = allAnswers.find(
                                (answer) =>
                                  answer.Answers[0].question_id ==
                                  key.question_id
                              );
                              if (
                                currentQuestion &&
                                key.question_id == currentQuestion.question_id
                              ) {
                                answerSaved = currentQuestion.Answers[0];
                              }
                              if (
                                key.question_type === "multiple" ||
                                key.question_type === "varias"
                              )
                                options = JSON.parse(key.question_options).map(
                                  (value, index) => (options[index] = value)
                                );
                              return (
                                <React.Fragment key={index}>
                                  <div
                                    style={{
                                      display: `${
                                        key.question_group == tabActive
                                          ? "block"
                                          : "none"
                                      }`,
                                    }}
                                  >
                                    {
                                    lastAnswer === 0 && (key.question_id === 89 || key.question_id === 90) ? '' : 
                                    lastAnswer === 1 && (key.question_id === 124 || key.question_id === 125) ? '' :
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: key.question_instruccions
                                          ?.replace("[", "")
                                          .replace("]", "")
                                          .replace(/,/g, "")
                                          .replace(/"/g, ""),
                                      }}
                                    ></div>
                                  }
                                    <div className={`padding-30 sm-padding-5`}>
                                      {key.question_id === 109 ? ("") : 
									   lastAnswer === 1 && (key.question_id === 124 || key.question_id === 125) ? '' : 
                                      lastAnswer === 0 && (key.question_id === 89 || key.question_id === 90) ? '' :
									   (
                                        <Question
                                          question={key}
                                          answerSaved={
                                            key.question_id ==
                                            answerSaved.question_id
                                              ? answerSaved
                                              : ""
                                          }
                                          options={options}
                                          setAnswer={setAnswer}
                                          tabActive={tabActive}
                                          required={true}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            });
                          })}
                      </div>
                    </div>
                    {tabIdx !== Object.keys(dynamicForm).length && (
                      <button
                        className="btn btn-success btn-cons btn-animated from-left pull-right"
                        type="button"
                        onClick={() => goNext()}
                        // onClick={() => {
                        //   validateRequired(
                        //     tabIdx,
                        //     Object.values(dynamicForm)[tabIdx][0].question_group
                        //   );
                        // }}
                      >
                        <span>Siguiente</span>
                      </button>
                    )}
                    {tabIdx == Object.keys(dynamicForm).length && (
                      <button
                        className="btn btn-success btn-cons btn-animated from-left pull-right"
                        type="submit"
                      >
                        <span>Guardar</span>
                      </button>
                    )}
                  </div>
                </ValidatorForm>
              </div>
            </div>
            {/* <button aria-label="" className="btn btn-primary btn-cons m-t-10" type="submit" disabled={loading}>{!loading ? 'Guardar' : 'Guardando...'}</button> */}
          </PageContainer>
        </div>
      )}
      {/* END PAGE CONTENT */}
    </div>
  );
};

export default Edit;
