import React, { useEffect, useState } from "react";
import { Highlighter } from "react-bootstrap-typeahead";
import { ValidatorForm } from "react-form-validator-core";

import WithoutMsgValidation from "../FormLayouts/WithoutMsgValidation";
import SelectValidation from "../Wizard/SelectValidation";

import PageContainer from "../../UIElements/Containers";
import Input from "../Elements/Input";
import Label from "../Elements/Label";
import { CONSTANTS } from "../../../helpers/constants";

import "react-quill/dist/quill.snow.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";

import "../Elements/styles.css";
import { getAge, getEntities } from "../../../helpers/utils";

const content = ({ path }) => {
  //INICIO CONEXION CON LA API-------------------------------------------------------------------------------------------------------
  const [dynamicQuestions, setDynamicQuestions] = useState([]);
  const [dynamicQuestionsFiltered, setDynamicQuestionsFiltered] = useState([]);
  const [dynamicQuestionsValidated, setDynamicQuestionsValidated] = useState([]);
  const [dynamicAnswers, setDynamicAnswers] = useState({});
  const [entities, setEntities] = useState([]);
  const [entityData, setEntityData] = useState({});
  const [nombreEntidad, setnombreEntidad] = useState("");
  const [ciudadEntidad, setciudadEntidad] = useState("");
  const [direccionEntidad, setDireccionEntidad] = useState("");
  const [nombreRL, setNombreRL] = useState("");
  const [correoRL, setCorreoRL] = useState("");
  const [telefonoRL, setTelefonoRL] = useState("");
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [primerApellidoUsuario, setPrimerApellidoUsuario] = useState("");
  const [segundoApellidoUsuario, setSegundoApellidoUsuario] = useState("");
  const [nacimientoUsuario, setNacimientoUsuario] = useState(null);
  const [correoUsuario, setCorreoUsuario] = useState("");
  const [telefonoUsuario, setTelefonoUsuario] = useState("");
  const [generoUsuario, setGeneroUsuario] = useState("");
  const [nacionalidadUsuario, setNacionalidadUsuario] = useState("");
  const [idiomaUsuario, setIdiomaUsuario] = useState("");
  const [escolarizacionUsuario, setEscolarizacionUsuario] = useState("");
  const [tiempoCalle, setTiempoCalle] = useState("");
  const [dondeVivias, setDondeVivias] = useState("");
  const [intitucionesUsuario, setintitucionesUsuario] = useState("");
  const [situacionCalle, setsituacionCalle] = useState("");
  const [sufridoAgresion, setsufridoAgresion] = useState("");
  const [perfilSocial, setPerfilSocial] = useState("");
  const [problematicas, setProblematicas] = useState([]);
  const handleCheckboxChange = (e) => {
    let { checked, value } = e.target;
    checked = !checked;
    if (checked) {
      let filteredArray = problematicas.filter((e) => e !== value);
      setProblematicas(filteredArray);
    } else {
      setProblematicas((problematica) => [...problematica, value]);
    }
  };
  const [fechaAlta, setFechaAlta] = useState("");
  const [fechaBaja, setFechaBaja] = useState("");
  const [entidadProcedencia, setentidadProcedencia] = useState("");
  const [motivoBaja, setMotivoBaja] = useState("");
  const [ethos, setEthos] = useState("");
  //GREMERAMOS EL CODIGO DE PARTICIPANTE
  const obtenerDigitos = (campo, numeroInicio, numeroFin) => {
    if (campo.length >= numeroFin) {
      return campo.slice(numeroInicio, numeroFin);
    }
    return "";
  };
  const digitosEntidad = obtenerDigitos(nombreEntidad, 0, 3);
  const digitosCiudad = obtenerDigitos(ciudadEntidad, 0, 2);
  const digitosNombre = obtenerDigitos(nombreUsuario, 0, 1);
  const digitosPrimerApellido = obtenerDigitos(primerApellidoUsuario, 0, 1);
  const digitosSegundoApellido = obtenerDigitos(segundoApellidoUsuario, 0, 1);
  const digitosFecha = obtenerDigitos(fechaAlta, 2, 4);

  const codigoParticipante = (
    digitosEntidad +
    digitosCiudad +
    digitosNombre +
    digitosPrimerApellido +
    digitosSegundoApellido +
    digitosFecha
  ).toUpperCase();
  //GREMERAMOS EL CODIGO DE PARTICIPANTE

  let participantForm = async () => {
    const userLogged = JSON.parse(sessionStorage.getItem("user")).id;
    if (
      direccionEntidad ||
      nombreRL ||
      correoRL ||
      telefonoRL
    ) {
      const updateEntity = await fetch(
        `${CONSTANTS.base_url}api/v1/data/entity/${entityData.entity_id}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": CONSTANTS.TOKEN,
          },
          body: JSON.stringify({
            entity_address: direccionEntidad,
            entity_representative_name: nombreRL,
            entity_representative_mail: correoRL,
            entity_representative_phone: telefonoRL,
          }),
        }
      );
      const entityUpdated = await updateEntity.json();
    }
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/participants`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": CONSTANTS.TOKEN,
      },
      body: JSON.stringify({
        participante_name: nombreUsuario,
        participante_lastname: primerApellidoUsuario,
        participante_second_surname: segundoApellidoUsuario,
        participante_birthday: nacimientoUsuario,
        participante_age: getAge(nacimientoUsuario),
        participante_mail: correoUsuario,
        participante_phone: telefonoUsuario,
        participante_code: codigoParticipante,
        participante_gender: generoUsuario,
        participante_nationality: nacionalidadUsuario,
        participante_language: idiomaUsuario,
        participante_scholarship: escolarizacionUsuario,
        entity_id: entityData.entity_id,
        user_id: userLogged,
      }),
    });
    const res = await req.json();
    if (res.data.participante_id) {
      const historyOfInstitucionalization = await fetch(
        `${CONSTANTS.base_url}api/v1/data/institucionalization_history`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": CONSTANTS.TOKEN,
          },
          body: JSON.stringify({
            participante_id: res.data.participante_id,
            history_acommodation_time: tiempoCalle,
            history_previous_acommodation: dondeVivias,
            history_quantity_programs: intitucionesUsuario,
            history_nohome_state: situacionCalle,
            history_assault_state: sufridoAgresion,
            history_social_profile: perfilSocial,
            history_problematic: problematicas.join(","),
            history_register_date: fechaAlta,
            history_updown_date: fechaBaja,
            history_entity: nombreEntidad,
            entidad_procedencia: entidadProcedencia,
            history_updown_reason: motivoBaja,
            history_previous_ethos: ethos,
          }),
        }
      );
      const resHistoryOfInstitucionalization =
        await historyOfInstitucionalization.json();
      if (resHistoryOfInstitucionalization.success) {
        setnombreEntidad("");
        setciudadEntidad("");
        setDireccionEntidad("");
        setNombreRL("");
        setCorreoRL("");
        setNombreUsuario("");
        setPrimerApellidoUsuario("");
        setSegundoApellidoUsuario("");
        setNacimientoUsuario("");
        setCorreoUsuario("");
        setTelefonoUsuario("");
        setGeneroUsuario("");
        setNacionalidadUsuario("");
        setIdiomaUsuario("");
        setEscolarizacionUsuario("");
        setTiempoCalle("");
        setDondeVivias("");
        setintitucionesUsuario("");
        setsituacionCalle("");
        setsufridoAgresion("");
        setPerfilSocial("");
        setProblematicas([]);
        setFechaAlta("");
        setFechaBaja("");
        setentidadProcedencia("");
        setMotivoBaja("");
        setEthos("");
        const answers = Object.entries(dynamicAnswers).map(([_key, value]) => ({ ...value, participante_id: res.data.participante_id }));
        const reqSaveAnswers = await fetch(`${CONSTANTS.base_url}api/v1/data/participante_answers/save_answers`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': CONSTANTS.TOKEN
          },
          body: JSON.stringify(answers)
        });
        const resSaveAnswers = await reqSaveAnswers.json();
        console.log(resSaveAnswers);
        location.assign("/home");
      } else {
        alert(resHistoryOfInstitucionalization.error);
      }
    }
  };
  //FIN CONEXION CON LA API----------------------------------------------------------------------------------------------------------

  const [props] = useState({});
  const _renderMenuItemChildren = (option, props, index) => {
    return [
      <Highlighter key="name" search={props.text}>
        {option.name}
      </Highlighter>,
      <div key="population">
        <small>Population: {option.population.toLocaleString()}</small>
      </div>,
    ];
  };
  props.renderMenuItemChildren = _renderMenuItemChildren;

  //INICIO FUNCION ETHOS -------------------------------------------------------------------------------------------------------------------
  function selectEthos(idCheck, valueEthos) {
    const select = document.getElementById("ethos");
    const checkBox = document.getElementById(idCheck);
    checkBox.addEventListener("change", () => {
      if (checkBox.checked) {
        select.value = valueEthos;
        setEthos(valueEthos);
      }
    });
    return;
  }
  //FIN FUNCION ETHOS -------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    (async () => {
      const entitiesList = await getEntities();
      setEntities(entitiesList);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const req = await fetch(`${CONSTANTS.base_url}api/v1/data/participante_questions`, {
        headers: { 'x-access-token': CONSTANTS.TOKEN },
      })
      const res = await req.json();
      if (res.data) {
        setDynamicQuestions(res.data);
        const filtered = res.data.filter(question => question.question_type === 'custom' && question.question_active);
        setDynamicQuestionsFiltered(filtered);
        setDynamicQuestionsValidated(filtered);
      }
    })()
  }, [])

  useEffect(() => {
    if (dynamicQuestionsFiltered && dynamicQuestionsFiltered.length) {
      const refilter = dynamicQuestionsFiltered.filter(question => !question.question_allowed_entities || !JSON.parse(question.question_allowed_entities || '[]').length || JSON.parse(question.question_allowed_entities || '[]').includes(entityData.entity_id))
      setDynamicQuestionsValidated(refilter);
      setDynamicAnswers({});
    }
  }, [entityData])

  const getEntityData = async (entity_name) => {
    const req = await fetch(
      `${CONSTANTS.base_url}api/v1/data/entity/byName/${entity_name}`,
      {
        headers: {
          "x-access-token": CONSTANTS.TOKEN,
        },
      }
    );

    const res = await req.json();
    if (res.data) {
      setEntityData(res.data);
    }
  };

  return (
    <div className="page-content-wrapper ">
      {/* START PAGE CONTENT */}
      <div
        className="content "
        style={{
          paddingTop: "60px",
        }}
      >
        <PageContainer>
          <div className="row">
            <div className="col-8">
              <h3>Alta Nuevo Participante</h3>
            </div>
            <div className="col-2"></div>
            <div className="col-2">
              <button
                aria-label=""
                className="btn btn-primary btn-cons m-t-10"
                type="submit"
                onClick={participantForm}
              >
                Guardar
              </button>
            </div>
          </div>
          <div
            className="row"
            style={{ overflowY: "scroll", maxHeight: "85vh" }}
          >
            {/*INICIO SECCION ENTIDAD*/}
            {entities && (
              <div className="col-lg-12">
                <div className="card card-default">
                  <div className="card-body">
                    <h4>Datos de la Entidad</h4>
                    <form className="" role="form">
                      <div className="row">
                        {/*INICIO DEL CAMPO NOMBRE ENTIDAD*/}
                        <div className="col-md-4">
                          <div className={`form-group form-group-default`}>
                            <Label>
                              <b>Nombre de la Entidad</b>
                            </Label>
                            <select
                              className="form-control"
                              id="entidades"
                              value={nombreEntidad}
                              onChange={(e) => {
                                setnombreEntidad(e.target.value);
                                getEntityData(e.target.value);
                              }}
                            >
                              <option hidden>Seleccione la entidad</option>
                              {entities.map((entity, index) => (
                                <option key={index} value={entity.entity_description}>
                                  {entity.entity_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {/*FIN DEL CAMPO NOMBRE ENTIDAD*/}
                        {/*INICIO DEL CAMPO CIUDAD ENTIDAD*/}
                        <div className="col-md-4">
                          <div className={`form-group form-group-default`}>
                            <Label>
                              <b>Ciudad</b>
                            </Label>
                            <select
                              className="form-control"
                              id="ciudad"
                              disabled
                              value={entityData?.entity_city || ciudadEntidad}
                              onChange={(e) => setciudadEntidad(e.target.value)}
                            >
                              <option hidden>Seleccione ciudad</option>
                              {entities.map((entity, index) => (
                                <option key={index} value={entity.entity_city}>
                                  {entity.entity_city}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {/*FIN DEL CAMPO CIUDAD ENTIDAD*/}
                        {/*INICIO DEL CAMPO DIRECCION ENTIDAD*/}
                        <div className="col-md-4">
                          <div className={`form-group form-group-default`}>
                            <Label>
                              <b>Dirección</b>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Introduzca los datos"
                              value={entityData?.entity_address || direccionEntidad}
                                   disabled
                              onChange={(e) =>
                                setDireccionEntidad(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        {/*FIN DEL CAMPO DIRECCION ENTIDAD*/}
                        {/*INICIO DEL CAMPO NOMBRE RL*/}
                        <div className="col-md-4">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Nombre Representate Legal</b>
                            </Label>
                            <Input
                              type="text"
                                   disabled
                              className="form-control"
                              placeholder="Introduzca los datos"
                              value={entityData?.entity_representative_name || nombreRL}
                              onChange={(e) => setNombreRL(e.target.value)}
                            />
                          </div>
                        </div>
                        {/*FIN DEL CAMPO NOMBRE RL*/}
                        {/*INICIO DEL CAMPO MAIL RL*/}
                        <div className="col-md-4">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Correo Representate Legal</b>
                            </Label>
                            <Input
                              type="email"
                              className="form-control"
                                   disabled
                              placeholder="Introduzca los datos"
                              value={entityData?.entity_representative_mail || correoRL}
                              onChange={(e) => setCorreoRL(e.target.value)}
                            />
                          </div>
                        </div>
                        {/*FIN DEL CAMPO MAIL RL*/}
                        {/*INICIO DEL CAMPO TELEFONO RL*/}
                        <div className="col-md-4">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Teléfono de Contacto Representante Legal</b>
                            </Label>
                            <Input
                              type="tel"
                              className="form-control"
                                   disabled
                              placeholder="Introduzca los datos"
                              value={entityData?.entity_representative_phone || telefonoRL}
                              onChange={(e) => setTelefonoRL(e.target.value)}
                            />
                          </div>
                        </div>
                        {/*FIN DEL CAMPO TELEFONO*/}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            {/*FIN SECCION ENTIDADAD*/}
            {/*INICIO SECCION PARTICIPANTES*/}
            <div className="col-lg-12">
              <div className="card card-default">
                <div className="card-body">
                  <h4>Datos del Participante</h4>
                  <form className="" role="form">
                    <div className="row">
                      {/*INICIO DEL CAMPO NOMBRE*/}
                      {(dynamicQuestions.find(question => question.question_key === 'participante_name')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'participante_name')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'participante_name')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-4">
                          {/*El "required" pone un asterisco en la esquina del campo*/}
                          <div
                            className={`form-group form-group-default required `}
                          >
                            <Label>
                              <b>Nombre</b>
                            </Label>
                            <Input
                              type="text"
                              className={`form-control`}
                              required=""
                              placeholder="Introduzca los datos"
                              value={nombreUsuario}
                              onChange={(e) => setNombreUsuario(e.target.value)}
                            />
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO NOMBRE*/}
                      {/*INICIO DEL CAMPO PRIMER APELLIDO*/}
                      {(dynamicQuestions.find(question => question.question_key === 'participante_lastname')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'participante_lastname')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'participante_lastname')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-4">
                          <div className={`form-group form-group-default required`}>
                            <Label>
                              <b>Primer Apellido</b>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Introduzca los datos"
                              value={primerApellidoUsuario}
                              required=""
                              onChange={(e) => {
                                setPrimerApellidoUsuario(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO PRIMER APELLIDO*/}
                      {/*INICIO DEL CAMPO SEGUNDO APELLIDO*/}
                      {(dynamicQuestions.find(question => question.question_key === 'participante_second_surname')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'participante_second_surname')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'participante_second_surname')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-4">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Segundo Apellido</b>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Introduzca los datos"
                              value={segundoApellidoUsuario}
                              onChange={(e) => {
                                setSegundoApellidoUsuario(e.target.value);
                              }}
                              />
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO SEGUNDO APELLIDO*/}
                      {/*INICIO DEL CAMPO NACIMIENTO*/}
                      {(dynamicQuestions.find(question => question.question_key === 'participante_birthday')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'participante_birthday')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'participante_birthday')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-4">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Fecha de Nacimiento</b>
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              placeholder="Introduzca los datos"
                              value={nacimientoUsuario}
                              onChange={(e) =>
                                setNacimientoUsuario(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO NACIMIENTO*/}
                      {/*INICIO DEL CAMPO CORREO*/}
                      {(dynamicQuestions.find(question => question.question_key === 'participante_mail')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'participante_mail')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'participante_mail')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-4">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Correo</b>
                            </Label>
                            <Input
                              type="email"
                              className="form-control"
                              placeholder="Introduzca los datos"
                              value={correoUsuario}
                              onChange={(e) => setCorreoUsuario(e.target.value)}
                              />
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO CORREO*/}
                      {/*INICIO DEL CAMPO TELEFONO*/}
                      {(dynamicQuestions.find(question => question.question_key === 'participante_phone')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'participante_phone')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'participante_phone')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-4">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Teléfono de Contacto</b>
                            </Label>
                            <Input
                              type="tel"
                              className="form-control"
                              placeholder="Introduzca los datos"
                              value={telefonoUsuario}
                              onChange={(e) => setTelefonoUsuario(e.target.value)}
                            />
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO TELEFONO*/}
                      {/*INICIO DEL CAMPO GENERO*/}
                      {(dynamicQuestions.find(question => question.question_key === 'participante_gender')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'participante_gender')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'participante_gender')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-6">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Genero</b>
                            </Label>
                            <select
                              className="form-control"
                              id="genero"
                              value={generoUsuario}
                              onChange={(e) => setGeneroUsuario(e.target.value)}
                            >
                              <option hidden>Seleccione un genero</option>
                              <option value="masculino">Masculino</option>
                              <option value="femenino">Femenino</option>
                              <option value="nobinario">No Binario</option>
                            </select>
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO GENERO*/}
                      {/*INICIO DEL CAMPO NACIONALIDAD*/}
                      {(dynamicQuestions.find(question => question.question_key === 'participante_nationality')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'participante_nationality')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'participante_nationality')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-6">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Nacionalidad</b>
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                placeholder="Introduzca los datos"
                                value={nacionalidadUsuario}
                                onChange={(e) =>
                                  setNacionalidadUsuario(e.target.value)
                                }
                              />
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO NACIONALIDAD*/}
                      {/*INICIO DEL CAMPO IDIOMAS*/}
                      {(dynamicQuestions.find(question => question.question_key === 'participante_language')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'participante_language')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'participante_language')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-6">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Idiomas que habla y entiende</b>
                            </Label>
                            <Input
                              className="form-control"
                              id="idioma"
                              placeholder="Escribe los idiomas"
                              value={idiomaUsuario}
                              onChange={(e) => setIdiomaUsuario(e.target.value)}
                             >
                             </Input>
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO IDIOMAS*/}
                      {/*INICIO DEL CAMPO ESCOLARIZACION*/}
                      {(dynamicQuestions.find(question => question.question_key === 'participante_scholarship')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'participante_scholarship')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'participante_scholarship')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-6">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Años de escolarización</b>
                            </Label>
                            <select
                              className="form-control"
                              id="escolarizacion"
                              value={escolarizacionUsuario}
                              onChange={(e) =>
                                setEscolarizacionUsuario(e.target.value)
                              }
                            >
                              <option hidden>
                                Seleccione año de escolarización
                              </option>
                              <option value="estudios_superiores">
                                Estudios superiores completos
                              </option>
                              <option value="estudios_superiores_no">
                                Estudios superiores incompletos
                              </option>
                              <option value="primaria">Primaria completa</option>
                              <option value="primaria_no">
                                Primaria incompleta
                              </option>
                              <option value="secundaria">
                                Secundaria completa
                              </option>
                              <option value="secundaria_no">
                                Secundaria incompleta
                              </option>
                            </select>
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO ESCOLARIZACION*/}
                      {/*INICIO DEL CAMPO CODIGO PARTICIPANTE*/}
                      {(dynamicQuestions.find(question => question.question_key === 'participante_code')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'participante_code')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'participante_code')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-12">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Codigo del participante</b>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="CODIGO DEL PARTICIPANTE"
                              value={codigoParticipante}
                              readOnly
                            />
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO CODIGO PARTICIPANTE*/}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/*FIN SECCION PARTICIPANTES*/}
            {/*INICIO SECCION HISTORIAL DE INSTITUCIONALIZACION*/}
            <div className="col-lg-12">
              <div className="card card-default">
                <div className="card-body">
                  <h4>Historial de Institucionalización</h4>
                  <form className="" role="form">
                    <div className="row">
                      {/*INICIO DEL TIEMPO SIN ALOJAMIENTO*/}
                      {(dynamicQuestions.find(question => question.question_key === 'history_acommodation_time')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'history_acommodation_time')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'history_acommodation_time')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-12">
                          <div className={`form-group form-group-default `}>
                            <b>
                              ¿Cuánto tiempo lleva viviendo sin un alojamiento que
                              puedas considerar propio y estable?
                            </b>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="HI01"
                                id="sinAlijamiento01"
                                value="menos_1_mes"
                                checked={tiempoCalle === "menos_1_mes"}
                                onChange={(e) => setTiempoCalle(e.target.value)}
                              />
                              <label
                                className="form-check-label mr-5"
                                htmlFor="sinAlijamiento01"
                              >
                                Menos de 1 mes
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="HI01"
                                id="sinAlijamiento02"
                                value="entre_1_y_6_meses"
                                checked={tiempoCalle === "entre_1_y_6_meses"}
                                onChange={(e) => setTiempoCalle(e.target.value)}
                              />
                              <label
                                className="form-check-label mr-5"
                                htmlFor="sinAlijamiento02"
                              >
                                Entre 1 y 6 meses
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="HI01"
                                id="sinAlijamiento03"
                                value="entre_6_y_12_meses"
                                checked={tiempoCalle === "entre_6_y_12_meses"}
                                onChange={(e) => setTiempoCalle(e.target.value)}
                              />
                              <label
                                className="form-check-label mr-5"
                                htmlFor="sinAlijamiento03"
                              >
                                Entre 6 y 12 meses
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="HI01"
                                id="sinAlijamiento04"
                                value="entre_1_y_3_años"
                                checked={tiempoCalle === "entre_1_y_3_años"}
                                onChange={(e) => setTiempoCalle(e.target.value)}
                              />
                              <label
                                className="form-check-label mr-5"
                                htmlFor="sinAlijamiento04"
                              >
                                Entre 1 y 3 años
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="HI01"
                                id="sinAlijamiento05"
                                value="mas_3_años"
                                checked={tiempoCalle === "mas_3_años"}
                                onChange={(e) => setTiempoCalle(e.target.value)}
                              />
                              <label
                                className="form-check-label mr-5"
                                htmlFor="sinAlijamiento05"
                              >
                                Más de 3 años
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="HI01"
                                id="sinAlijamiento06"
                                value="NS/NC"
                                checked={tiempoCalle === "NS/NC"}
                                onChange={(e) => setTiempoCalle(e.target.value)}
                              />
                              <label
                                className="form-check-label mr-5"
                                htmlFor="sinAlijamiento06"
                              >
                                NS/NC
                              </label>
                            </div>
                          </div>
                        </div>
                      }
                      {/*FIN DEL TIEMPO SIN ALOJAMIENTO*/}
                      {/*INICIO DEL TIEMPO DONDE VIVIVAS ANTES*/}
                      {(dynamicQuestions.find(question => question.question_key === 'history_previous_acommodation')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'history_previous_acommodation')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'history_previous_acommodation')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-12">
                          <div className={`form-group form-group-default `}>
                            <b>¿Dónde vivías antes de entrar en este programa?</b>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias01"
                                value="espacio_publico"
                                checked={dondeVivias === "espacio_publico"}
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias01", "ethos1");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias01">
                                En el espacio público o alojamiento de
                                fortuna(coche,cajero,etc...)
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias02"
                                value="centro_emergencia"
                                checked={dondeVivias === "centro_emergencia"}
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias02", "ethos2");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias02">
                                En un centro de emergencia para PsSH
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias03"
                                value="centro_albergue"
                                checked={dondeVivias === "centro_albergue"}
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias03", "ethos3");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias03">
                                En un centro de albergue para PsSH
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias04"
                                value="centro_mujeres"
                                checked={dondeVivias === "centro_mujeres"}
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias04", "ethos4");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias04">
                                Centros de atención a mujeres víctimas de trata o
                                de violencia de género
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias05"
                                value="alojamientos_imigrantes"
                                checked={
                                  dondeVivias === "alojamientos_imigrantes"
                                }
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias05", "ethos5");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias05">
                                En centros de alojamientos para inmigrantes o
                                solicitantes de asilo
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias06"
                                value="centro_menores"
                                checked={dondeVivias === "centro_menores"}
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias06", "ethos6");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias06">
                                En un centro de menores (tutela)
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias07"
                                value="centro_internamiento"
                                checked={dondeVivias === "centro_internamiento"}
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias07", "ethos6");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias07">
                                En un centro de internamiento para menores
                                (condena)
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias08"
                                value="centro_salud_mental"
                                checked={dondeVivias === "centro_salud_mental"}
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias08", "ethos6");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias08">
                                Centro de salud mental /hospital salud mental
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias09"
                                value="vivienda_tutelada"
                                checked={dondeVivias === "vivienda_tutelada"}
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias09", "ethos7");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias09">
                                En una vivienda tutelada o residencia de estancia
                                larga
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias10"
                                value="casa_familiares"
                                checked={dondeVivias === "casa_familiares"}
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias10", "ethos8");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias10">
                                En casa de familiares
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias11"
                                value="casa_amigos"
                                checked={dondeVivias === "casa_amigos"}
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias11", "ethos8");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias11">
                                En casa de amigos/as
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias12"
                                value="infravivienda_chabola"
                                checked={dondeVivias === "infravivienda_chabola"}
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias12", "ethos11");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias12">
                                En una infravivienda/chabola
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias13"
                                value="insalubre_no_acondicionada"
                                checked={
                                  dondeVivias === "insalubre_no_acondicionada"
                                }
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias13", "ethos12");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias13">
                                En una vivienda insalubre o no acondicionada como
                                residencia/casa okupa
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias14"
                                value="casa_masificada"
                                checked={dondeVivias === "casa_masificada"}
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias14", "ethos13");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias14">
                                En una casa masificada
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias15"
                                value="en_pension"
                                checked={dondeVivias === "en_pension"}
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias15", "ethos0");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias15">
                                En una pensión
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias16"
                                value="mi_casa"
                                checked={dondeVivias === "mi_casa"}
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias16", "ethos0");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias16">
                                En mi casa
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="DV"
                                id="dondeVivias17"
                                value="otro"
                                checked={dondeVivias === "otro"}
                                onChange={(e) => {
                                  setDondeVivias(e.target.value);
                                  selectEthos("dondeVivias17", "ethos0");
                                }}
                              />
                              <label className="form-check-label" htmlFor="dondeVivias17">
                                Otro
                              </label>
                            </div>
                          </div>
                        </div>
                      }
                      {/*FIN DEL TIEMPO DONDE VIVIVAS ANTES*/}
                      {/*INICIO DEL CAMPO PERFIL ETHOS*/}
                      {(dynamicQuestions.find(question => question.question_key === 'history_previous_ethos')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'history_previous_ethos')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'history_previous_ethos')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-12">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Perfil Ethos</b>
                            </Label>
                            <select
                              className="form-control ReadOnlyEthos"
                              id="ethos"
                              value={ethos}
                              onChange={(e) => setEthos(e.target.value)}
                            >
                              <option value="ethos0">
                                Seleccione perfil ethos
                              </option>
                              <option value="ethos1">Ethos 1</option>
                              <option value="ethos2">Ethos 2</option>
                              <option value="ethos3">Ethos 3</option>
                              <option value="ethos4">Ethos 4</option>
                              <option value="ethos5">Ethos 5</option>
                              <option value="ethos6">Ethos 6</option>
                              <option value="ethos7">Ethos 7</option>
                              <option value="ethos8">Ethos 8</option>
                              <option value="ethos11">Ethos 11</option>
                              <option value="ethos12">Ethos 12</option>
                              <option value="ethos13">Ethos 13</option>
                            </select>
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO PEFIL ETHOS*/}
                      {/*INICIO DEL CAMPO CUANTAS INTITUCIONES O RECURSOS*/}
                      {(dynamicQuestions.find(question => question.question_key === 'history_quantity_programs')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'history_quantity_programs')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'history_quantity_programs')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-12">
                          <div className={`form-group form-group-default `}>
                            <Label htmlFor="IOR">
                              <b>
                                Aproximadamente, ¿En cuántas instituciones o
                                recursos de proteccion social has estado antes de
                                llegar a Fururo&Co
                              </b>
                            </Label>
                            <br></br>
                            <textarea
                              className="form-control"
                              id="IOR"
                              placeholder="Introduzca los datos"
                              value={intitucionesUsuario}
                              onChange={(e) =>
                                setintitucionesUsuario(e.target.value)
                              }
                            ></textarea>
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO CUANTAS INTITUCIONES O RECURSOS*/}
                      {/*INICIO DEL SITUACION DE CALLE*/}
                      {(dynamicQuestions.find(question => question.question_key === 'history_nohome_state')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'history_nohome_state')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'history_nohome_state')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-6">
                          <div className={`form-group form-group-default `}>
                            <b>¿Has estado en situación de calle?</b>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="SC01"
                                id="situacionCalle01"
                                value="si"
                                checked={situacionCalle === "si"}
                                onChange={(e) =>
                                  setsituacionCalle(e.target.value)
                                }
                              />
                              <label
                                className="form-check-label mr-5"
                                htmlFor="situacionCalle01"
                              >
                                Sí
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="SC01"
                                id="situacionCalle02"
                                value="no"
                                checked={situacionCalle === "no"}
                                onChange={(e) =>
                                  setsituacionCalle(e.target.value)
                                }
                              />
                              <label
                                className="form-check-label mr-5"
                                htmlFor="situacionCalle02"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      }
                      {/*FIN DEL SITUACION DE AGRESION*/}
                      {/*INICIO DEL SITUACION DE CALLE*/}
                      {(dynamicQuestions.find(question => question.question_key === 'history_assault_state')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'history_assault_state')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'history_assault_state')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-6">
                          <div className={`form-group form-group-default `}>
                            <b>
                              ¿Has sufrido alguna situación previa de agresión en
                              el entorno de vivienda o alojamiento en el que has
                              estado?
                            </b>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="EA01"
                                id="estadoAgresion01"
                                value="si"
                                checked={sufridoAgresion === "si"}
                                onChange={(e) =>
                                  setsufridoAgresion(e.target.value)
                                }
                              />
                              <label
                                className="form-check-label mr-5"
                                htmlFor="estadoAgresion01"
                              >
                                Sí
                              </label>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="EA01"
                                id="estadoAgresion02"
                                value="no"
                                checked={sufridoAgresion === "no"}
                                onChange={(e) =>
                                  setsufridoAgresion(e.target.value)
                                }
                              />
                              <label
                                className="form-check-label mr-5"
                                htmlFor="estadoAgresion02"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </div>
                      }
                      {/*FIN DEL SITUACION DE AGRESION*/}
                      {/*INICIO DEL CAMPO PERFIL SOCIAL*/}
                      {(dynamicQuestions.find(question => question.question_key === 'history_social_profile')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'history_social_profile')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'history_social_profile')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-12">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Perfil Social</b>
                            </Label>
                            <select
                              className="form-control"
                              id="escolarizacion"
                              value={perfilSocial}
                              onChange={(e) => setPerfilSocial(e.target.value)}
                            >
                              <option hidden>Seleccione perfil social</option>
                              <option value="institucion_infancia">
                                Hº de institución en infancia
                              </option>
                              <option value="migracion">
                                Proceso migratorio en mayoría de edad
                              </option>
                              <option value="otras_realidades">
                                Otras realidades(ruptura familiar,pobreza
                                estructural,minorías,etc)
                              </option>
                            </select>
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO PERFIL SOCIAL*/}
                      {/*INICIO DE PROBLEMATICAS*/}
                      {(dynamicQuestions.find(question => question.question_key === 'history_problematic')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'history_problematic')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'history_problematic')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-12">
                          <div className={`form-group form-group-default `}>
                            <b>Problemáticas</b>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="PR01"
                                id="problematicas01"
                                value="situacion_irregularuidad"
                                // checked={
                                //   problematicas.PR01 ===
                                //   "situacion_irregularuidad"
                                // }
                                onChange={handleCheckboxChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="problematicas01"
                              >
                                Situación de irregularidad
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="PR02"
                                id="problematicas02"
                                value="consumo_drogas"
                                // checked={problematicas.PR02 === "consumo_drogas"}
                                onChange={handleCheckboxChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="problematicas02"
                              >
                                Abuso de consumo de drogas sin tratamiento
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="PR03"
                                id="problematicas03"
                                value="salud_mental_sin_reconocer"
                                // checked={
                                //   problematicas.PR03 ===
                                //   "salud_mental_sin_reconocer"
                                // }
                                onChange={handleCheckboxChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="problematicas03"
                              >
                                Patología de salud mental sin reconocer
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="PR04"
                                id="problematicas04"
                                value="salud_mental_con_tratamiento"
                                // checked={
                                //   problematicas.PR04 ===
                                //   "salud_mental_con_tratamiento"
                                // }
                                onChange={handleCheckboxChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="problematicas04"
                              >
                                Patología de salud mental reconocida y con
                                tratamiento
                              </label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="PR05"
                                id="problematicas05"
                                value="otro"
                                // checked={problematicas.PR05 === "otro"}
                                onChange={handleCheckboxChange}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="problematicas05"
                              >
                                Otros
                              </label>
                            </div>
                          </div>
                        </div>
                      }
                      {/*FIN DE PROBLEMATICAS*/}
                      {/*INICIO DEL CAMPO FECHA ALTA*/}
                      {(dynamicQuestions.find(question => question.question_key === 'history_register_date')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'history_register_date')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'history_register_date')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-6">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Fecha de Alta</b>
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              placeholder="Introduzca los datos"
                              value={fechaAlta}
                              onChange={(e) => setFechaAlta(e.target.value)}
                            />
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO FECHA ALTA*/}
                      {/*INICIO DEL CAMPO FECHA BAJA*/}
                      {(dynamicQuestions.find(question => question.question_key === 'history_updown_date')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'history_updown_date')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'history_updown_date')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-6">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Fecha de Baja</b>
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              placeholder="Introduzca los datos"
                              value={fechaBaja}
                              onChange={(e) => setFechaBaja(e.target.value)}
                            />
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO FECHA BAJA*/}
                      {/*INICIO DEL CAMPO ENTIDAD PROCEDENCIA*/}
                      {(dynamicQuestions.find(question => question.question_key === 'history_entity')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'history_entity')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'history_entity')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-6">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Entidad de procedencia</b>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Introduzca los datos"
                              value={entidadProcedencia}
                              onChange={(e) =>
                                setentidadProcedencia(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO ENTINDAD PROCEDENCIA*/}
                      {/*INICIO DEL CAMPO MOTIVO DE BAJA*/}
                      {(dynamicQuestions.find(question => question.question_key === 'history_updown_reason')?.question_active &&
                        (dynamicQuestions.find(question => question.question_key === 'history_updown_reason')?.question_allowed_entities ?
                        JSON.parse(dynamicQuestions.find(question => question.question_key === 'history_updown_reason')?.question_allowed_entities)?.includes(entityData?.entity_id) : true)) &&
                        <div className="col-md-6">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Motivo de baja</b>
                            </Label>
                            <select
                              className="form-control"
                              id="baja"
                              value={motivoBaja}
                              onChange={(e) => setMotivoBaja(e.target.value)}
                            >
                              <option hidden>
                                Seleccione el motivo de baja
                              </option>
                              <option value="albergue_covadonga">
                                Abandono con aviso
                              </option>
                              <option value="asocicacion_albeniz">
                                Abandono sin aviso previo
                              </option>
                              <option value="caritas_madrid">Expulsión</option>
                              <option value="fundacion_putxel">
                                Superación del itinerario
                              </option>
                              <option value="hijas_caridad_madrid">
                                Superacón de edad máxima
                              </option>
                            </select>
                          </div>
                        </div>
                      }
                      {/*FIN DEL CAMPO MOTIVO DE BAJA*/}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/*FIN SECCION HISTORIAL DE INSTITUCIONALIZACION*/}


            {/*INICIO SECCION PREGUNTAS DINAMICAS*/}
            {(dynamicQuestionsValidated && dynamicQuestionsValidated.length) ?
              <div className="col-lg-12">
                <div className="card card-default">
                  <div className="card-body">
                    <h4>Preguntas adicionales</h4>
                    <form className="" role="form">
                      <div className="row">
                        {dynamicQuestionsValidated.map((question, index) => (
                          <div key={index} className="col-md-6">
                            <div className={`form-group form-group-default`}>
                              <Label htmlFor={question.question_key}><b>{question.question_text}</b></Label>
                              <Input id={question.question_key} type="text" className={`form-control`} placeholder="Introduzca los datos"
                                value={dynamicAnswers[question.question_key]?.answer_value || ''}
                                onChange={(e) => setDynamicAnswers({...dynamicAnswers, [question.question_key]: {
                                  question_id: question.id,
                                  answer_value: e.target.value
                                }})} />
                            </div>
                          </div>
                        ))}
                      </div>
                    </form>
                  </div>
                </div>
              </div> : null
            }
            {/*FIN SECCION PREGUNTAS DINAMICAS*/}
          </div>
        </PageContainer>
      </div>
      {/* END PAGE CONTENT */}
    </div>
  );
};

export default content;
