import React from "react";
import TableBasicWrapperComponent from "./Content";

const Component = ({ location }) => {
  let path = location.pathname;
	return (
    <div className={path.includes("/executive") || path.includes("/casual") ? "no-padding page-container" : "page-container"}>
			<TableBasicWrapperComponent path={path} />
		</div>
	);
};

export default Component;
