import React, { Fragment, useState } from "react";
import { Link } from 'react-router-dom';
import { ValidatorForm } from "react-form-validator-core";
import WithoutMsgValidation from "../../Forms/FormLayouts/WithoutMsgValidation";
import "../style.css";
import CompaniesList from "./CompaniesList";
import { CONSTANTS } from "../../../helpers/constants";

const content = () => {
  const [userName, setUserName] = useState("");
  const [paternalName, setPaternalName] = useState("");
  const [maternalName, setMaternalName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState("");
  const [company, setCompany] = useState(0);
  const [loading, setLoading] = useState(false);
  const [register, setRegister] = useState(false);

  let handleFormSubmit = async () => {
    setLoading(true);
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/user`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_email: email,
        user_password: password,
        user_name: userName,
        user_paternal_surname: paternalName,
        user_maternal_surname: maternalName,
        user_avatar: avatar,
        company_id: company 
      })
    });
    const res = await req.json();
    if (res.success) {
      setUserName("");
      setPaternalName("");
      setMaternalName("");
      setEmail("");
      setPassword("");
      setRegister(true);
      setAvatar("");
      setCompany(0);
    }
    setLoading(false);
  };

  return (
    <Fragment>
      <div className="register-container full-width sm-p-t-30 d-flex justify-content-center" style={{backgroundColor: '#fff', height: '100vh'}}>
        <div className="d-flex justify-content-center flex-column full-height-extra w-50">
          <img src="/assets/img/doomie/logo.png" alt="logo" data-src="/assets/img/doomie/logo.png" data-src-retina="/assets/img/doomie/logo.png" width="250" height="200" style={{objectFit: 'contain'}} />
          <h3>{!register ? 'Registrate para comenzar a usar el sistema.' : 'Tu usuario se registro con exito.'}</h3>
          <p>{!register ? 'Una vez que realices el registro, nuestros asesores validaran tu usuario' : 'Usuario registrado con exito, estamos validando tu usuario. Una vez terminada la validación, recibiras un correo de confirmación en tu bandeja de entrada.'}</p>
          {!register &&
            <ValidatorForm instantValidate={true} onSubmit={handleFormSubmit} className="p-t-15">
              <div className="row">
                <div className="col-md-12">
                  <WithoutMsgValidation
                    onChange={(e) => setUserName(e.target.value)} name="userName" id="userName" value={userName}
                    validators={["required"]} errorMessages={["Este campo es obligatorio"]} className={"form-control"} label={"Nombre(s)"} require="true"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <WithoutMsgValidation
                    onChange={(e) => setPaternalName(e.target.value)} name="paternalName" id="paternalName" value={paternalName}
                    validators={["required"]} errorMessages={["Este campo es obligatorio"]} className={"form-control"} label={"Primer apellido"} require="true"
                  />
                </div>
                <div className="col-md-6">
                  <WithoutMsgValidation
                    onChange={(e) => setMaternalName(e.target.value)} name="maternalName" id="maternalName" value={maternalName}
                    validators={[]} errorMessages={[]} className={"form-control"} label={"Segundo apellido"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <WithoutMsgValidation
                    onChange={(e) => setEmail(e.target.value)} name="email" id="email" value={email}
                    validators={["required", "isEmail"]} errorMessages={["Este campo es obligatorio", "El correo electrónico no tiene un formato valido"]} className={"form-control"} label={"Email"} require="true"
                  />
                </div>
                <div className="col-md-6">
                  <WithoutMsgValidation
                    onChange={(e) => setPassword(e.target.value)}  name="password" id="password" value={password} type="password"
                    validators={["required"]} errorMessages={["Este campo es obligatorio"]} className={"form-control"} label={"Contraseña"} require="true"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <CompaniesList setCompany={setCompany} required={true} />
                </div>
              </div>
              <button aria-label="" className="btn btn-primary btn-cons m-t-10" type="submit" disabled={loading}>{!loading ? 'Registrarme' : 'Creando cuenta...'}</button>
            </ValidatorForm>
          }
          {register && <div><Link to="/login" className="btn btn-primary btn-cons m-t-10">Ir al inicio</Link></div>}
        </div>
      </div>
    </Fragment>
  )
};

export default content;
