import React from "react";
import Copyright from "../ui/Footer/Copyright";

const content = () => {
	return (
        <React.Fragment>
            {/* START COPYRIGHT */}
            <Copyright
            brand={"COMPASSFACIAM"}
            reserved={""}
            terms={""}
            policy={""}
          />
          {/* END COPYRIGHT */}
        </React.Fragment>
	);
};

export default content;
