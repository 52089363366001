import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { max } from "lodash";
import { PolarArea } from "react-chartjs-2";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

export const polarchartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    r: {
      max: 3,
      min: 0,
      ticks: {
        stepSize: 0.5,
      },
    },
  },
};

const chartStyles = {
  display: "flex",
  justifyContent: "center",
  height: "600px",
};

const PolarChart = ({ data, id }) => {
  return (
    <div className="page-content-wrapper" style={chartStyles}>
      <PolarArea options={polarchartOptions} data={data} tabIndex={id} />
    </div>
  );
};

export default PolarChart;
