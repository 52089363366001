import React from "react";
import Modal from "react-bootstrap/Modal";
import { CONSTANTS } from "../../../../../helpers/constants";

const ConfirmDelete = ({ visible, currentForm, closeHandler, successCallback, failCallback }) => {
  const handleDeleteForm = async () => {
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/form/${currentForm.form_id}`, {
      method: 'DELETE',
      headers: {
        'x-access-token': CONSTANTS.TOKEN
      }
    });
    const res = await req.json();
    res.success ? successCallback(currentForm) : failCallback(res);
  }
  const handleClose = () => {
    closeHandler();
  }

  return (
    <Modal show={visible} onHide={handleClose} onEscapeKeyDown={handleClose} onExit={handleClose}>
      <Modal.Header closeButton>
        <h4>Eliminar formulario: <b>{currentForm.form_name}</b></h4>
      </Modal.Header>
      <Modal.Body>
        <p className="small-text">¿Estas seguro de eliminar el formulario <b>{currentForm.form_name}</b>?</p>
        <p className="small-text">Una vez realizada esta acción, no podras recuperarlo.</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary btn-cons" onClick={handleDeleteForm}>Eliminar</button>
        <div className="btn btn-cons" onClick={closeHandler}>Cancelar</div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDelete;
