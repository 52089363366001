import React from 'react'

import Sidebar from '../../../Theme-Condensed/Sidebar';
import Quickview from '../../../Quickview';
import Search from '../../../Search';
import Header from '../../../ui/Header';

const Component = ({location, children, inboxHeader}) => {
    return (
        <React.Fragment>
           <Sidebar location={location} />
            <Header location={location} inboxHeader={inboxHeader}/>
                {children}
            <Quickview/>
            <Search/>
        </React.Fragment>
    )
}

export default Component
