import React from 'react';
import { AiFillEye } from 'react-icons/ai';
import { ValidatorComponent } from 'react-form-validator-core';
import { transformPasswordField } from '../../../helpers/utils';

class LoginFieldValidate extends ValidatorComponent {
 
    render() {
        const { errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;
        
        return (
            <React.Fragment>
                <div className={`form-group form-group-default 
                    ${this.props.require ? 'required' : ''} ${this.state.isValid ? '' : 'has-error'}`} 
                    aria-required="true"
                >
                    <label>{this.props.label}</label>
                    <input
                        {...rest}
                        ref={(r) => { this.input = r; }}
                    />
                    {
                    this.props.type === 'password' &&
                        <i
                        style={{height: '25px', display: 'flex', float: 'right', position: 'absolute', top: '1.3rem', right: '2rem', cursor: 'pointer'}}
                        onClick={() => transformPasswordField(this.props.id)}>
                        <AiFillEye />
                        </i>
                    }
                </div>
            </React.Fragment>
        );
    }
}
 
export default LoginFieldValidate;