import React, { useState } from "react";
import "./style.scss";
import Header from "./header";
import { CONSTANTS } from "../helpers/constants";

const TAB_HEADERS = ["¿Qué es COMPASS?", "¿Cómo se usa?", "Quiénes somos"];
const BOTTOM_LOGOS = [
  "/assets/img/doomie/header1.png",
  "/assets/img/doomie/header3.png",
  "/assets/img/doomie/header2.png",
  "/assets/img/doomie/header4.png",
];

const LandingHome = ({ location }) => {
  let path = location.pathname;
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <div className={"no-padding page-container landingpage"}>
      <div className="page-content-wrapper">
        <Header />
        <div className="content p-0">
          <div style={{ padding: 0 }}>
            <div className="row">
              <div className="hero-banner">
                <img
                  src="/assets/img/doomie/landing-hero.png"
                  alt="main banner"
                  width={"100%"}
                />
                <img
                  src="/assets/img/doomie/faciam-logo.png"
                  alt="logo faciam"
                  className="logo"
                />
                <a
                  href={`${CONSTANTS.app_url}`}
                  className="landing-tabs-menu-item active btn"
                >
                  Entrar a la plataforma
                </a>
              </div>
              <div className="landing-content">
                <h1 className="landing-header">¡Bienvenido/a a COMPASS!</h1>
                <div className="landing-tabs">
                  <div className="flex flex-wrap justify-center landing-tabs-menu">
                    {TAB_HEADERS.map((tab, index) => (
                      <div
                        key={index}
                        className={`landing-tabs-menu-item ${
                          currentTab === index ? "active" : ""
                        }`}
                        onClick={() => setCurrentTab(index)}
                      >
                        {tab}
                      </div>
                    ))}
                    <img
                      src="/assets/img/doomie/logo.png"
                      alt="logo"
                      data-src="/assets/img/logo.png"
                      data-src-retina="/assets/img/logo_2x.png"
                      height="100"
                      width="200"
                      style={{objectFit: 'cover', marginLeft: '0.5rem'}}
                    />
                  </div>
                  <div className="landing-tabs-content">
                    {currentTab === 0 ? (
                      <div className="landing-tabs-content-item">
                        <p>
                          Se trata de una herramienta creada por la red FACIAM
                          en el marco del{" "}
                          <a href="https://futuroandco.org/" target="_blank">
                            proyecto Futuro&Co
                          </a>
                          , que permite a los equipos técnicos medir el nivel de
                          desinstitucionalización de las personas jóvenes con
                          las que trabajamos y analizar de manera individual los
                          avances de cada persona.
                        </p>
                        <p>
                          A través de la recopilación de datos sistematizados,
                          COMPASS nos permite conocer el impacto de que está
                          teniendo nuestro acompañamiento y saber si el efecto
                          que se está produciendo es el que estamos buscando,
                          además de ayudarnos a diseñar el acompañamiento en
                          base a las áreas de mejora identificadas.
                        </p>
                      </div>
                    ) : currentTab === 1 ? (
                      <div className="landing-tabs-content-item">
                        <p>
                          COMPASS es una aplicación informática de uso continuo
                          que nos va a permitir conocer la situación de cada
                          joven en su proceso de (desinstitucionalización), de
                          reconstrucción de su proyecto de vida, la eficacia de
                          las herramientas que usamos y la utilidad de los
                          apoyos prestados.
                        </p>
                        <p>
                          Para conocer en detalle cómo utilizar la herramienta,
                          a continuación, puedes acceder al{" "}
                          <a href="/assets/docs/ManualdeUsuarioCOMPASS.pdf" target="_blank">
                            manual de uso
                          </a>
                          .
                        </p>
                      </div>
                    ) : currentTab === 2 ? (
                      <div className="landing-tabs-content-item">
                        <p>
                          <b>Futuro&Co</b>
                        </p>
                        <p>
                          Futuro&Co, es un proyecto de innovación social creado
                          por la red FACIAM, cuyo objetivo es la autonomía y
                          emancipación de jóvenes de entre 18 y 29 años en
                          situación de sinhogarismo en España.
                        </p>
                        <p>
                          El proyecto es posible gracias al Ministerio de
                          Derechos Sociales y Agenda 2030 y a los fondos
                          europeos Next Generation, pero también gracias al
                          trabajo de 11 entidades lideradas por FACIAM presentes
                          en 7 ciudades: Barcelona, Gijón, Madrid, Salamanca,
                          Oviedo, Valencia y Zaragoza.
                        </p>
                        <p>
                          Además, el proyecto de cuenta con la colaboración del
                          Instituto Universitario de Cooperación y Desarrollo
                          (IUDC) y la Universidad Complutense de Madrid.
                        </p>
                        <p>
                          <b>Red FACIAM</b>
                        </p>
                        <p>
                          La red FACIAM nace en 1979 con la vocación del trabajo
                          en red para dar respuesta más eficaz al sinhogarismo
                          en aquellos años. Con más de 40 años de experiencia en
                          el trabajo con personas en situación de exclusión
                          socioresidencial, la red sigue desarrollando programas
                          y proyectos para este colectivo a través de las
                          organizaciones sociales que forman parte de ella.
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap items-center justify-between landing-logos-section">
                {BOTTOM_LOGOS.map((logo, index) => (
                  <img
                    key={index}
                    className="landing-bottom-logo"
                    src={logo}
                    alt={`Logo ${index + 1}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHome;
