import React from 'react';
import { AiFillEye } from 'react-icons/ai'
import { ValidatorComponent } from 'react-form-validator-core'
import { transformPasswordField } from '../../../helpers/utils';
 
class WithoutMsgValidation extends ValidatorComponent {

  render() {
    const { errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;
    return (
      <React.Fragment>
        <div className={`form-group form-group-default ${this.props.require ? 'required' : ''} ${this.state.isValid ? '' : 'has-error'}`} aria-required="true">
          <label htmlFor={this.props.id}>{this.props.label}</label>
          <div className="controls">
            <input id={this.props.id} {...rest} ref={(r) => { this.input = r; }} type={this.props.type || "text"} />
            {
              this.props.type === 'password' &&
                <i
                  style={{height: '20px', display: 'flex', float: 'right', position: 'absolute', top: '1.5rem', right: '2rem', cursor: 'pointer'}}
                  onClick={() => transformPasswordField(this.props.id)}>
                  <AiFillEye />
                </i>
            }
          </div>
        </div>
        {this.errorText()}
      </React.Fragment>
    );
  }
  errorText() {
    const { isValid } = this.state;
    if (isValid) return null;
    return <label className="error">{this.getErrorMessage()}</label>
  }
}
 
export default WithoutMsgValidation;