import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ValidatorForm } from "react-form-validator-core";
import WithoutMsgValidation from "../../../../Forms/FormLayouts/WithoutMsgValidation";
import Select from 'react-select';
import { CONSTANTS } from "../../../../../helpers/constants";

import { Typeahead } from "react-bootstrap-typeahead";
import { Input } from "reactstrap";

const QUESTION_TYPE_OPTIONS = [
  { value: 'abierta', label: 'Abierta' },
  { value: 'multiple', label: 'Opción multiple' },
]


const EditQuestion = ({ visible, currentForm, currentQuestion, closeHandler, successCallback, failCallback }) => {
  const [questionTypeError, setQuestionTypeError] = useState(false);
  const [questionData, setQuestionData] = useState({});
  const [checkEntities, setCheckEntities] = useState(false);
  const [entitiesList, setEntitiesList] = useState([]);
  const questionGroups = [];
  currentForm?.Questions?.map(({ question_group }) => question_group && !questionGroups.includes(question_group) && questionGroups.push(question_group));

  const getEntities = async () => {
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/entity`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': CONSTANTS.TOKEN
      }
    });
    const res = await req.json();
    if (res.success && res.data.length) setEntitiesList(res.data);
  }

  useEffect(() => {
    (async () => {
      if (!entitiesList.length) await getEntities();
      const entities = currentQuestion.question_allowed_entities ? JSON.parse(currentQuestion.question_allowed_entities) : null;
      setQuestionData({
        questionText: currentQuestion.question_text,
        questionType: currentQuestion.question_type,
        questionOptions: currentQuestion.question_options ? JSON.parse(currentQuestion.question_options) : null,
        questionGroup: currentQuestion.question_group,
        questionWidth: currentQuestion.question_width,
        showInEntities: entities
      });
      setCheckEntities((entities && entities.length) || false);
    })()
  }, [currentQuestion])

  useEffect(() => {
    if (!checkEntities) setQuestionData({ ...questionData, showInEntities: [] });
  }, [checkEntities])

  const handleClose = () => {
    closeHandler();
  }
  const handleUpdateQuestion = async () => {
    if (questionData.questionType === 'multiple' && (!questionData.questionOptions || questionData.questionOptions?.length < 2)) {
      setQuestionTypeError(true);
      return;
    }
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/question/${currentQuestion.question_id}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': CONSTANTS.TOKEN
      },
      body: JSON.stringify({
        question_text: questionData?.questionText,
        question_type: questionData?.questionType || 'abierta',
        question_options: questionData?.questionOptions ? JSON.stringify(questionData.questionOptions) : null,
        question_group: questionData?.questionGroup || '',
        question_width: questionData?.questionWidth ? parseInt(questionData?.questionWidth) : 1,
        question_allowed_entities: questionData?.showInEntities && questionData?.showInEntities.length ? JSON.stringify(questionData?.showInEntities) : null
      })
    });
    const res = await req.json();
    res.success ? successCallback(questionData) : failCallback(res);
  }

  return (
    <Modal show={visible} onHide={handleClose} onEscapeKeyDown={handleClose} onExit={handleClose} onClick={() => setQuestionTypeError(false)}>
      <ValidatorForm instantValidate={true} onSubmit={handleUpdateQuestion} className="p-t-15">
        <Modal.Header closeButton>
          <h4>Editar pregunta</h4>
        </Modal.Header>
        <Modal.Body>
          <p className="small-text">{currentQuestion.question_text}</p>
          <div className="row">
            <div className="col-md-12">
              <WithoutMsgValidation
                onChange={(e) => setQuestionData({ ...questionData, questionText: e.target.value })} value={questionData?.questionText || ''}
                name="questionText" placeholder="Texto de la pregunta" type="text"
                validators={["required"]} errorMessages={["Este campo es obligatorio"]} className={"form-control"} label="Pregunta" require="true"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 m-b-10">
              <Select name="questionType" options={QUESTION_TYPE_OPTIONS} 
                value={QUESTION_TYPE_OPTIONS.find(option => option.value === questionData.questionType)}
                onChange={(e) => setQuestionData({ ...questionData, questionType: e.value, questionOptions: null })}
              />
            </div>
          </div>
          {questionData?.questionType === 'multiple' &&
            <div className="row">
              <div className="col-md-12 m-b-10">
                <Typeahead id="questionOptions" allowNew multiple options={questionData.questionOptions || []} defaultSelected={questionData.questionOptions || []}
                  onChange={(e) => setQuestionData({ ...questionData, questionOptions: e.map(option => option.label || option) })}
                  newSelectionPrefix="Agregar opción: " placeholder="Escribe las opciones de respuesta..."
                />
              </div>
              {questionTypeError && <p className="red accentColor">Para las preguntas de opcion multiple, debes agregar al menos 2 opciones</p>}
            </div>
          }
          <div className="row">
            <div className="col-md-12 m-b-10">
              <Typeahead id="questionGroup" allowNew options={questionGroups} clearButton={true}
                selected={questionData.questionGroup ? [questionData.questionGroup] : []}
                onChange={(selected) => setQuestionData({ ...questionData, questionGroup: selected[0]?.label || selected[0] })}
                newSelectionPrefix="Agregar grupo: " placeholder="Escribe el grupo..."
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 m-b-10">
              <WithoutMsgValidation
                onChange={(e) => setQuestionData({ ...questionData, questionWidth: e.target.value })} value={questionData?.questionWidth || ''}
                name="questionWidth" placeholder="Medida que tomara la pregunta en el formulario" type="text"
                validators={["isNumber"]} errorMessages={["solo numeros"]} className={"form-control"} label="Medida de la pregunta"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 m-b-10">
              <div className="form-check form-check-inline complete">
                <Input type="checkbox" name="conditionalEntityCheck" id="conditionalEntityCheck" checked={checkEntities} onChange={() => setCheckEntities(!checkEntities)} />
                <label htmlFor="conditionalEntityCheck">Activación condicional por entidad</label>
              </div>
              {checkEntities &&
                <Typeahead id="conditionalEntityList" multiple options={entitiesList.map(entity => ({ value: entity.entity_id, label: entity.entity_name }))}
                  defaultSelected={questionData.showInEntities ? questionData.showInEntities.map(id => entitiesList.filter(entity => id === entity.entity_id ?? entity).map(obj => ({ value: obj.entity_id, label: obj.entity_name }))[0]) : []}
                  onChange={(e) => setQuestionData({ ...questionData, showInEntities: e.map(option => option.value) })}
                  placeholder="Seleccionar entidad(es)..."
                />
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary btn-cons" type="submit">Guardar</button>
          <div className="btn btn-cons" onClick={closeHandler}>Cancelar</div>
        </Modal.Footer>
      </ValidatorForm>
    </Modal>
  );
};

export default EditQuestion;
