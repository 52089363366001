export const filterKeys = {
  genero: "participante_gender",
  nacionalidad: "participante_nationality",
  history_acommodation_time: 'history_acommodation_time',
  history_tiempoSinVivienda: "history_previous_acommodation",
  history_situacionCalle: "history_nohome_state",
  history_agresionPrevia: "history_assault_state",
  history_problematica: "history_problematic",
  colectividadVivienda: 7,
  intermitenciaApoyos: 8,
  autonomiaEconomiaDomestica: 9,
  copago: 10,
  identidadJuridica: 11,
  atencionPsicologica: 12,
  asesoramientoJuridico: 13,
  itinerarioFormativo: 14,
  planMentoria: 15,
  asambleasConvivenciales: 16,
  ocioIndividual: 17,
  ocioGrupal: 18,
  voluntariado: 19,
  incipitMapeo: 20,
  incipitCollage: 21,
  incipitPensar: 22,
  discoForum: 23,
  carpeDiem: 24,
  ecomapa: 25,
  lineaDeFuturo: 26,
  podcast: 27,
  contextualizacionCierre: 28,
  pactosCuidados: 30,
  mapasAlma: 31,
  tallerCuidados: 32,
  mapasCorporales: 33,
};

export const filterNames = {
    genero: "Género",
    edadMinima: "Edad Mínima",
    edadMaxima: 'Edad Máxima',
    nacionalidad: "Nacionalidad",
    history_acommodation_time: 'Tiempo sin alojamiento',
    history_tiempoSinVivienda: "Tiempo sin vivienda",
    history_situacionCalle: "Situación de calle",
    history_agresionPrevia: "Agresión previa",
    history_problematica: "Problematicas",
    colectividadVivienda: "Colectividad de la vivienda",
    intermitenciaApoyos: "Intermitencia de apoyos",
    autonomiaEconomiaDomestica: "Grado de autonomía en economia doméstica",
    copago: "Copago",
    identidadJuridica: "Identidad Jurídica",
    atencionPsicologica: "Apoyos - Atención Psicológica",
    asesoramientoJuridico: "Apoyos - Asesoramiento Jurídico",
    itinerarioFormativo: "Apoyos - Itinerario Formativo Laboral",
    planMentoria: "Plan de mentoria",
    asambleasConvivenciales: "Participación - Asambleas convivenciales",
    ocioIndividual: "Participación - Ocio Individual",
    ocioGrupal: "Participación - Ocio Grupal",
    voluntariado: "Voluntariado",
    incipitMapeo: "Autonarrativas - Incipit - Mapeo",
    incipitCollage: "Autonarrativas - Incipit - Collage",
    incipitPensar: "Autonarrativas - Incipit - Bueno para pensar",
    discoForum: "Autonarrativas - Ruit - Disco Forum",
    carpeDiem: 'Autonarrativas - Ruit - Carpe Diem',
    ecomapa: "Autonarrativas - Ruit - Ecomapa",
    lineaDeFuturo: "Autonarrativas - Exit - Línea de futuro",
    podcast: "Autonarrativas - Exit - Podcast",
    contextualizacionCierre: "Autonarrativas - Exit - Contextualización de cierre",
    pactosCuidados: "Salud - Pactos de cuidados",
    mapasAlma: "Salud - Mapas del alma",
    tallerCuidados: "Salud - Taller de cuidados",
    mapasCorporales: "Salud - Mapas corporales",
  };