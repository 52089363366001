import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-form-validator-core";
import ForgotPasswordModal from "./ForgotPasswordModal";
import LoginFieldValidate from "./LoginFieldValidate";
import { login } from "../../../lib/User";
import { isLogged } from "../../../helpers/utils";
import FlipBarNotifyModule from "../../UIElements/Notification/FlipBarNotification/FlipBarNotifyModule";

import "./blank_page_style.css";

const content = ({ path }) => {
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
	const [notification, setNotification] = useState(null);

	let handleFormSubmit = async () => {
		const user = await login(userName, password);
		if(!user) setNotification(true)
		return user;
	};

	let closeModal = () => {
		setShowForgotPasswordModal(false);
	}

	useEffect(() => {
		if (notification && notification.timeout) {
		  setTimeout(() => {
			setNotification(null);
		  }, notification.timeout);
		}
	  }, [notification])

	useEffect(() => {
		(async () => {
		  const logged = await isLogged();
		  if(logged){
			window.location.href = '/home'
		  }
		})()
	  }, [])

	return (
		<React.Fragment>
			{notification && <FlipBarNotifyModule notifications={[{ type: 'error', desc: 'Usuario o contraseña incorrectos' }]} position={notification.position || 'top-right'} />}
			<ForgotPasswordModal visible={showForgotPasswordModal} setVisible={closeModal} closeHandler={() => setShowForgotPasswordModal(false)} />
		<div className="login-wrapper ">
			<div className="bg-pic">
				{/* <div className="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 m-b-20">
					<h1 className="semi-bold text-white">
						Meet pages - The simplest and fastest way to build web UI for your
						dashboard or app.
					</h1>
					<p className="small">
						Our beautifully-designed UI Framework come with hundreds of
						customizable features. Every Layout is just a starting point.
						©2019-2020 All Rights Reserved. Pages® is a registered trademark of
						Revox Ltd.
					</p>
				</div> */}
			</div>
			<div className="login-container bg-white">
				<div className="p-l-50 p-r-50 p-t-50 m-t-30 sm-p-l-15 sm-p-r-15 sm-p-t-40">
					<img
						src="/assets/img/doomie/faciam-logo.png"
						alt="logo"
						data-src="/assets/img/doomie/faciam-logo.png"
						data-src-retina="/assets/img/doomie/faciam-logo.png"
						width="250"
						height="100"
					/>
					<h2 className="p-t-25">
						Accede a <br /> tu panel
					</h2>
					<p className="mw-80 m-t-5">Inicia sesión con tu cuenta</p>
          <ValidatorForm
            instantValidate={true}
            onSubmit={handleFormSubmit}
            className="p-t-15"
          >
            <LoginFieldValidate
              onChange={(e) => setUserName(e.target.value)}
              name="correo"
              value={userName}
              validators={["required", "isEmail"]}
              errorMessages={[]}
              className={"form-control"}
              label={"Iniciar sesión"}
              placeholder="Correo electrónico"
              require="true"
            />

            <LoginFieldValidate
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              value={password}
              validators={["required"]}
              errorMessages={[]}
              className={"form-control"}
              label={"Password"}
              id="password"
              type={"password"}
              placeholder="Contraseña"
              require="true"
            />
						<div className="row">
							{/* <div className="col-md-6 no-padding sm-p-l-10">
								<div className="form-check">
									<input type="checkbox" value="1" id="checkbox1" />
									<label htmlFor="checkbox1">Recuerdame</label>
								</div>
							</div> */}
							<div className="col-md-6 d-flex align-items-center justify-content-end">
								<button
									aria-label=""
									className="btn btn-primary btn-lg m-t-10"
									type="submit"
								>
									Iniciar sesión
								</button>
							</div>
						</div>
						<div className="m-b-5 m-t-30">
							<a href="#" className="normal" onClick={() => setShowForgotPasswordModal(true)}>
								¿Olvidaste tu contraseña?
							</a>
						</div>
						<div>
              <a href="/condensed/extra/register" className="normal">¿Aún no eres miembro? Regístrese ahora.</a>
						</div>
					</ValidatorForm>
				</div>
			</div>
		</div>
		</React.Fragment>
	);
};

export default content;
