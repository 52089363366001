import React from "react";
import FormsElementWrapperComponent from "./Content";
import Record from "./Record";

const Component = ({ location }) => {
  const urlParams = new URLSearchParams(window.location.search);
  let path = location.pathname;
  return (
    //<DefaultLayout location={location}>
    <div className={"page-container"}>
      {/* START PAGE CONTENT WRAPPER */}
      {urlParams.get("create") || urlParams.get("complete") ? (
        <FormsElementWrapperComponent path={path} />
      ) : (
        <Record />
      )}
      {/* END PAGE CONTENT WRAPPER */}
    </div>
    //</DefaultLayout>
  );
};

export default Component;
