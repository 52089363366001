import React, { useEffect, useState, useRef } from "react";
import PageContainer from "../UIElements/Containers";
import "../Dashboard/styles.css";
import { CONSTANTS } from "../../helpers/constants";
import SingleReportButton from "./reports/SingleReportButton";
import BarChart from "./charts/BarChart";

const Filtrado = ({ path }) => {
  const userLogged = JSON.parse(sessionStorage.getItem("user"));
  const urlParams = new URLSearchParams(window.location.search);
  const participantsSelected = urlParams
    .get("participants_selected")
    .split(",");
  const targetRef = useRef();
  const [participants, setParticipants] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [dataset, setDataset] = useState();
  let filtered;

  useEffect(() => {
    (async () => {
      participantsSelected.forEach(async (participantSelected) => {
        const getParticipants = await fetch(
          `${CONSTANTS.base_url}api/v1/data/participants/${participantSelected}`,
          {
            headers: {
              "x-access-token": CONSTANTS.TOKEN,
            },
          }
        );
        const participant = await getParticipants.json();
        setParticipants((prevParticipants) =>
          prevParticipants.concat(participant.data)
        );
      });
    })();
  }, []);

  useEffect(() => {
    if (participants && participants.length > 1) {
      generateDataSets(participants);
    }
  }, [participants]);

  function random_rgba() {
    const hue = Math.random() * 360;
    const lightness = Math.random() * 75;
    const randomColor = `hsl(${hue} 100% ${15 + lightness}% / .6)`;

    return randomColor;
  }

  const groupByKey = (list, key) => {
    return [key, list]
  }

  const generateDataSets = async (data) => {
    const participantsGroups = await generateParticipantGroups(data);
    const participantsSelected = Object.keys(participantsGroups);
    const colors = [];
    const datasets = [];
    const labels = [
      "AUTOCONCEPTO - AUTOVALORACIÓN",
      "EXPECTATIVAS - VALORACIÓN PROFESIONAL",
      "ATRIBUCIÓN DE CAUSALIDAD - VALORACIÓN PROFESIONAL",
      "ATRIBUCIÓN DE CAUSALIDAD - AUTOVALORACIÓN",
      "MANEJO DE LA INCERTIDUMBRE - VALORACIÓN PROFESIONAL",
      "CONDUCTAS DE SALUD/BIENESTAR - VALORACIÓN PROFESIONAL",
      "RESILENCIA - AUTOVALORACIÓN",
      "ESPIRITUALIDAD - AUTOVALORACIÓN",
      "IDENTIDAD DIGITAL - AUTOVALORACIÓN",
      "CONCIENCIA DE CIUDADANÍA - VALORACIÓN PROFESIONAL",
      "PERTENENCIA A GRUPOS - VALORACIÓN PROFESIONAL",
      "SOCIALIZACIÓN, MANEJO DE LAS NORMAS - VALORACIÓN PROFESIONAL",
      "SEGURIDAD ONTOLÓGICA - VALORACIÓN PROFESIONAL",
      "SENTIDO DE PERTENENCIA - VALORACIÓN PROFESIONAL",
      "GARANTIA DE INGRESOS - VALORACIÓN PROFESIONAL",
      "ACCESO A SERVICIOS EN LA COMUNIDAD Y APOYO COMUNITARIO - AUTOVALORACIÓN",
      "PARTICIPACIÓN EN REDES SOCIALES DIGITALES - VALORACIÓN PROFESIONAL",
      "OCIO - VALORACIÓN PROFESIONAL",
      "APOYO SOCIAL (REDES NATURALES) - AUTOVALORACIÓN",
      "POSIBILIDAD DE ACCESO A LA VIVIENDA - VALORACIÓN PROFESIONAL",
      "CONVIVENCIA VECINAL - VALORACIÓN PROFESIONAL",
      "BRECHA DIGITAL,ACCESO Y EVALUCIÓN DE LA INFORMACIÓN  - AUTOVALORACIÓN",
      "IDIOMA  - AUTOVALORACIÓN",
      "EMPLEABILIDAD  - VALORACIÓN PROFESIONAL",
      "DESTREZAS FORMATIVAS  - VALORACIÓN PROFESIONAL",
      "MOVILIDAD  - VALORACIÓN PROFESIONAL",
      "AUTONOMÍA EN LA INTERACCIÓN CON LAS ADMINISTRACIONES  - VALORACIÓN PROFESIONAL",
      "DESARROLLO DE CONCIENCIA DE DESINSTITUCIONALIZACIÓN  - VALORACIÓN PROFESIONAL",
      "HABILIDADES SOCIALES  - VALORACIÓN PROFESIONAL",
      "HABILIDADES CONVIVENCIALES  - AUTOVALORACIÓN",
      "AUTONOMÍA EN LA GESTIÓN DE LA SALIDA  - VALORACIÓN PROFESIONAL",
    ];
    participantsSelected.map((participant) => {
      const data = [];
      labels.forEach((group) => {
        if (
          participantsGroups?.[participant]?.[group] &&
          participantsGroups?.[participant]?.[group].length >= 1
        ) {
          const groupedAnswers = groupByKey(
            participantsGroups?.[participant]?.[group],
            group
          );
          const answersValues = Object.values(groupedAnswers);
          const answersSum = [];
          answersValues[1].map((element, idx) => {
            if (element) {
              const sumAnswers = Number(element.answer_value || 0);
              answersSum.push(sumAnswers);
            }
          });
          if (answersSum.length >= 1) {
            data.push(
              answersSum?.reduce((a, b) => Number(a) + Number(b)) / (answersSum.length )
            );
          }
          colors.push(random_rgba());
        }
      });
      if (data && data.length >= 1) {
        datasets.push({
          label: participant,
          data: data,
          backgroundColor: colors,
          borderColor: colors,
        });
      }
    });
    generateDataSetResults(datasets);
  };

  const generateDataSetResults = async (datasets) => {
    const cuidadoAutocuidadoAccepted = [
      1, 2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 17, 18, 19, 26, 27, 28, 30,
      31, 32, 33, 34,
    ];
    const trabajoPersonalAccepted = [
      1, 2, 15, 22, 23, 25, 26, 27, 28, 31, 32, 33, 34,
    ];
    const participacionAccepted = [
      1, 10, 11, 12, 14, 16, 18, 19, 21, 22, 23, 27, 28, 29, 30, 31, 32, 34,
    ];
    const emancipacionAccepted = [
      13, 2, 5, 7, 9, 11, 15, 16, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 28,
      29, 30, 31, 32, 33, 34, 35,
    ];
    const cuidadoAutocuidado = [];
    const trabajoPersonal = [];
    const participacion = [];
    const emancipacion = [];
    const indiceGeneral = [];
    const data = [];
    let cuidadoAutocuidadoRes;
    let trabajoPersonalRes;
    let participacionRes;
    let emancipacionRes;
    let indiceGeneralRes;
    const colors = [];
    if (datasets.length) {
      datasets.map((dataset, idx) => {
        const cuidadoAutocuidadoMap = [];
        const trabajoPersonalMap = [];
        const participacionMap = [];
        const emancipacionMap = [];
        const indiceGeneralMap = [];
        dataset.data.map((value, dataIdx) => {
          if (cuidadoAutocuidadoAccepted.includes(dataIdx)) {
            cuidadoAutocuidadoMap.push(value || 0);
          }
          if (trabajoPersonalAccepted.includes(dataIdx)) {
            trabajoPersonalMap.push(value || 0);
          }
          if (participacionAccepted.includes(dataIdx)) {
            participacionMap.push(value || 0);
          }
          if (emancipacionAccepted.includes(dataIdx)) {
            emancipacionMap.push(value || 0);
          }
          indiceGeneralMap.push(value || 0);
        });
        cuidadoAutocuidado.push(
          cuidadoAutocuidadoMap.reduce((a, b) => Number(a) + Number(b)) /
            (cuidadoAutocuidadoMap.length * 3)* 100
        );
        trabajoPersonal.push(
          trabajoPersonalMap.reduce((a, b) => Number(a) + Number(b)) /
            (trabajoPersonalMap.length * 3)* 100
        );
        participacion.push(
          participacionMap.reduce((a, b) => Number(a) + Number(b)) /
            (participacionMap.length * 3)* 100
        );
        emancipacion.push(
          emancipacionMap.reduce((a, b) => Number(a) + Number(b)) /
            (emancipacionMap.length * 3)* 100
        );
        indiceGeneral.push(
          indiceGeneralMap.reduce((a, b) => Number(a) + Number(b)) /
            (indiceGeneralMap.length * 3)* 100
        );
        cuidadoAutocuidadoRes =
          cuidadoAutocuidado.reduce((a, b) => Number(a) + Number(b)) / cuidadoAutocuidado.length;
        trabajoPersonalRes =
          trabajoPersonal.reduce((a, b) => Number(a) + Number(b)) / trabajoPersonal.length;
        participacionRes =
          participacion.reduce((a, b) => Number(a) + Number(b)) / participacion.length;
        emancipacionRes =
          emancipacion.reduce((a, b) => Number(a) + Number(b)) / emancipacion.length;
        indiceGeneralRes =
          indiceGeneral.reduce((a, b) => Number(a) + Number(b)) / indiceGeneral.length;

        data.push({
          label: dataset.label,
          data: [
            cuidadoAutocuidadoRes.toFixed(2),
            trabajoPersonalRes.toFixed(2),
            participacionRes.toFixed(2),
            emancipacionRes.toFixed(2),
            indiceGeneralRes.toFixed(2)
          ],
          backgroundColor: random_rgba(),
          borderColor: random_rgba(),
        });
      });

      setDataset({
        labels: [
          "Cuidado y autocuidado",
          "Trabajo personal y activación de competencias",
          "Participación y vida en la Comunidad",
          "Emancipación",
          "Índice general de resultados",
        ],
        datasets: [...data],
      });
    }
  };

  const generateParticipantGroups = (participants) => {
    let selectedParticipants = [];
    participants.map((participant) => {
      selectedParticipants.push(
        `${participant.participante_id}.${participant.participante_name}`
      );
    });
    const participantsArr = selectedParticipants.reduce(async (acc, val) => {
      const result = await acc;
      const id = val.split(".")[0];
      const value = await getAnswersByParticipant(id);
      return { ...result, [val]: value };
    }, {});
    return participantsArr;
  };

  const getAnswersByParticipant = async (participant_id) => {
    const getParticipant = participants.filter(
      (participant) => participant.participante_id == participant_id
    );

    const answersArr = getParticipant[0]?.Answers?.filter(
      (answer) => answer.Question.form_id == 2
    );
    if (answersArr?.length >= 1 && typeof answersArr == "object") {
      const grouped = answersArr?.reduce((acc, currentValue) => {
        let groupKey = currentValue.Question.question_group;
        if (!acc[groupKey]) {
          acc[groupKey] = [];
        }
        acc[groupKey].push(currentValue);
        return acc;
      }, {});
      if (
        grouped["APOYO SOCIAL (REDES NATURALES) - AUTOVALORACIÓN"] &&
        grouped["APOYO SOCIAL (REDES NATURALES) - AUTOVALORACIÓN"].length >= 1
      ) {
        const filtered = grouped[
          "APOYO SOCIAL (REDES NATURALES) - AUTOVALORACIÓN"
        ].filter((question) => question.Question.question_type != "varias");
        grouped["APOYO SOCIAL (REDES NATURALES) - AUTOVALORACIÓN"] = filtered;
      }

      return grouped;
    }
    return null;
  };

  return (
    <div className="page-content-wrapper">
      <div className="content" style={{ paddingTop: "60px" }}>
        <PageContainer>
          {dataset && (
            <PageContainer>
              <SingleReportButton
                body={targetRef}
                orientation={"l"}
                participant_name={"Reporte comparativo de participantes"}
                text={"Descargar informe"}
              />
              <div ref={targetRef}>
                <div className="container w-100">
                  <BarChart data={dataset} />
                </div>
                <div className="data">
                  Participantes seleccionados :{" "}
                  {participants &&
                    participants.length >= 1 &&
                    participants.map((value, pIdx) => (
                      <div className="data">
                        <ul>
                          <li>
                            <div>
                              {`${value.participante_name} ${value.participante_lastname} - ${value.participante_code}`}
                            </div>
                            <div>
                              Resultados:{" "}
                              {dataset.labels &&
                                dataset.labels.length >= 1 &&
                                dataset.labels.map((value, idx) => (
                                  // value.data.map((item) => console.log(dataset.labels[idx],item))
                                  <ul>
                                    <li>{`${dataset.labels[idx]} : ${dataset?.datasets[pIdx]?.data[idx]}`}</li>
                                  </ul>
                                ))}
                            </div>
                          </li>
                        </ul>
                      </div>
                    ))}
                </div>
              </div>
            </PageContainer>
          )}
        </PageContainer>
      </div>
    </div>
  );
};

export default Filtrado;
