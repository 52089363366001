import React, { useEffect, useState, useRef } from "react";
import PageBreadcrumb from "../UIElements/Breadcrumb";
import PageContainer from "../UIElements/Containers";
import PageScroll from "../UIElements/PageScroll";
import "../Dashboard/styles.css";
import { CONSTANTS } from "../../helpers/constants";
import PolarChart from "./charts/PolarChart";
import RadarChart from "./charts/RadarChart";
import SingleReportButton from "./reports/SingleReportButton";
import BarChart from "./charts/BarChart";
import LineChart from "./charts/LineChart";

const Evolutivo = ({ path }) => {
  const targetRef = useRef();
  const urlParams = new URLSearchParams(window.location.search);
  const dates = urlParams.get("dates").split(",");
  const [answersByDate, setAnswersByDate] = useState([]);
  const [groups, setQuestionGroups] = useState([]);
  const [participant, setParticipant] = useState(null);
  const [dataset, setDataset] = useState();

  useEffect(() => {
    (async () => {
      if (dates) {
        dates.forEach(async (date) => {
          const getAnswers = await fetch(
            `${
              CONSTANTS.base_url
            }api/v1/data/question/form_participant/2/${urlParams.get(
              "participante_id"
            )}/${date}`,
            {
              headers: {
                "x-access-token": CONSTANTS.TOKEN,
              },
            }
          );
          const answers = await getAnswers.json();
          const grouped = answers.data.reduce((acc, currentValue) => {
            let groupKey = currentValue.question_group;
            if (!acc[groupKey]) {
              acc[groupKey] = [];
            }
            acc[groupKey].push(currentValue);
            return acc;
          }, {});
          if (
            grouped["APOYO SOCIAL (REDES NATURALES) - AUTOVALORACIÓN"] &&
            grouped["APOYO SOCIAL (REDES NATURALES) - AUTOVALORACIÓN"].length >=
              1
          ) {
            const filtered = grouped[
              "APOYO SOCIAL (REDES NATURALES) - AUTOVALORACIÓN"
            ].filter((question) => question.question_type != "varias");
            grouped["APOYO SOCIAL (REDES NATURALES) - AUTOVALORACIÓN"] =
              filtered;
          }
          setQuestionGroups(Object.keys(grouped));
          setAnswersByDate((prevAnswers) => prevAnswers.concat(grouped));
        });
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const getParticipant = await fetch(
        `${CONSTANTS.base_url}api/v1/data/participants/${urlParams.get(
          "participante_id"
        )}`,
        {
          headers: {
            "x-access-token": CONSTANTS.TOKEN,
          },
        }
      );
      const participant = await getParticipant.json();
      setParticipant(participant.data);
    })();
  }, []);

  function random_rgba() {
    const hue = Math.random() * 360;
    const lightness = Math.random() * 75;
    const randomColor = `hsl(${hue} 100% ${15 + lightness}% / .6)`;

    return randomColor;
  }

  const generateDoughnut = () => {
    const colors = [];
    const datasets = [];
    const labels = [...groups];
    if (answersByDate && answersByDate.length >= 1) {
      let date;
      // console.log(answersByDate)
      answersByDate.forEach((answer, idx) => {
        const data = [];
        date =
          answer["AUTOCONCEPTO - AUTOVALORACIÓN"][0]?.Answers[0].answer_date;
        groups.forEach((group) => {
          const answersSum = [];
          answer[group].forEach((element) => {
            if (element.Answers && element.Answers.length) {
              const sumAnswers =
                element.Answers.length > 1
                  ? element.Answers.reduce(
                      (a, b) => Number(a.answer_value) + Number(b.answer_value)
                    )
                  : Number(element.Answers[0].answer_value);
              answersSum.push(sumAnswers / element.Answers.length);
            }
          });
          data.push(
            answersSum.reduce((a, b) => Number(a) + Number(b)) /
              answersSum.length
          );
          colors.push(random_rgba());
        });
        datasets.push({
          label: date,
          data: data,
          backgroundColor: colors,
          borderColor: colors,
        });
      });
    }

    return {
      labels,
      datasets,
    };
  };

  const generateDataSetResults = () => {
    const colors = [];
    const datasets = [];
    const data = [];
    answersByDate.forEach((answer, idx) => {
      const cuidadoAutocuidadoAccepted = [
        1,2,3,4,5,6,7,8,10,11,12,13,14,17,18,19,26,27,28,30,31,32,33,34
      ];
      const trabajoPersonalAccepted = [
        22,23,25,26,27,28,33,34,31,32,15,1,2,3,4,5,6,7,8,9,10,13,14
      ];
      const participacionAccepted = [
        1, 10, 11, 12, 14, 16, 18, 19, 21, 22, 23, 27, 28, 29, 30, 31, 32, 34,
      ];
      const emancipacionAccepted = [
        13, 2, 5, 7, 9, 11, 15, 16, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27, 28,
        29, 30, 31, 32, 33, 34, 35,
      ];
      const cuidadoAutocuidado = [];
      const trabajoPersonal = [];
      const participacion = [];
      const emancipacion = [];
      const indiceGeneral = [];
      let cuidadoAutocuidadoRes;
      let trabajoPersonalRes;
      let participacionRes;
      let emancipacionRes;
      let indiceGeneralRes;
      let date;
      date = answer["AUTOCONCEPTO - AUTOVALORACIÓN"][0]?.Answers[0].answer_date;
      groups.forEach((group, gIdx) => {
        let cuidadoAutocuidadoMap = [];
        let trabajoPersonalMap = [];
        let participacionMap = [];
        let emancipacionMap = [];
        let indiceGeneralMap = [];
        if (cuidadoAutocuidadoAccepted.includes(gIdx)) {
          answer[group].forEach((element, idx) => {
            cuidadoAutocuidadoMap.push(
              Number(element.Answers[idx]?.answer_value) || 0
            );
          });
          cuidadoAutocuidado.push(
            cuidadoAutocuidadoMap.reduce((a, b) => Number(a) + Number(b))
          );
        }
        if (trabajoPersonalAccepted.includes(gIdx)) {
          answer[group].forEach((element, idx) => {
            trabajoPersonalMap.push(
              Number(element.Answers[idx]?.answer_value) || 0
            );
          });
          trabajoPersonal.push(
            trabajoPersonalMap.reduce((a, b) => Number(a) + Number(b))
          );
        }
        if (participacionAccepted.includes(gIdx)) {
          answer[group].forEach((element, idx) => {
            participacionMap.push(
              Number(element.Answers[idx]?.answer_value) || 0
            );
          });
          participacion.push(
            participacionMap.reduce((a, b) => Number(a) + Number(b))
          );
        }
        if (emancipacionAccepted.includes(gIdx)) {
          answer[group].forEach((element, idx) => {
            console.log(element);
            emancipacionMap.push(
              Number(element.Answers[idx]?.answer_value) || 0
            );
          });
          emancipacion.push(
            emancipacionMap.reduce((a, b) => Number(a) + Number(b))
          );
        }

        answer[group].forEach((element, idx) => {
          indiceGeneralMap.push(
            Number(element.Answers[idx]?.answer_value) || 0
          );
        });
        indiceGeneral.push(
          indiceGeneralMap.reduce((a, b) => Number(a) + Number(b))
        );
        cuidadoAutocuidadoMap = [];
        trabajoPersonalMap = [];
        participacionMap = [];
        emancipacionMap = [];
        indiceGeneralMap = [];
      });
      colors.push(random_rgba());
      cuidadoAutocuidadoRes =
        (cuidadoAutocuidado.reduce((a, b) => Number(a) + Number(b)) /
          (cuidadoAutocuidado.length * 3)) *
        100;
      trabajoPersonalRes =
        (trabajoPersonal.reduce((a, b) => Number(a) + Number(b)) /
          (trabajoPersonal.length * 3)) *
        100;
      participacionRes =
        (participacion.reduce((a, b) => Number(a) + Number(b)) /
          (participacion.length * 3)) *
        100;
      emancipacionRes =
        (emancipacion.reduce((a, b) => Number(a) + Number(b)) /
          (emancipacion.length * 3)) *
        100;
      indiceGeneralRes =
        (indiceGeneral.reduce((a, b) => Number(a) + Number(b)) /
          (indiceGeneral.length * 3)) *
        100;

      data.push({
        label: date,
        data: [
          cuidadoAutocuidadoRes.toFixed(2),
          trabajoPersonalRes.toFixed(2),
          participacionRes.toFixed(2),
          emancipacionRes.toFixed(2),
          indiceGeneralRes.toFixed(2),
        ],
        backgroundColor: random_rgba(),
        borderColor: random_rgba(),
      });
    });
    console.log(data);

    return {
      labels: [
        "Cuidado y autocuidado",
        "Trabajo personal y activación de competencias",
        "Participación y vida en la Comunidad",
        "Emancipación",
        "Indice general de resultados",
      ],
      datasets: [...data],
    };
  };

  return (
    <div className="page-content-wrapper">
      <div className="content" style={{ paddingTop: "60px" }}>
        {answersByDate && groups && (
          <PageContainer>
            {participant && (
              <PageScroll>
                <PageBreadcrumb>
                  <li className="breadcrumb-item">
                    <a
                      href={`/participants_form?participant_id=${participant.participante_id}`}
                    >
                      {participant.participante_name}
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Dashboards</li>
                  <li style={{ display: "flex", justifyContent: "flex-end" }}>
                    <SingleReportButton
                      body={targetRef}
                      orientation={"landscape"}
                      participant_name={participant.participante_name}
                    />
                  </li>
                </PageBreadcrumb>
              </PageScroll>
            )}
            <div ref={targetRef}>
              {participant && (
                <h2>
                  Reporte evolutivo del participante{" "}
                  {participant.participante_name} -{" "}
                  {participant.participante_code}
                </h2>
              )}

              <BarChart data={generateDataSetResults()}></BarChart>
              <LineChart data={generateDoughnut()}></LineChart>
            </div>
          </PageContainer>
        )}
      </div>
    </div>
  );
};

export default Evolutivo;
