import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ValidatorForm } from "react-form-validator-core";
import WithoutMsgValidation from "../../Forms/FormLayouts/WithoutMsgValidation";
import { updatePasswordForgotten } from "../../../lib/User";

const ForgotPasswordModal = ({ visible, setVisible, closeHandler }) => {
    const [email, setEmail] = useState("");

    const handleSendForm = async () => {
        const updatePassword = await updatePasswordForgotten(email);
        console.log(updatePassword)
        setEmail("");
        if(updatePassword){
            setVisible();
        }
      }

  return (
    visible &&
    <Modal show={visible} onHide={closeHandler} onEscapeKeyDown={closeHandler} onExit={closeHandler} >
        <ValidatorForm instantValidate={true} onSubmit={handleSendForm} className="p-t-15">
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <p className="h5 p-3">Se enviará un correo electrónico al correo proporcionado con tu nueva contraseña</p>
                <div className="row">
                    <div className="col-md-12">
                    <WithoutMsgValidation
                        onChange={(e) => setEmail(e.target.value)} value={email}
                        name="email" placeholder="Correo electrónico" type="text"
                        validators={["required"]} errorMessages={["Este campo es obligatorio"]} className={"form-control"} label="Correo" require="true"
                    />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary btn-cons" type="submit">Enviar</button>
                <div className="btn btn-cons" onClick={closeHandler}>Cancelar</div>
            </Modal.Footer>
        </ValidatorForm>
    </Modal>
  );
};

export default ForgotPasswordModal;
