import React from "react";
import Modal from "react-bootstrap/Modal";
import { CONSTANTS } from "../../../../../helpers/constants";

const ConfirmActiveStatus = ({ visible, currentData, closeHandler, successCallback, failCallback }) => {
  const handleUpdateQuestion = async () => {
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/participante_questions/${currentData.id}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': CONSTANTS.TOKEN
      },
      body: JSON.stringify({
        question_active: !currentData.question_active,
      })
    });
    const res = await req.json();
    res.success ? successCallback(res) : failCallback(res);
  }


  const handleClose = () => {
    closeHandler();
  }

  return (
    <Modal show={visible} onHide={handleClose} onEscapeKeyDown={handleClose} onExit={handleClose}>
      <Modal.Header closeButton>
        <h4>{currentData?.question_active ? 'Desactivar' : 'Activar'} pregunta</h4>
      </Modal.Header>
      <Modal.Body>
        <p className="small-text">¿Estas seguro de {currentData?.question_active ? 'desactivar' : 'activar'} la pregunta?</p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary btn-cons" onClick={handleUpdateQuestion}>{currentData?.question_active ? 'Desactivar' : 'Activar'}</button>
        <div className="btn btn-cons" onClick={closeHandler}>Cancelar</div>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmActiveStatus;
