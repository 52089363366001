import React from "react";
import "./header.scss";

const Header = ({ location }) => {
  const BOTTOM_LOGOS = [
    "/assets/img/doomie/header2.png",
    "/assets/img/doomie/header3.png",
    "/assets/img/doomie/header4.png",
  ];
  return (
    <div
      className={`header`}
      style={{ backgroundColor: "#addc71", minHeight: "6vh" }}
    >
      <div className="container flex w-100">
        <div
          className="flex flex-wrap items-center justify-between landing-logos-section"
          style={{ backgroundColor: "transparent" }}
        >
          <img
            className="landing-bottom-logo"
            src={"/assets/img/doomie/header1.png"}
            alt={`Logo Futuro And Co`}
          />
        </div>
        <React.Fragment>
          <div
            className="flex flex-wrap items-center justify-between landing-logos-section"
            style={{ backgroundColor: "transparent" }}
          >
            {BOTTOM_LOGOS.map((logo, index) => (
              <img
                key={index}
                className="landing-bottom-logo"
                src={logo}
                alt={`Logo ${index + 1}`}
              />
            ))}
          </div>
        </React.Fragment>
      </div>
    </div>
  );
};

export default Header;
