import React, { useEffect, useState } from "react";
import { ValidatorForm } from "react-form-validator-core";

import WithoutMsgValidation from "../FormLayouts/WithoutMsgValidation";
import PageContainer from "../../UIElements/Containers";
import { CONSTANTS } from "../../../helpers/constants";

import "react-quill/dist/quill.snow.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";

import "../Elements/styles.css";
import CompaniesList from "../../Extra/register/CompaniesList";

const content = ({ path }) => {
  const [userName, setUserName] = useState("");
  const [paternalName, setPaternalName] = useState("");
  const [maternalName, setMaternalName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState("");
  const [company, setCompany] = useState(0);
  const [loading, setLoading] = useState(false);

  let handleFormSubmit = async () => {
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/user`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_email: email,
        user_password: password,
        user_name: userName,
        user_paternal_surname: paternalName,
        user_maternal_surname: maternalName,
        user_avatar: avatar,
        company_id: company 
      })
    });
    const res = await req.json();
    if (res.success) {
      setUserName("");
      setPaternalName("");
      setMaternalName("");
      setEmail("");
      setPassword("");
      setAvatar("");
      setCompany(0);
      location.assign("/usuarios");
    }
  };

  return (
    <div className="page-content-wrapper ">
      {/* START PAGE CONTENT */}
      <div
        className="content "
        style={{
          paddingTop: "60px",
        }}
      >
        <PageContainer>
          <div className="row">
            <div className="col-8">
              <h3>Alta Nuevo Usuario</h3>
            </div>
            <div className="col-2"></div>
            <div className="col-2">
            </div>
          </div>
          <div
            className="row"
            // style={{ overflowY: "scroll"}}
          >
            <ValidatorForm instantValidate={true} onSubmit={handleFormSubmit} className="p-t-15 col-12">
              <div className="row">
                <div className="col-md-12">
                  <WithoutMsgValidation
                    onChange={(e) => setUserName(e.target.value)} name="userName" id="userName" value={userName}
                    validators={["required"]} errorMessages={["Este campo es obligatorio"]} className={"form-control"} label={"Nombre(s)"} require="true"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <WithoutMsgValidation
                    onChange={(e) => setPaternalName(e.target.value)} name="paternalName" id="paternalName" value={paternalName}
                    validators={["required"]} errorMessages={["Este campo es obligatorio"]} className={"form-control"} label={"Primer apellido"} require="true"
                  />
                </div>
                <div className="col-md-6">
                  <WithoutMsgValidation
                    onChange={(e) => setMaternalName(e.target.value)} name="maternalName" id="maternalName" value={maternalName}
                    validators={[]} errorMessages={[]} className={"form-control"} label={"Segundo apellido"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <WithoutMsgValidation
                    onChange={(e) => setEmail(e.target.value)} name="email" id="email" value={email}
                    validators={["required", "isEmail"]} errorMessages={["Este campo es obligatorio", "El correo electrónico no tiene un formato valido"]} className={"form-control"} label={"Email"} require="true"
                  />
                </div>
                <div className="col-md-6">
                  <WithoutMsgValidation
                    onChange={(e) => setPassword(e.target.value)}  name="password" id="password" value={password} type="password"
                    validators={["required"]} errorMessages={["Este campo es obligatorio"]} className={"form-control"} label={"Contraseña"} require="true"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <CompaniesList setCompany={setCompany} required={true} />
                </div>
              </div>
              <button aria-label="" className="btn btn-primary btn-cons m-t-10" type="submit" disabled={loading}>{!loading ? 'Crear usuario' : 'Creando usuario...'}</button>
            </ValidatorForm>
          </div>
        </PageContainer>
      </div>
      {/* END PAGE CONTENT */}
    </div>
  );
};

export default content;
