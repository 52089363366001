const persons = [
    "Jim",
    "John",
    "Lucy",
    "Mike",
    "Sara",
    "Daniel",
    "Silva",
    "Kate",
    "Joan"
]

export default persons;