import React, { useEffect, useState, useRef } from "react";
import PageBreadcrumb from "../UIElements/Breadcrumb";
import PageContainer from "../UIElements/Containers";
import PageScroll from "../UIElements/PageScroll";
import "../Dashboard/styles.css";
import { CONSTANTS } from "../../helpers/constants";
import PolarChart from "./charts/PolarChart";
import RadarChart from "./charts/RadarChart";
import SingleReportButton from "./reports/SingleReportButton";

const Content = ({ path }) => {
  const targetRef = useRef();
  const urlParams = new URLSearchParams(window.location.search);
  const [answers, setAnswers] = useState(null);
  const [groups, setQuestionGroups] = useState([]);
  const [participant, setParticipant] = useState(null);

  useEffect(() => {
    (async () => {
      const getAnswers = await fetch(
        `${
          CONSTANTS.base_url
        }api/v1/data/question/form_participant/2/${urlParams.get(
          "participante_id"
        )}/${urlParams.get("date")}`,
        {
          headers: {
            "x-access-token": CONSTANTS.TOKEN,
          },
        }
      );
      const answers = await getAnswers.json();
      const grouped = answers.data.reduce((acc, currentValue) => {
        let groupKey = currentValue.question_group;
        if (!acc[groupKey]) {
          acc[groupKey] = [];
        }
        acc[groupKey].push(currentValue);
        return acc;
      }, {});
      if (
        grouped["APOYO SOCIAL (REDES NATURALES) - AUTOVALORACIÓN"] &&
        grouped["APOYO SOCIAL (REDES NATURALES) - AUTOVALORACIÓN"].length >= 1
      ) {
        const filtered = grouped[
          "APOYO SOCIAL (REDES NATURALES) - AUTOVALORACIÓN"
        ].filter((question) => question.question_type != "varias");
        grouped["APOYO SOCIAL (REDES NATURALES) - AUTOVALORACIÓN"] = filtered;
      }
      setQuestionGroups(Object.keys(grouped));
      setAnswers(grouped);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const getParticipant = await fetch(
        `${CONSTANTS.base_url}api/v1/data/participants/${urlParams.get(
          "participante_id"
        )}`,
        {
          headers: {
            "x-access-token": CONSTANTS.TOKEN,
          },
        }
      );
      const participant = await getParticipant.json();
      setParticipant(participant.data);
    })();
  }, []);

  function random_rgba() {
    const hue = Math.random() * 360;
    const lightness = Math.random() * 75;
    const randomColor = `hsl(${hue} 100% ${15 + lightness}% / .4)`;

    return randomColor;
  }

  const generateDoughnut = () => {
    const data = [];
    const colors = [];
    groups.forEach((group) => {
      const arrAnswers = [];
      answers[group].forEach((element) => {
        arrAnswers.push(element.Answers[0].answer_value);
      });
      data.push(
        arrAnswers.reduce((a, b) => Number(a) + Number(b)) / arrAnswers.length
      );
      colors.push(random_rgba());
    });
    const labels = [...groups];
    const datasets = [];

    datasets.push({
      label: "Promedio de la sección",
      data: [...data],
      backgroundColor: colors,
      borderColor: colors,
    });

    return {
      labels,
      datasets,
    };
  };

  // const generateDashboard = (answers, group) => {
  //   const arrAnswers = [];
  //   answers[group].forEach((element) => {
  //     arrAnswers.push(element.Answers[0].answer_value);
  //   });

  //   const result =
  //     arrAnswers.reduce((a, b) => Number(a) + Number(b)) / arrAnswers.length;
  //   const labels = [urlParams.get("date")];

  //   const color = random_rgba();
  //   return {
  //     labels: labels,
  //     datasets: [
  //       {
  //         label: urlParams.get("date"),
  //         data: [result],
  //         borderColor: color,
  //         backgroundColor: color,
  //         tension: 0.1,
  //       },
  //     ],
  //   };
  // };

  const getSum = () => {
    const data = [];
    groups.forEach((group) => {
      const arrAnswers = [];
      answers[group].forEach((element, idx) => {
        arrAnswers.push(Number(element.Answers[idx]?.answer_value) || 0);
      });
      data.push(arrAnswers.reduce((a, b) => Number(a) + Number(b)));
    });

    return (
      (data.reduce((a, b) => Number(a) + Number(b)) / (data.length * 3)) * 100
    );
  };

  const setResultText = () => {
    const result = getSum();
    if (result <= 1) {
      return `${Number(result).toFixed(2)} Grado alto de institucionalización`;
    } else if (result > 1 && result <= 34) {
      return `${Number(result).toFixed(
        2
      )} Grado moderado de institucionalización`;
    } else if (result > 34 && result <= 67) {
      return `${Number(result).toFixed(2)} moderado de emancipación`;
    } else {
      return `${Number(result).toFixed(2)} alto de vida en comunidad`;
    }
  };

  const setCuidadoAutocuidado = () => {
    if (groups && answers) {
      const data = [];
      groups.forEach((group, gIdx) => {
        const acceptedIdx = [1,2,3,4,5,6,7,8,10,11,12,13,14,17,18,19,26,27,28,30,31,32,33,34];
        if (acceptedIdx.includes(gIdx)) {
          const arrAnswers = [];
          answers[group].forEach((element, idx) => {
            arrAnswers.push(Number(element.Answers[idx]?.answer_value) || 0);
          });
          data.push(arrAnswers.reduce((a, b) => Number(a) + Number(b)));
        }
      });
      
      return (
        (data.reduce((a, b) => Number(a) + Number(b)) / (data.length * 3)) * 100
      );
    }
  };

  const setTrabajoPersonal = () => {
    if (groups) {
      const data = [];
      groups.forEach((group, idx) => {
        const acceptedIdx = [22,23,25,26,27,28,33,34,31,32,15,1,2,3,4,5,6,7,8,9,10,13,14];
        if (acceptedIdx.includes(idx)) {
          const arrAnswers = [];
          answers[group].forEach((element, idx) => {
            arrAnswers.push(Number(element.Answers[idx]?.answer_value) || 0);
          });
          data.push(arrAnswers.reduce((a, b) => Number(a) + Number(b)));
        }
      });

      return (
        (data.reduce((a, b) => Number(a) + Number(b)) / (data.length * 3)) * 100
      );
    }
  };

  const setParticipacion = () => {
    if (groups) {
      const data = [];
      groups.forEach((group, idx) => {
        const arrAnswers = [];
        const acceptedIdx = [
          1, 10, 11, 12, 14, 16, 18, 19, 21, 22, 23, 27, 28, 29, 30, 31, 32, 34,
        ];
        if (acceptedIdx.includes(idx)) {
          answers[group].forEach((element, idx) => {
            arrAnswers.push(Number(element.Answers[idx]?.answer_value) || 0);
          });
          data.push(arrAnswers.reduce((a, b) => Number(a) + Number(b)));
        }
      });

      return (
        (data.reduce((a, b) => Number(a) + Number(b)) / (data.length * 3)) * 100
      );
    }
  };

  const setEmancipacion = () => {
    if (groups) {
      const data = [];
      groups.forEach((group, idx) => {
        const arrAnswers = [];
        const acceptedIdx = [
          13, 2, 5, 7, 9, 11, 15, 16, 17, 18, 19, 20, 21, 22, 23, 25, 26, 27,
          28, 29, 30, 31, 32, 33, 34, 35,
        ];
        if (acceptedIdx.includes(idx)) {
          answers[group].forEach((element, idx) => {
            arrAnswers.push(Number(element.Answers[idx]?.answer_value) || 0);
          });
          data.push(arrAnswers.reduce((a, b) => Number(a) + Number(b)));
        }
      });

      return (
        (data.reduce((a, b) => Number(a) + Number(b)) / (data.length * 3)) * 100
      );
    }
  };

  return (
    <div className="page-content-wrapper">
      <div className="content" style={{ paddingTop: "60px" }}>
        {answers && groups && (
          <PageContainer>
            {participant && (
              <PageScroll>
                <PageBreadcrumb>
                  <li className="breadcrumb-item">
                    <a
                      href={`/participants_form?participant_id=${participant.participante_id}`}
                    >
                      {participant.participante_name}
                    </a>
                  </li>
                  <li className="breadcrumb-item active">Dashboards</li>
                  <li style={{ display: "flex", justifyContent: "flex-end" }}>
                    <SingleReportButton
                      body={targetRef}
                      participant_name={participant.participante_name}
                    />
                  </li>
                </PageBreadcrumb>
              </PageScroll>
            )}
            <div ref={targetRef}>
              {participant && (
                <h2>
                  Reporte de resultados del participante{" "}
                  {participant.participante_name} -{" "}
                  {participant.participante_code} del día:{" "}
                  {urlParams.get("date")}
                </h2>
              )}
              <h4>Índice general de emancipación: {setResultText()}</h4>
              <PolarChart data={generateDoughnut()} sum={getSum()}></PolarChart>
              <div className="d-flex justify-content-between align-items-center m-t-5">
                <div className="row alert-info border border-info p-3">
                  Cuidado y autocuidado: {setCuidadoAutocuidado().toFixed(2)}
                </div>
                <div className="row alert-info border border-info p-3">
                  Trabajo personal y activaciónd de competencias{" "}
                  {setTrabajoPersonal().toFixed(2)}
                </div>
                <div className="row alert-info border border-info p-3">
                  Participación y vida en la comunidad{" "}
                  {setParticipacion().toFixed(2)}
                </div>
                <div className="row alert-info border border-info p-3">
                  Emancipación {setEmancipacion().toFixed(2)}
                </div>
              </div>
              <div
                style={{
                  padding: "0",
                  marginTop: "-200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "95%",
                }}
              >
                <RadarChart
                  data={generateDoughnut()}
                  sum={getSum()}
                ></RadarChart>
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                columnGap: "1fr",
              }}
            ></div>
          </PageContainer>
        )}
      </div>
    </div>
  );
};

export default Content;
