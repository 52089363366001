import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { dateFormat } from "../../../../../helpers/utils";
import ConfirmDeleteQuestion from "./ConfirmDeleteQuestion";
import FlipBarNotifyModule from "../../../../UIElements/Notification/FlipBarNotification/FlipBarNotifyModule";
import EditQuestion from "./EditFormQuestion";

const ViewFormModal = ({ visible, currentForm, closeHandler, handleUpdater }) => {
  const [notification, setNotification] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [parentForm, setParentForm] = useState({});
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showEditQuestionModal, setShowEditQuestionModal] = useState(false);


  useEffect(() => {
    if (notification && notification.timeout) {
      setTimeout(() => {
        setNotification(null);
      }, notification.timeout);
    }
  }, [notification])

  useEffect(() => {
    if (currentForm && Object.keys(currentForm).length) setParentForm(currentForm);
  }, [currentForm])

  const handleOpenConfirmDelete = (data) => {
    setCurrentQuestion(data);
    setShowConfirmDeleteModal(true);
    closeHandler();
  }
  const handleCloseConfirmDelete = () => {
    setCurrentQuestion({});
    setShowConfirmDeleteModal(false);
  }
  const handleSuccessConfirmDelete = (data) => {
    setShowConfirmDeleteModal(false);
    setCurrentQuestion({});
    setNotification({ message: 'Pregunta eliminada con exito', timeout: 5000 });
    handleUpdater();
  }
  const handleFailConfirmDelete = (_data) => {
    setShowConfirmDeleteModal(false);
    setNotification({ message: 'Error al eliminar la pregunta', type: 'error', timeout: 5000 });
  }

  const handleOpenEditQuestion = (data) => {
    setCurrentQuestion(data);
    setShowEditQuestionModal(true);
    closeHandler();
  }
  const handleCloseEditQuestion = () => {
    setCurrentQuestion({});
    setShowEditQuestionModal(false);
  }
  const handleSuccessEditQuestion = (data) => {
    setShowEditQuestionModal(false);
    setCurrentQuestion({});
    setNotification({ message: 'Pregunta actualizada con exito', timeout: 5000 });
    handleUpdater();
  }
  const handleFailEditQuestion = (_data) => {
    setShowEditQuestionModal(false);
    setNotification({ message: 'Error al editar la pregunta', type: 'error', timeout: 5000 });
  }

  return (
    <>
      {notification && <FlipBarNotifyModule notifications={[{ type: notification.type || 'success', desc: notification.message }]} position={notification.position || 'top-right'} />}
      <ConfirmDeleteQuestion visible={showConfirmDeleteModal} currentQuestion={currentQuestion} closeHandler={handleCloseConfirmDelete} successCallback={handleSuccessConfirmDelete} failCallback={handleFailConfirmDelete} />
      <EditQuestion visible={showEditQuestionModal} currentForm={parentForm} currentQuestion={currentQuestion} closeHandler={handleCloseEditQuestion} successCallback={handleSuccessEditQuestion} failCallback={handleFailEditQuestion} />

      <Modal show={visible} onHide={closeHandler} onEscapeKeyDown={closeHandler} onExit={closeHandler} size="lg">
        <Modal.Header closeButton>
          <h4>Formulario: <b>{currentForm.form_name}</b></h4>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <p>Creado el: <b>{dateFormat(currentForm.form_createdAt, {includeHours: true})}</b></p>
            </div>
            <div className="col-md-6">
              <p>Ultima actualización: <b>{dateFormat(currentForm.form_updatedAt, {includeHours: true})}</b></p>
            </div>
            <div className="col-md-12">
              <p>Tipo: {currentForm.form_type}</p>
            </div>
          </div>
          <div className="row m-t-10 m-b-10">
            <div className="col-md-12">
              <p>{currentForm.form_description}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <h5>Preguntas:</h5>
            </div>
            {(currentForm && currentForm?.Questions?.length) ? currentForm.Questions.map((question, index) => (
              <div key={index} className={`card view-form-card card-default`}>
                <div className={`card-header`}>
                  <div className="flex justify-between">
                    <div className="card-title">{question.question_group}</div>
                    <div className="card-title">Pregunta {question.question_type === 'multiple' ? `de opción ` : ''} {question.question_type}</div>
                  </div>
                  {question && question.question_allowed_entities && question.question_allowed_entities.length &&
                    <div className="flex justify-end">
                      <div className="card-title">Condicionada a entidades</div>
                    </div>
                  }
                  <div className="card-body">
                    <h5>{question.question_text}</h5>
                    {question.question_options &&
                      <ol style={{ listStyleType: 'upper-alpha' }}>
                        {JSON.parse(question.question_options).map((option, indexj) => (
                          <li key={indexj}>{option}</li>
                        ))}
                      </ol>
                    }
                  </div>
                </div>
                <div className="card-header flex justify-end">
                  <button className="btn btn-primary btn-cons" onClick={() => handleOpenEditQuestion(question)}>Editar</button>
                  <div className="btn btn-danger btn-cons mr-0" onClick={() => handleOpenConfirmDelete(question)}>Eliminar</div>
                </div>
              </div>
            )) : <div className="col-md-12"><p>Este formulario aún no cuenta con preguntas.</p></div>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn btn-cons" onClick={closeHandler}>Cerrar</div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewFormModal;
