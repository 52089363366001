import React, { useState, useEffect } from "react";
import FlipBarNotifyModule from "../../components/UIElements/Notification/FlipBarNotification/FlipBarNotifyModule";
import PageScroll from "../../components/UIElements/PageScroll";
import Breadcrumb from "../../components/UIElements/Breadcrumb";
import PageContainer from "../../components/UIElements/Containers";
import Label from "../../components/Forms/Elements/Label";
import Input from "../../components/Forms/Elements/Input";
import { CONSTANTS } from "../../helpers/constants";
import { dateFormat, loadImage } from "../../helpers/utils";
import "./style.scss";

const UserProfile = ({ location }) => {
  let path = location.pathname;
  const [notification, setNotification] = useState(null);
  const [defaultTextFields, setDefaultTextFields] = useState([ false, false, false, false ]);
  const [user, setUser] = useState(null);
  const [editingData, setEditingData] = useState(false);
  const [userAvatar, setUserAvatar] = useState(null);
  const [previewUserAvatar, setPreviewUserAvatar] = useState(null);
  const [updatedFormData, setUpdatedFormData] = useState({});

  useEffect(() => {
    (async () => {
      const req = await fetch(`${CONSTANTS.base_url}api/v1/data/user/${JSON.parse(sessionStorage.getItem('user')).id}?include=Company`, {
        headers: {
          'x-access-token': CONSTANTS.TOKEN
        }
      });
      const res = await req.json();
      if (res.data) {
        setUser(res.data);
        setUserAvatar(res.data.user_avatar || `${CONSTANTS.DEFAULT_USER_AVATAR}${res.data.user_name} ${res.data.user_lastName} ${res.data.user_maternalLastName}`);
      }
    })()
  }, [])

  useEffect(() => {
    if (notification && notification.timeout) {
      setTimeout(() => {
        setNotification(null);
      }, notification.timeout);
    }
  }, [notification])

  const handleAvatarChange = (e) => {
    loadImage(e, (data, error) => {
      if (error) {
        setNotification({ type: 'error', message: `Ocurrio un error al cargar la imagen: ${error}`, timeout: 5000 });
      } else {
        setPreviewUserAvatar(data);
      }
    });
  }
  const handleEditing = () => {
    setUpdatedFormData({
      user_name: user.user_name,
      user_lastName: user.user_lastName,
      user_maternalLastName: user.user_maternalLastName,
      user_birthday: user.user_birthday ? new Date(user.user_birthday).toJSON().slice(0, 10) : null
    });
    setPreviewUserAvatar(userAvatar);
    setEditingData(true);
  }
  const handleCancelEditing = () => {
    setEditingData(false);
    setPreviewUserAvatar(userAvatar);
    setUpdatedFormData({});
  }
  const handleUpdateUser = async () => {
    if (!updatedFormData.user_name) {
      setNotification({ type: 'error', message: 'El nombre es obligatorio', timeout: 5000 });
      return;
    }
    if (!updatedFormData.user_lastName) {
      setNotification({ type: 'error', message: 'El apellido paterno es obligatorio', timeout: 5000 });
      return;
    }
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/user/${JSON.parse(sessionStorage.getItem('user')).id}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': CONSTANTS.TOKEN
      },
      body: JSON.stringify({
        user_avatar: previewUserAvatar,
        ...updatedFormData
      })
    });
    const res = await req.json();
    if (res.success) {
      setUser({ ...user, ...updatedFormData, user_avatar: previewUserAvatar });
      setUserAvatar(previewUserAvatar);
      sessionStorage.setItem('user', JSON.stringify({
        ...JSON.parse(sessionStorage.getItem('user')),
        nombre: `${updatedFormData.user_name || ''} ${updatedFormData.user_lastName || ''} ${updatedFormData.user_maternalLastName || ''}`,
        avatar: previewUserAvatar
      }));
    }
    setNotification({
      type: res.success ? 'success' : 'error',
      message: res.success ? 'Usuario actualizado con exito' : 'Ocurrio un error al actualizar los datos',
      timeout: 5000
    });
    handleCancelEditing();
  }

  return user ? (
    <div className={"no-padding page-container"}>
      <div className="page-content-wrapper">
        {notification && <FlipBarNotifyModule notifications={[{ type: notification.type || 'success', desc: notification.message }]} position={notification.position || 'top-right'} />}
        <div className="content" style={{ paddingTop: path && path.includes("/executive") ? "170px" : path.includes("/casual") ? "100px" : "60px" }}>
          <div className="jumbotron" data-pages="parallax">
            <PageContainer className={ path.includes("/executive") || path.includes("/casual") ? "container sm-p-l-0 sm-p-r-0" : "sm-p-l-0 sm-p-r-0" }>
              <PageScroll>
                <Breadcrumb>
                  <li className="breadcrumb-item"><a href="/home">Home</a></li>
                  <li className="breadcrumb-item active">Perfil de usuario</li>
                </Breadcrumb>
              </PageScroll>
            </PageContainer>
          </div>

          <PageContainer className={ path.includes("/executive") || path.includes("/casual") ? "container" : ""}>
            <div className="row">
              <div className="col-8 center-margin">
                <h3>Mi perfil</h3>
                <div className="card card-default">
                  <div className="card-header">
                    <div className="flex items-center justify-between">
                      {/* {(user.Role && user.Role.role_name) && <div className="card-title">{user.Role.role_name}</div>} */}
                      {(user.Role && user.Role.role_name) && <div className="card-title">Miembro desde: {dateFormat(user.user_createdAt, { fullMonth: true })}</div>}
                    </div>
                    <div className={`center-margin m-t-10 relative overflow-hidden br-100 avatarWrapper ${editingData ? 'avatarWrapperEdit' : ''}`}>
                      <img className="block avatar" src={!editingData ? userAvatar : previewUserAvatar} alt="profile avatar" width={150} height={150} />
                      {editingData && <input type="file" className="top-left full-width full-height avatarWrapperChanger" onChange={handleAvatarChange} />}
                    </div>
                  </div>
                  <div className="card-body">
                    {!editingData ?
                      <>
                        <h4 className="uppercase tc m-b-0">{`${user.user_name || ''} ${user.user_lastName || ''} ${user.user_maternalLastName || ''}`}</h4>
                        <p className="m-b-10 tc">{user.user_mail}</p>
                        
                        <div className="tc">
                          <button type="button" className="btn btn-info btn-icon-left btn-lg" iconposition="left" onClick={handleEditing}>
                            <i className="pg-icon">edit</i><span className="">Actualizar datos</span>
                          </button>
                        </div>
                      </> :
                      <>
                        <form className="m-t-20" role="form">
                          <div className="row">
                            <div className="col-md-6">
                              <div className={`form-group form-group-default ${defaultTextFields[0] ? "focused" : ""} required`}
                                onClick={() => setDefaultTextFields([true, false, false, false])}>
                                <Label>Nombre</Label>
                                <Input type="text" className={`form-control ${defaultTextFields[0] ? "focus-visible" : ""}`}
                                  value={updatedFormData.user_name}
                                  onChange={(e) => setUpdatedFormData({ ...updatedFormData, user_name: e.currentTarget.value })} />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className={`form-group form-group-default ${defaultTextFields[1] ? "focused" : ""} required`}
                                onClick={() => setDefaultTextFields([ false, true, false, false ])}>
                                <Label>Primer Apellido</Label>
                                <Input type="text" className={`form-control ${defaultTextFields[1] ? "focus-visible" : ""}`}
                                  value={updatedFormData.user_lastName}
                                  onChange={(e) => setUpdatedFormData({ ...updatedFormData, user_lastName: e.currentTarget.value })} />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className={`form-group form-group-default ${defaultTextFields[2] ? "focused" : ""}`}
                                onClick={() => setDefaultTextFields([false, false, true, false])}>
                                <Label>Segundo Apellido</Label>
                                <Input type="text" className={`form-control ${defaultTextFields[2] ? "focus-visible" : ""}`}
                                  value={updatedFormData.user_maternalLastName}
                                  onChange={(e) => setUpdatedFormData({ ...updatedFormData, user_maternalLastName: e.currentTarget.value })} />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className={`form-group form-group-default ${defaultTextFields[3] ? "focused" : ""}`}
                                onClick={() => setDefaultTextFields([false, false, false, true])}>
                                <Label>Fecha de nacimiento</Label>
                                <Input type="date" className={`form-control ${defaultTextFields[3] ? "focus-visible" : ""}`}
                                  value={updatedFormData.user_birthday || ''} max={new Date().toJSON().slice(0, 10)}
                                  onChange={(e) => setUpdatedFormData({ ...updatedFormData, user_birthday: e.currentTarget.value })} />
                              </div>
                            </div>
                          </div>
                        </form>
                        <div className="tr">
                          <button type="button" className="btn btn-info btn-lg" onClick={handleCancelEditing}>
                            <span className="">Cancelar</span>
                          </button>
                          <button type="button" className="btn btn-primary btn-icon-left btn-lg m-l-10" iconposition="left" onClick={handleUpdateUser}>
                            <i className="pg-icon">tick</i><span className="">Guardar</span>
                          </button>
                        </div>
                      </>
                    }
                        
                  </div>
                </div>
              </div>
            </div>
          </PageContainer>
        </div>
      </div>
    </div>
  ) : null;
};

export default UserProfile;