import React, { useEffect, useState } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport, Search } from "react-bootstrap-table2-toolkit";
import DataTable from "../../DataTable";
import PageBreadcrumb from "../../../UIElements/Breadcrumb";
import PageContainer from "../../../UIElements/Containers";
import PageScroll from "../../../UIElements/PageScroll";
import FlipBarNotifyModule from '../../../UIElements/Notification/FlipBarNotification/FlipBarNotifyModule';
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import "../../style.css";
import { CONSTANTS } from "../../../../helpers/constants";
import NewQuestionModal from "./Modals/NewQuestion";
import EditQuestion from "./Modals/EditFormQuestion";
import ConfirmDelete from "./Modals/ConfirmDelete";
import ViewFormModal from "./Modals/ViewForm";
import ConfirmActiveStatus from "./Modals/ConfirmActiveStatus";

const content = ({ path }) => {
  const TABLE_COLUMNS = [
    {
      dataField: 'id',
      text: 'ID',
      headerAlign: 'center',
      align: 'center',
      headerStyle: { backgroundColor: '#f0f0f073' },
      sort: true,
      editable: false
    }, {
      dataField: 'question_key',
      text: 'KEY',
      headerAlign: 'center',
      align: 'center',
      headerStyle: { backgroundColor: '#f0f0f073' },
      headerTitle: true,
      sort: true,
      editable: false
    }, {
      dataField: 'question_text',
      text: 'PREGUNTA',
      headerAlign: 'center',
      align: 'center',
      headerStyle: { backgroundColor: '#f0f0f073' },
      headerTitle: true,
      editable: false
    }, {
      dataField: 'question_type',
      text: 'TIPO',
      headerAlign: 'center',
      align: 'center',
      headerStyle: { backgroundColor: '#f0f0f073' },
      headerTitle: true,
      sort: true,
      editable: false,
      formatter: (cell, row) => (<span key={row.id} className={`label label-${row.question_type === 'base' ? 'info' : 'warning'}`}>{row.question_type}</span>)
    }, {
      dataField: 'question_active',
      text: 'STATUS',
      headerAlign: 'center',
      align: 'center',
      headerStyle: { backgroundColor: '#f0f0f073' },
      headerTitle: true,
      sort: true,
      editable: false,
      formatter: (cell, row) => (<span key={row.id} className={`label label-${row.question_active ? 'success' : 'danger'}`}>{row.question_active ? 'Activa' : 'Inactiva'}</span>)
    }, {
      dataField: 'question_allowed_entities',
      text: 'CONDICIONADA',
      headerAlign: 'center',
      align: 'center',
      headerStyle: { backgroundColor: '#f0f0f073' },
      headerTitle: true,
      sort: true,
      editable: false,
      formatter: (cell, row) => row.question_allowed_entities ? JSON.parse(row.question_allowed_entities).length ? 'Si' : 'No' : 'No'
    }, {
      dataField: 'updatedAt',
      hidden: true
    }, {
      dataField: '',
      text: 'ACTIONS',
      headerAlign: 'center',
      align: 'center',
      headerStyle: { backgroundColor: '#f0f0f073' },
      headerTitle: true,
      editable: false,
      formatter: (cell, row) => (
        <div key={row.id} className="dropdown d-lg-block d-none">
          <button className="btn btn-default" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="actions dropdown">
            <i className="pg-icon">more_vertical</i>
          </button>
          <div className="dropdown-menu" role="menu">
            <a href="#" onClick={() => handleOpenViewForm(row)} className="dropdown-item"><i className="pg-icon">eye</i> Ver detalles</a>
            <a href="#" onClick={() => handleOpenConfirmActiveStatus(row)} className="dropdown-item"><i className="pg-icon">{row.question_active ? 'disable' : 'menu_add'}</i> {row.question_active ? 'Desactivar' : 'Activar'}</a>
            <a href="#" onClick={() => handleOpenEditForm(row)} className="dropdown-item"><i className="pg-icon">edit</i> Editar</a>
            {row.question_type !== 'base' && <a href="#" onClick={() => handleOpenConfirmDelete(row)} className="dropdown-item"><i className="pg-icon">trash_alt</i> Eliminar</a>}
          </div>
        </div>
      )
    }
  ];
  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;

  const [forbidden, setForbidden] = useState(false);
  const [showNewQuestionModal, setShowNewQuestionModal] = useState(false);
  const [showEditFormModal, setShowEditFormModal] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showViewFormModal, setShowViewFormModal] = useState(false);
  const [showConfirmActiveModal, setShowConfirmActiveModal] = useState(false);
  const [currentQuestionData, setCurrentQuestionData] = useState({});
  const [questions, setQuestions] = useState([]);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    getQuestions();
  }, [])

  useEffect(() => {
    if (notification && notification.timeout) {
      setTimeout(() => {
        setNotification(null);
      }, notification.timeout);
    }
  }, [notification])

  const getQuestions = async () => {
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/participante_questions`, {
      headers: {
        'x-access-token': CONSTANTS.TOKEN
      }
    });
    const res = await req.json();
    if (req.status === 403) setForbidden(true);
    if (res.success) setQuestions(res.data);
  }

  const handleCloseCreateQuestion = () => {
    setShowNewQuestionModal(false);
  }
  const handleSuccessCreateQuestion = (data) => {
    setShowNewQuestionModal(false);
    setQuestions([...questions, data]);
    setNotification({ message: 'Pregunta agregada con exito', timeout: 5000 });
  }
  const handleFailCreateQuestion = (_data) => {
    setShowNewQuestionModal(false);
    setNotification({ message: 'Error al crear la pregunta', type: 'error', timeout: 5000 });
  }

  const handleOpenEditForm = (data) => {
    setCurrentQuestionData(data);
    setShowEditFormModal(true);
  }
  const handleCloseEditForm = () => {
    setCurrentQuestionData({});
    setShowEditFormModal(false);
  }
  const handleSuccessEditForm = (_data) => {
    setShowEditFormModal(false);
    setCurrentQuestionData({});
    setNotification({ message: 'Pregunta actualizada con exito', timeout: 5000 });
    getQuestions();
  }
  const handleFailEditForm = (_data) => {
    setShowEditFormModal(false);
    setNotification({ message: 'Error al editar la pregunta', type: 'error', timeout: 5000 });
  }

  const handleOpenConfirmDelete = (data) => {
    setCurrentQuestionData(data);
    setShowConfirmDeleteModal(true);
  }
  const handleCloseConfirmDelete = () => {
    setCurrentQuestionData({});
    setShowConfirmDeleteModal(false);
  }
  const handleSuccessConfirmDelete = (data) => {
    setShowConfirmDeleteModal(false);
    setQuestions(questions.filter(({ id }) => id !== data.id));
    setCurrentQuestionData({});
    setNotification({ message: 'Pregunta eliminada con exito', timeout: 5000 });
  }
  const handleFailConfirmDelete = (_data) => {
    setShowConfirmDeleteModal(false);
    setNotification({ message: 'Error al eliminar la pregunta', type: 'error', timeout: 5000 });
  }

  const handleOpenViewForm = (data) => {
    setCurrentQuestionData(data);
    setShowViewFormModal(true);
  }
  const handleCloseViewForm = () => {
    setCurrentQuestionData({});
    setShowViewFormModal(false);
  }

  const handleOpenConfirmActiveStatus = (data) => {
    setCurrentQuestionData(data);
    setShowConfirmActiveModal(true);
  }
  const handleCloseConfirmActiveStatus = () => {
    setCurrentQuestionData({});
    setShowConfirmActiveModal(false);
  }
  const handleSuccessConfirmActiveStatus = (data) => {
    setShowConfirmActiveModal(false);
    setCurrentQuestionData({});
    setNotification({ message: 'Pregunta actualizada con exito', timeout: 5000 });
    getQuestions();
  }
  const handleFailConfirmActiveStatus = (_data) => {
    setShowConfirmActiveModal(false);
    setNotification({ message: 'Error al actualizar la pregunta', type: 'error', timeout: 5000 });
  }

  return (
    <div className="page-content-wrapper">
      <div className="content" style={{ paddingTop: path && path.includes("/executive") ? "170px" : path.includes("/casual") ? "100px" : "60px" }}>
        {notification && <FlipBarNotifyModule notifications={[{ type: notification.type || 'success', desc: notification.message }]} position={notification.position || 'top-right'} />}
        <NewQuestionModal visible={showNewQuestionModal} closeHandler={handleCloseCreateQuestion} successCallback={handleSuccessCreateQuestion} failCallback={handleFailCreateQuestion} />
        <EditQuestion visible={showEditFormModal} currentData={currentQuestionData} closeHandler={handleCloseEditForm} successCallback={handleSuccessEditForm} failCallback={handleFailEditForm} />
        <ConfirmDelete visible={showConfirmDeleteModal} currentData={currentQuestionData} closeHandler={handleCloseConfirmDelete} successCallback={handleSuccessConfirmDelete} failCallback={handleFailConfirmDelete} />
        <ViewFormModal visible={showViewFormModal} currentData={currentQuestionData} closeHandler={handleCloseViewForm} />
        <ConfirmActiveStatus visible={showConfirmActiveModal} currentData={currentQuestionData} closeHandler={handleCloseConfirmActiveStatus} successCallback={handleSuccessConfirmActiveStatus} failCallback={handleFailConfirmActiveStatus} />

        <div className="jumbotron" data-pages="parallax">
          <PageContainer className={path.includes("/executive") || path.includes("/casual") ? "container sm-p-l-0 sm-p-r-0" : "sm-p-l-0 sm-p-r-0 "}>
            <PageScroll>
              <PageBreadcrumb>
                <li className="breadcrumb-item active">Editor de formulario de participantes</li>
              </PageBreadcrumb>
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="card card-transparent">
                    <div className="card-header">
                      <div className="card-title">Participantes</div>
                    </div>
                    <div className="card-body">
                      <h3>Formulario de Participantes.</h3>
                      <p>Administrar tabla de formulario de participantes</p>
                    </div>
                  </div>
                </div>
              </div>
            </PageScroll>
          </PageContainer>
        </div>

        <PageContainer className={ path.includes("/executive") || path.includes("/casual") ? "container" : ""}>
          <div className="card card-transparent">
            <div className="card-header ">
              {!forbidden &&
                <div className="pull-right">
                  <div className="col-xs-12">
                    <button aria-label="" id="show-modal" className="btn btn-primary btn-cons" onClick={() => setShowNewQuestionModal(true)}>
                      <i className="pg-icon">add</i> Agregar pregunta
                    </button>
                  </div>
                </div>
              }
              <div className="clearfix"></div>
            </div>
            <div className="card-body">
              <ToolkitProvider keyField="id" bootstrap4 data={questions} columns={TABLE_COLUMNS} search exportCSV={{ fileName: `form_participante_${new Date().getTime()}.csv` }}>
                {(props) => {
                  return (
                    <React.Fragment>
                      {!forbidden &&
                        <>
                          <ExportCSVButton {...props.csvProps}>Export CSV</ExportCSVButton>
                          <SearchBar {...props.searchProps} placeholder="Buscar" style={{width: "20%", float: "right", marginBottom: "5px"}} />
                        </>
                      }
                      <DataTable
                        {...props.baseProps}
                        hover={true}
                        bordered={false}
                        condensed={true}
                        key={"formularioParticipantes"}
                        noDataIndication={forbidden ? 'No tienes permisos suficientes, contacta con tu administrador.' : 'No se encontraron datos.'}
                        defaultSorted={[{
                          dataField: 'updatedAt',
                          order: 'desc'
                        }]}
                        pagination={paginationFactory({
                          hideSizePerPage: true,
                          hidePageListOnlyOnePage: false,
                          showTotal: true,
                          paginationTotalRenderer: (from, to, size) => (<span className="react-bootstrap-table-pagination-total">Mostrando {from} - {to} de {size} registros</span>),
                        })}
                      />
                    </React.Fragment>
                  )
                }}
              </ToolkitProvider>
            </div>
          </div>
        </PageContainer>
      </div>
    </div>
  );
};

export default content;
