import ComponentAdmin from "./ComponentAdmin";
import LandingSidebar from "./LandingSidebar";

const Sidebar = ({ location, user }) => {
  // console.log(user)
  if (location.pathname === '/landing') {
    return <LandingSidebar />
  }
  if(user && user.rol === 'admin'){
    return <ComponentAdmin />
  }
  return null
}

export default Sidebar