import React, { useEffect, useState } from "react";
import { Highlighter } from "react-bootstrap-typeahead";
import { ValidatorForm } from "react-form-validator-core";

import WithoutMsgValidation from "../FormLayouts/WithoutMsgValidation";
import SelectValidation from "../Wizard/SelectValidation";

import PageContainer from "../../UIElements/Containers";
import Input from "../Elements/Input";
import Label from "../Elements/Label";
import { CONSTANTS } from "../../../helpers/constants";

import "react-quill/dist/quill.snow.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";

import { saveAs } from "file-saver";

import "../Elements/styles.css";
import { getAge, getEntities } from "../../../helpers/utils";

const Edit = () => {
  //INICIO CONEXION CON LA API-------------------------------------------------------------------------------------------------------
  const [ user, setUser ] = useState();
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [primerApellidoUsuario, setPrimerApellidoUsuario] = useState("");
  const [segundoApellidoUsuario, setSegundoApellidoUsuario] = useState("");
  const [correoUsuario, setCorreoUsuario] = useState("");
  const [contrasenaUsuario, setContrasenaUsuario] = useState("");
  const [userVerified, setUserVerified] = useState(0);
  const [userCompanie, setUserCompanie] = useState(0);

  const [enablePsw, setEnablePsw] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [companies, setCompanies] = useState("");


  const [participante, setParticipante] = useState();
  const [isEditing, setIsEditing] = useState(false);

  let updateUser = async () => {
    const userLogged = JSON.parse(sessionStorage.getItem("user")).id
    const req = await fetch(
      `${CONSTANTS.base_url}api/v1/data/user/${user.user_id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": CONSTANTS.TOKEN,
        },
        body: JSON.stringify({
          user_name: nombreUsuario,
          user_lastName: primerApellidoUsuario,
          user_maternalLastName: segundoApellidoUsuario,
          user_mail: correoUsuario,
          user_verified: userVerified,
          company_id: userCompanie,
          user_id: userLogged,
        }),
      }
    );
	const resUpdated = await req.json();
    if(resUpdated.success){
		location.reload();
      //location.assign("/usuarios");
    }
  }

  let updateUserPassword = async () => {
    const userLogged = JSON.parse(sessionStorage.getItem("user")).id
    if( password !== confirmPassword ){
      alert("Las contraseñas no coinciden");
      return
    }
    const req = await fetch(
      `${CONSTANTS.base_url}api/v1/data/user/updatePassword/${user.user_id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": CONSTANTS.TOKEN,
        },
        body: JSON.stringify({
          user_password: password,
          user_mail: correoUsuario,
          user_id: userLogged,
        }),
      }
    );
  }

  let getCompanies = async () => {
    const userLogged = JSON.parse(sessionStorage.getItem("user")).id
    const req = await fetch(
      `${CONSTANTS.base_url}api/v1/data/company/`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": CONSTANTS.TOKEN,
        }
      }
    );
    const companies = await req.json();
    return companies.data;
  }
  //FIN CONEXION CON LA API----------------------------------------------------------------------------------------------------------

  // Get form data info 
  useEffect(async () => {
    const listCompanies = await getCompanies();
    console.log("companies::",  listCompanies);
    setCompanies( listCompanies );
  }, [])

  // Get user by id
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    (async () => {
      const req = await fetch(
        `${CONSTANTS.base_url}api/v1/data/user/${urlParams.get(
          "user_id"
        )}`,
        {
          headers: {
            "x-access-token": CONSTANTS.TOKEN,
          },
        }
      );
      const res = await req.json();
      console.log(res)
      console.log("userbyId", res.data,"verified", res.data.user_verified, "companie", res.data.company_id );
      if (res.success) {
        setUser(res.data);
        setNombreUsuario(res.data.user_name);
        setPrimerApellidoUsuario(res.data.user_lastName);
        setSegundoApellidoUsuario(res.data.user_maternalLastName);
        setCorreoUsuario(res.data.user_mail);
        setUserVerified(res.data.user_verified);
        setUserCompanie(res.data.company_id);
      }
    })();
  }, []);

  // Select option makers
  const companieMakerOptions = () => {
    if(companies){
      let companiesOption = companies.map( (item) => {
        return <option value={item.company_id}> { item.company_name } </option>
      } )
      return companiesOption
    }else{
      return <option>Sin compañías</option>
    }
  }


  return (
    <div className="page-content-wrapper ">
      {/* START PAGE CONTENT */}
      {user && (
        <div
          className="content "
          style={{
            paddingTop: "60px",
          }}
        >
          <PageContainer>
            <div className="row">
              <div className="col-6">
                <h3>{`${nombreUsuario} ${primerApellidoUsuario} | Estatus ${userVerified == 0 ? "No Activo" : "Activo"  }`}</h3>
              </div>
              <div className="col-6 d-flex align-items-center">           
                {isEditing ? (
                  <React.Fragment>
                    <button
                      aria-label=""
                      className="btn btn-white btn-cons m-t-10 col"
                      onClick={() => setIsEditing(false)}
                    >
                      Cancelar
                    </button>
                    <button
                      aria-label=""
                      className="btn btn-primary btn-cons m-t-10 col"
                      onClick={() => updateUser()}
                    >
                      <small>Actualizar Usuario</small>
                    </button>
                  </React.Fragment>
                ) : (
                  <button
                    aria-label=""
                    className="btn btn-primary btn-cons m-t-10 col-2"
                    onClick={() => setIsEditing(true)}
                  >
                    <small>Editar Usuario</small>
                  </button>
                )}
              </div>
            </div>
            <div
              className="row"
              style={{ overflowY: "scroll", maxHeight: "85vh" }}
            >
              {/*INICIO SECCION USUARIOS*/}
              <div className="col-lg-12">
                <div className="card card-default">
                  <div className="card-body">
                    <h4>Datos del Usuario</h4>
                    <form className="" role="form">
                      <div className="row">
                        {/*INICIO DEL CAMPO NOMBRE*/}
                        <div className="col-md-4">
                          {/*El "required" pone un asterisco en la esquina del campo*/}
                          <div
                            className={`form-group form-group-default required `}
                          >
                            <Label>
                              <b>Nombre</b>
                            </Label>
                            <Input
                              type="text"
                              className={`form-control`}
                              required=""
                              placeholder="Introduzca los datos"
                              value={nombreUsuario}
                              onChange={(e) => setNombreUsuario(e.target.value)}
                            />
                          </div>
                        </div>
                        {/*FIN DEL CAMPO NOMBRE*/}
                        {/*INICIO DEL CAMPO PRIMER APELLIDO*/}
                        <div className="col-md-4">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Primer Apellido</b>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Introduzca los datos"
                              value={primerApellidoUsuario}
                              onChange={(e) => {
                                setPrimerApellidoUsuario(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        {/*FIN DEL CAMPO PRIMER APELLIDO*/}
                        {/*INICIO DEL CAMPO SEGUNDO APELLIDO*/}
                        <div className="col-md-4">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Segundo Apellido</b>
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="Introduzca los datos"
                              value={segundoApellidoUsuario}
                              onChange={(e) => {
                                setSegundoApellidoUsuario(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        {/*FIN DEL CAMPO SEGUNDO APELLIDO*/}
                        {/*INICIO DEL CAMPO CORREO*/}
                        <div className="col-md-6">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Correo</b>
                            </Label>
                            <Input
                              type="email"
                              className="form-control"
                              placeholder="Introduzca los datos"
                              value={correoUsuario}
                              onChange={(e) => setCorreoUsuario(e.target.value)}
                            />
                          </div>
                        </div>
                        {/*FIN DEL CAMPO CORREO*/}
                        {/*INICIO DEL CAMPO NACIMIENTO*/}
                        <div className="col-md-6">
                          <div className={`form-group form-group-default `}>
                            <Label>
                              <b>Contraseña</b>
                            </Label>
                            <Input
                              type="password"
                              className="form-control"
                              placeholder="Contraseña de Usuario"
                              value={contrasenaUsuario}
                              disabled
                              onChange={(e) =>
                                setContrasenaUsuario(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        {/*FIN DEL CAMPO NACIMIENTO*/}
                        {/*INICIO DEL CAMPO VERIFICACIÓN*/}
                        <div className="col-md-6">
                            <div className={`form-group form-group-default`}>
                              <Label>
                                <b>Estatus</b>
                              </Label>
                              <select
                                class="form-control"
                                id="verified"
                                value={userVerified}
                                onChange={(e) => setUserVerified(e.target.value)}
                              >
                                <option value={0} > No Activo </option>
                                <option value={1} > Activo </option>

                              </select>
                            </div>
                        </div>
                        {/*FIN DEL CAMPO NOMBRE ENTIDAD*/}     
                        {/*INICIO DEL CAMPO COMPAÑIA*/}
                        <div className="col-md-6">
                            <div className={`form-group form-group-default`}>
                              <Label>
                                <b>Compañía</b>
                              </Label>
                              <select
                                class="form-control"
                                id="userCompanie"
                                value={userCompanie}
                                onChange={(e) => setUserCompanie(e.target.value)}
                              >
                                { companies && companieMakerOptions() }
                              </select>
                            </div>
                        </div>
                        {/*FIN DEL CAMPO NOMBRE ENTIDAD*/}                        
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/*FIN SECCION USUARIOS*/}
            </div>

            <div
              className="row"
              style={{ overflowY: "scroll", maxHeight: "85vh" }}
            >
              {/*INICIO SECCION CONTRASEÑA*/}
              <div className="col-lg-12">
                <div className="card card-default">
                  <div className="card-body">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <h4>Actualizar contraseña</h4>
                      <div style={{display: "flex"}}>
                        <button
                          aria-label=""
                          className="btn btn-white btn-cons m-t-10 col"
                          onClick={() => setEnablePsw(!enablePsw)}
                        >
                          Habilitar
                        </button>
                        <button
                          aria-label=""
                          className="btn btn-primary btn-cons m-t-10 col"
                          onClick={() => updateUserPassword()}
                        >
                          <small>Actualizar Contraseña</small>
                        </button>
                      </div>
                    </div>
                    <form className="" role="form">
                      <div className="row">
                        {/*INICIO DEL CAMPO CONTRASEÑA*/}
                        <div className="col-md-4">
                          {/*El "required" pone un asterisco en la esquina del campo*/}
                          <div
                            className={`form-group form-group-default required `}
                          >
                            <Label>
                              <b>Contraseña</b>
                            </Label>
                            <Input
                              type="password"
                              className={`form-control`}
                              required=""
                              placeholder="Introduzca los datos"
                              disabled = { enablePsw === true ? false : true }
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        {/*FIN DEL CAMPO CONTRASEÑA*/}     
                        {/*INICIO DEL CAMPO CONFIRMAR CONTRASEÑA*/}
                        <div className="col-md-4">
                          {/*El "required" pone un asterisco en la esquina del campo*/}
                          <div
                            className={`form-group form-group-default required `}
                          >
                            <Label>
                              <b>Confirmar Contraseña</b>
                            </Label>
                            <Input
                              type="password"
                              className={`form-control`}
                              required=""
                              placeholder="Introduzca los datos"
                              disabled = { enablePsw === true ? false : true }
                              value={confirmPassword}
                              onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                          </div>
                        </div>
                        {/*FIN DEL CAMPO CONFIRMAR CONTRASEÑA*/}                        
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/*FIN SECCION CONTRASEÑA*/}
            </div>
          </PageContainer>
        </div>
      )}
      {/* END PAGE CONTENT */}
    </div>
  );
};

export default Edit;
