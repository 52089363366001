import React from 'react';
import { connect } from 'react-redux';
import SidebarItem from './SidebarItem';
import 'react-slidedown/lib/slidedown.css'
import './style.scss';

class LandingSidebar extends React.Component {
    state = {
        isVisible: false,
        shouldExpand: false,
        shouldExpandNest: false,
        clickedMenu: null,
        clickedMenuNest: null,
        shouldSideBarPin: false,
        shouldToggleSidebarHeader: false,
        shouldBoxedLayout: false
    }
    componentDidMount() {

    }
    handleMouseEnter = () => {
        this.setState({
            isVisible: true
        })
    }
    handleMouseLeave = () => {
        this.setState({
            isVisible: false
        })
    }
    handleMenuExpan = (value) => {
        let customShouldExpan = true
        if (this.state.clickedMenu === value) {
            customShouldExpan = !this.state.shouldExpand
        }
        this.setState({
            shouldExpand: customShouldExpan,
            clickedMenu: value,
            clickedMenuNest: null
        })
    }
    handleMenuExpanNest = (value) => {
        this.setState({
            shouldExpandNest: !this.state.shouldExpandNest,
            clickedMenuNest: value
        })
    }
    togglePin = () => {
        this.setState({
            shouldSideBarPin: !this.state.shouldSideBarPin
        }, () => {
            if (this.state.shouldSideBarPin) {
                document.body.classList.add('menu-pin')
            } else {
                document.body.classList.remove('menu-pin')
            }
        })
    }
    toggleSidebarHeader = () => {
        this.setState({
            shouldToggleSidebarHeader: !this.state.shouldToggleSidebarHeader
        })
    }

    navBoxedLayout = () => {
        this.setState({
            shouldBoxedLayout: !this.state.shouldBoxedLayout
        }, () => {
            if (this.state.shouldBoxedLayout) {
                document.body.classList.add('box-layout menu-pin  mac desktop js-focus-visible')
            } else {
                document.body.classList.remove('box-layout menu-pin  mac desktop js-focus-visible')
            }
        })
    }

  render() {
    const { isVisible } = this.state
    const style = { transform: `translate3d(${isVisible ? 280 - 70 : 0}px, 0,0)` };
    let toggleHeader = this.state.shouldToggleSidebarHeader ? "sidebar-overlay-slide from-top show" : "sidebar-overlay-slide from-top";
    let shouldViewHeader = (
      window.location.pathname !== "/extra/404" &&
      window.location.pathname !== "/extra/500" &&
      window.location.pathname !== "/extra/login" &&
      window.location.pathname !== "/extra/register" &&
      window.location.pathname !== "/extra/lock_screen" ? true : false
    );
    let isBoxedLayout = window.location.pathname.includes("/boxed_layout") ? true : false;
    if (isBoxedLayout) {
      document.body.classList.remove('dashboard')
      document.body.classList.add('box-layout', 'menu-pin', 'mac', 'desktop', 'js-focus-visible')
    } else {
      document.body.classList.add('dashboard')
    }

    return (
      <nav style={shouldViewHeader ? style : { display: "none" }} className={`page-sidebar ${this.props.openState ? 'visible' : ''}`}
        data-pages="sidebar"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}>
        {/* BEGIN SIDEBAR MENU TOP TRAY CONTENT*/}
        <div className={toggleHeader} id="appMenu">
          <div className="row">
            <div className="col-xs-6 no-padding">
              <a href="#" className="p-l-40"><img src="/assets/img/demo/social_app.svg" alt="socail" /></a>
            </div>
            <div className="col-xs-6 no-padding">
              <a href="#" className="p-l-10"><img src="/assets/img/demo/email_app.svg" alt="socail" /></a>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-6 m-t-20 no-padding">
              <a href="#" className="p-l-40"><img src="/assets/img/demo/calendar_app.svg" alt="socail" /></a>
            </div>
            <div className="col-xs-6 m-t-20 no-padding">
              <a href="#" className="p-l-10"><img src="/assets/img/demo/add_more.svg" alt="socail" /></a>
            </div>
          </div>
        </div>
        {/* END SIDEBAR MENU TOP TRAY CONTENT*/}
        {/* BEGIN SIDEBAR MENU HEADER*/}
        <div className="sidebar-header">
          <img src="/assets/img/doomie/futuro_co_version_4.png" alt="logo"
            style={this.props.openState ? { marginRight: '0px' } : {}}
            className="brand" data-src="/assets/img/doomie/futuro_co_version_4.png" data-src-retina="/assets/img/doomie/futuro_co_version_1.PNG"
            height="38" />
          {(this.state.isVisible || this.state.shouldSideBarPin) ?
            <div className="sidebar-header-controls">
              <button aria-label="Pin Menu" type="button" onClick={this.togglePin}
                className="btn btn-icon-link invert d-lg-inline-block d-xlg-inline-block d-md-inline-block d-sm-none d-none"
                data-toggle-pin="sidebar">
                <i className="pg-icon"></i>
              </button>
            </div>
            : null
          }
        </div>
        {/* END SIDEBAR MENU HEADER*/}
        {/* START SIDEBAR MENU */}
        <div className="sidebar-menu" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 'calc(100% - 60px)' }}>
          {/* BEGIN SIDEBAR MENU ITEMS*/}
          <ul className="menu-items" style={{ height: 'auto' }}>
            <SidebarItem url="/home" title="Home" subTitle="Pagina principal" icon={<i className="pg-icon">home</i>} topMargin="m-t-30" />
            <SidebarItem url="/" title="Matriz" subTitle="Formulario matriz" icon="FM" topMargin="m-t-30" />
            <SidebarItem url="/" title="Sistema de apoyos" subTitle="Formulario sistema de apoyos" icon="SA" topMargin="m-t-30" />
            <SidebarItem url="/" title="Participantes" subTitle="Formulario participantes" icon="PA" topMargin="m-t-30" />
          </ul>
          <div>
            <a href="/" className="p-l-10">Política de Privacidad</a>
            <span className="p-l-10">|</span>
            <a href="/" className="p-l-10">Soporte</a>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => {
  return {
    openState: state.QuickView.openMobileSideBar
  }
}
const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(LandingSidebar);