import React, { useState, useEffect } from "react";
import { Highlighter } from "react-bootstrap-typeahead";
import { ValidatorForm } from "react-form-validator-core";
import PageScroll from "../../UIElements/PageScroll";
import Breadcrumb from "../../UIElements/Breadcrumb";
import PageContainer from "../../UIElements/Containers";
import "react-quill/dist/quill.snow.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import FlipBarNotifyModule from "../../UIElements/Notification/FlipBarNotification/FlipBarNotifyModule";
import { CONSTANTS } from "../../../helpers/constants";
import "../Elements/styles.css";
import FormData from "./FormData";

const content = ({ path }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [dynamicForm, setDynamicForm] = useState(null);
  const [notification, setNotification] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    (async () => {
      const req = await fetch(`${CONSTANTS.base_url}api/v1/data/form/1`, { headers: { 'x-access-token': CONSTANTS.TOKEN }});
      const reqAnswers = await fetch(`${CONSTANTS.base_url}api/v1/data/answer/p/${urlParams.get('participante_id')}`, { headers: { 'x-access-token': CONSTANTS.TOKEN }});
      const res = await req.json();
      const resAnswers = await reqAnswers.json();
      if (res.data && res.data.Questions.length) {
        const groups = res.data.Questions.reduce((acc, currentValue) => {
          let groupKey = currentValue.question_group;
          if (currentValue.question_allowed_entities && JSON.parse(currentValue.question_allowed_entities).length) {
            if (JSON.parse(currentValue.question_allowed_entities).includes(JSON.parse(sessionStorage.getItem('user')).entity)) {
              if (!acc[groupKey]) acc[groupKey] = [];
              acc[groupKey].push(currentValue);
            }
          } else {
            if (!acc[groupKey]) acc[groupKey] = [];
            acc[groupKey].push(currentValue);
          }
          return acc;
        }, {});
        setDynamicForm(groups);
      }
      if (resAnswers.data && resAnswers.data.length) {
        const preloadAnswers = {};
        resAnswers.data.map(answer => {
          preloadAnswers[`question_id_${answer.question_id}`] = {
            participante_id: answer.participante_id.toString(),
            question_id: answer.question_id,
            answer_value: answer.answer_value,
            answer_createdAt: answer.answer_createdAt,
            answer_date: answer.answer_date
          }
        })
        setFormData(preloadAnswers);
      } else {
        setFormData({});
      }
    })()
  }, [])

  useEffect(() => {
    if (notification && notification.timeout) {
      setTimeout(() => {
        setNotification(null);
      }, notification.timeout);
    }
  }, [notification])

  let handleFormSubmit = async () => {
    const answers = Object.values(formData);
    answers.map(async (answer) => {
      const req = await fetch(
        `${CONSTANTS.base_url}api/v1/data/answer`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": CONSTANTS.TOKEN,
          },
          body: JSON.stringify({
            ...answer,
            complete: urlParams.get('complete')
          }),
        }
      );
      const res = await req.json();
      if (res.success) {
        setNotification({ message: res.mensaje, timeout: 5000 });
      } else {
        setNotification({ message: res.mensaje, type: "error", timeout: 5000 });
      }
    });
  };

  const [props] = useState({});
  const _renderMenuItemChildren = (option, props, index) => {
    return [
      <Highlighter key="name" search={props.text}>
        {option.name}
      </Highlighter>,
      <div key="population">
        <small>Population: {option.population.toLocaleString()}</small>
      </div>,
    ];
  };
  props.renderMenuItemChildren = _renderMenuItemChildren;

  return (
    <div className="page-content-wrapper ">
      {notification && <FlipBarNotifyModule notifications={[{ type: notification.type || 'success', desc: notification.message }]} position={notification.position || 'top-right'} />}
      {/* START PAGE CONTENT */}
      <div
        className="content "
        style={{
          paddingTop:
            path && path.includes("/executive")
              ? "170px"
              : path.includes("/casual")
                ? "100px"
                : "60px",
        }}
      >
        <div className="jumbotron" data-pages="parallax">
          <PageContainer
            className={
              path.includes("/executive") || path.includes("/casual")
                ? "container sm-p-l-0 sm-p-r-0"
                : "sm-p-l-0 sm-p-r-0 "
            }
          >
            <PageScroll>
              <Breadcrumb>
                <li className="breadcrumb-item">
                  <a href={`/participants_form?participant_id=${urlParams.get('participante_id')}`}>Volver</a>
                </li>
              </Breadcrumb>
            </PageScroll>
          </PageContainer>
        </div>

        <PageContainer
          className={
            path.includes("/executive") || path.includes("/casual")
              ? "container"
              : " "
          }
        >
          <div className="row">
            <div className="col-8">
              <h3>Sistema de Apoyos</h3>
            </div>
          </div>
          <ValidatorForm instantValidate={true} onSubmit={handleFormSubmit} className="p-t-15">
            <div className="row">
              {(dynamicForm && formData) && Object.keys(dynamicForm).map((key, index) => (
                <FormData key={index} data={dynamicForm[key]} group={key} handleChange={setFormData} formData={formData} />
              ))}
            </div>
            <button aria-label="" className="btn btn-primary btn-cons m-t-10" type="submit" disabled={loading}>{!loading ? 'Guardar' : 'Guardando...'}</button>
          </ValidatorForm>
        </PageContainer>
      </div>
      {/* END PAGE CONTENT */}
    </div>
  );
};

export default content;
