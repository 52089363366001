import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const barchartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top"
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

const BarChart = ({ data, id }) => {
  return (
    <div className="page-content-wrapper">
      <Bar options={barchartOptions} data={data} tabIndex={id} />
    </div>
  );
};

export default BarChart;
