import React, { useEffect, useState, useRef } from "react";
import InputMask from "react-input-mask";
import Label from "../Forms/Elements/Label";
import PageContainer from "../UIElements/Containers";
import "../Dashboard/styles.css";
import { CONSTANTS } from "../../helpers/constants";
import Input from "../Forms/Elements/Input";
import { filterKeys, filterNames } from "./filterKeys";
import { DownloadTableExcel } from "react-export-table-to-excel";
import SingleReportButton from "./reports/SingleReportButton";

const Filtrado = ({ path }) => {
  const targetRef = useRef();
  const [participants, setParticipants] = useState(null);
  const [filteredParticipants, setFilteredParticipants] = useState(null);
  const [singleDimension, setSingleDimension] = useState();

  //Filters
  const [filtersUsed, setFiltersUsed] = useState({});
  const [allFilters, setAllFilters] = useState([]);
  const [genero, setGenero] = useState(false);
  const [acommodationTime, setAcommodationTime] = useState(false);
  const [previousAccomodation, setPreviousAcommodationTime] = useState(false);
  const [noHomeState, setNoHomeState] = useState(false);
  const [assaultState, setAssaultState] = useState(false);
  const [problematic, setProblematic] = useState(false);
  const [nacionalidad, setNacionalidad] = useState(false);
  const [minEdad, setMinEdad] = useState(0);
  const [maxEdad, setMaxEdad] = useState(0);
  const [colectividadVivienda, setColectividadVivienda] = useState(false);
  const [intermitenciaApoyos, setIntermitenciaApoyos] = useState(false);
  const [autonomiaEconomia, setAutonomiaEconomia] = useState(false);
  const [copago, setCopago] = useState(false);
  const [identidadJuridica, setIdentidadJuridica] = useState(false);
  const [atencionPsicologica, setAtencionPsicologica] = useState(false);
  const [asesoramientoJuridico, setAsesoramientoJuridico] = useState(false);
  const [itinerarioFormativo, setItinerarioFormativo] = useState(false);
  const [planMentoria, setPlanMentoria] = useState(false);
  const [asambleasConvivenciales, setAsambleasConvivenciales] = useState(false);
  const [ocioIndividual, setOcioIndividual] = useState(false);
  const [ocioGrupal, setOcioGrupal] = useState(false);
  const [voluntariado, setVoluntariado] = useState(false);
  const [incipitMapeo, setIncipitMapeo] = useState(false);
  const [incipitCollage, setIncipitCollage] = useState(false);
  const [incipitPensar, setIncipitPensar] = useState(false);
  const [discoForum, setDiscoForum] = useState(false);
  const [carpeDiem, setCarpeDiem] = useState(false);
  const [ecomapa, setEcomapa] = useState(false);
  const [lineaDeFuturo, setLineaDeFuturo] = useState(false);
  const [podcast, setPodcast] = useState(false);
  const [contextualizacionCierre, setContextualizacionCierre] = useState(false);
  const [pactosCuidados, setPactosCuidados] = useState(false);
  const [mapasAlma, setMapasAlma] = useState(false);
  const [tallerCuidados, setTallerCuidados] = useState(false);
  const [mapasCorporales, setMapasCorporales] = useState(false);
  const [ageRange, setAgeRange] = useState('');

  const edadMinMap = {
	10: 0,
    0: 1,
    1: 12,
    2: 16,
    3: 18,
    4: 22,
    5: 26,
    6: 30,
    7:36,
    8: 50,
    9: 65
  }

  const edadMaxMap = {
	10: 0,
    0: 11,
    1: 15,
    2: 17,
    3: 21,
    4: 25,
    5: 29,
    6: 35,
    7: 50,
    8: 65,
    9: 0
  }

  const groups = [
    "AUTOCONCEPTO - AUTOVALORACIÓN",
    "EXPECTATIVAS - VALORACIÓN PROFESIONAL",
    "ATRIBUCIÓN DE CAUSALIDAD - VALORACIÓN PROFESIONAL",
    "ATRIBUCIÓN DE CAUSALIDAD - AUTOVALORACIÓN",
    "MANEJO DE LA INCERTIDUMBRE - VALORACIÓN PROFESIONAL",
    "CONDUCTAS DE SALUD/BIENESTAR - VALORACIÓN PROFESIONAL",
    "RESILENCIA - AUTOVALORACIÓN",
    "ESPIRITUALIDAD - AUTOVALORACIÓN",
    "IDENTIDAD DIGITAL - AUTOVALORACIÓN",
    "CONCIENCIA DE CIUDADANÍA - VALORACIÓN PROFESIONAL",
    "PERTENENCIA A GRUPOS - VALORACIÓN PROFESIONAL",
    "SOCIALIZACIÓN, MANEJO DE LAS NORMAS - VALORACIÓN PROFESIONAL",
    "SEGURIDAD ONTOLÓGICA - VALORACIÓN PROFESIONAL",
    "SENTIDO DE PERTENENCIA - VALORACIÓN PROFESIONAL",
    "GARANTIA DE INGRESOS - VALORACIÓN PROFESIONAL",
    "ACCESO A SERVICIOS EN LA COMUNIDAD Y APOYO COMUNITARIO - AUTOVALORACIÓN",
    "PARTICIPACIÓN EN REDES SOCIALES DIGITALES - VALORACIÓN PROFESIONAL",
    "OCIO - VALORACIÓN PROFESIONAL",
    "APOYO SOCIAL (REDES NATURALES) - AUTOVALORACIÓN",
    "POSIBILIDAD DE ACCESO A LA VIVIENDA - VALORACIÓN PROFESIONAL",
    "CONVIVENCIA VECINAL - VALORACIÓN PROFESIONAL",
    "BRECHA DIGITAL,ACCESO Y EVALUCIÓN DE LA INFORMACIÓN  - AUTOVALORACIÓN",
    "IDIOMA  - AUTOVALORACIÓN",
    "EMPLEABILIDAD  - VALORACIÓN PROFESIONAL",
    "DESTREZAS FORMATIVAS  - VALORACIÓN PROFESIONAL",
    "MOVILIDAD  - VALORACIÓN PROFESIONAL",
    "AUTONOMÍA EN LA INTERACCIÓN CON LAS ADMINISTRACIONES  - VALORACIÓN PROFESIONAL",
    "DESARROLLO DE CONCIENCIA DE DESINSTITUCIONALIZACIÓN  - VALORACIÓN PROFESIONAL",
    "HABILIDADES SOCIALES  - VALORACIÓN PROFESIONAL",
    "HABILIDADES CONVIVENCIALES  - AUTOVALORACIÓN",
    "AUTONOMÍA EN LA GESTIÓN DE LA SALIDA  - VALORACIÓN PROFESIONAL",
  ];

  useEffect(() => {
    const userLogged = JSON.parse(sessionStorage.getItem("user"));
    (async () => {
      //${CONSTANTS.base_url}api/v1/data/participants/byUser/${userLogged.entity}`
      const getParticipants = await fetch(
        `${CONSTANTS.base_url}api/v1/data/participants`,
        {
          headers: {
            "x-access-token": CONSTANTS.TOKEN,
          },
        }
      );
      const participant = await getParticipants.json();
      setParticipants(participant.data);
    })();
  }, []);

  useEffect(() => {
    if (genero) {
      filtersUsed.genero = genero;
    } else {
      delete filtersUsed.genero;
    }
    if (nacionalidad) {
      filtersUsed.nacionalidad = nacionalidad;
    } else {
      delete filtersUsed.nacionalidad;
    }
    if (minEdad) {
      filtersUsed.edadMinima = minEdad == 0 ? 0 : minEdad;
    } else {
      delete filtersUsed.edadMinima;
    }
    if (maxEdad) {
      filtersUsed.edadMaxima = maxEdad;
    } else {
      delete filtersUsed.edadMaxima;
    }
    if (acommodationTime) {
      filtersUsed.history_acommodation_time = acommodationTime;
    } else {
      delete filtersUsed.history_acommodation_time;
    }
    if (previousAccomodation) {
      filtersUsed.history_tiempoSinVivienda = previousAccomodation;
    } else {
      delete filtersUsed.history_tiempoSinVivienda;
    }
    if (noHomeState) {
      filtersUsed.history_situacionCalle = noHomeState;
    } else {
      delete filtersUsed.history_situacionCalle;
    }
    if (assaultState) {
      filtersUsed.history_agresionPrevia = assaultState;
    } else {
      delete filtersUsed.history_agresionPrevia;
    }
    if (problematic) {
      filtersUsed.history_problematica = problematic;
    } else {
      delete filtersUsed.history_problematica;
    }
    if (colectividadVivienda) {
      filtersUsed.colectividadVivienda = colectividadVivienda;
    } else {
      delete filtersUsed.colectividadVivienda;
    }
    if (intermitenciaApoyos) {
      filtersUsed.intermitenciaApoyos = intermitenciaApoyos;
    } else {
      delete filtersUsed.intermitenciaApoyos;
    }
    if (autonomiaEconomia) {
      filtersUsed.autonomiaEconomiaDomestica = autonomiaEconomia;
    } else {
      delete filtersUsed.autonomiaEconomiaDomestica;
    }
    if (copago) {
      filtersUsed.copago = copago;
    } else {
      delete filtersUsed.copago;
    }
    if (identidadJuridica) {
      filtersUsed.identidadJuridica = identidadJuridica;
    } else {
      delete filtersUsed.identidadJuridica;
    }
    if (atencionPsicologica) {
      filtersUsed.atencionPsicologica = atencionPsicologica;
    } else {
      delete filtersUsed.atencionPsicologica;
    }
    if (asesoramientoJuridico) {
      filtersUsed.asesoramientoJuridico = asesoramientoJuridico;
    } else {
      delete filtersUsed.asesoramientoJuridico;
    }
    if (itinerarioFormativo) {
      filtersUsed.itinerarioFormativo = itinerarioFormativo;
    } else {
      delete filtersUsed.itinerarioFormativo;
    }
    if (planMentoria) {
      filtersUsed.planMentoria = planMentoria;
    } else {
      delete filtersUsed.planMentoria;
    }
    if (asambleasConvivenciales) {
      filtersUsed.asambleasConvivenciales = asambleasConvivenciales;
    } else {
      delete filtersUsed.asambleasConvivenciales;
    }
    if (ocioIndividual) {
      filtersUsed.ocioIndividual = ocioIndividual;
    } else {
      delete filtersUsed.ocioIndividual;
    }
    if (ocioGrupal) {
      filtersUsed.ocioGrupal = ocioGrupal;
    } else {
      delete filtersUsed.ocioGrupal;
    }
    if (voluntariado) {
      filtersUsed.voluntariado = voluntariado;
    } else {
      delete filtersUsed.voluntariado;
    }
    if (incipitMapeo) {
      filtersUsed.incipitMapeo = incipitMapeo;
    } else {
      delete filtersUsed.incipitMapeo;
    }
    if (incipitCollage) {
      filtersUsed.incipitCollage = incipitCollage;
    } else {
      delete filtersUsed.incipitCollage;
    }
    if (incipitPensar) {
      filtersUsed.incipitPensar = incipitPensar;
    } else {
      delete filtersUsed.incipitPensar;
    }
    if (discoForum) {
      filtersUsed.discoForum = discoForum;
    } else {
      delete filtersUsed.discoForum;
    }
    if (carpeDiem) {
      filtersUsed.carpeDiem = carpeDiem;
    } else {
      delete filtersUsed.carpeDiem;
    }
    if (voluntariado) {
      filtersUsed.voluntariado = voluntariado;
    } else {
      delete filtersUsed.voluntariado;
    }
    if (lineaDeFuturo) {
      filtersUsed.lineaDeFuturo = lineaDeFuturo;
    } else {
      delete filtersUsed.lineaDeFuturo;
    }
    if (podcast) {
      filtersUsed.podcast = podcast;
    } else {
      delete filtersUsed.podcast;
    }
    if (contextualizacionCierre) {
      filtersUsed.contextualizacionCierre = contextualizacionCierre;
    } else {
      delete filtersUsed.contextualizacionCierre;
    }
    if (pactosCuidados) {
      filtersUsed.pactosCuidados = pactosCuidados;
    } else {
      delete filtersUsed.pactosCuidados;
    }
    if (mapasAlma) {
      filtersUsed.mapasAlma = mapasAlma;
    } else {
      delete filtersUsed.mapasAlma;
    }
    if (tallerCuidados) {
      filtersUsed.tallerCuidados = tallerCuidados;
    } else {
      delete filtersUsed.tallerCuidados;
    }
    if (mapasCorporales) {
      filtersUsed.mapasCorporales = mapasCorporales;
    } else {
      delete filtersUsed.mapasCorporales;
    }
  }, [
    genero,
    nacionalidad,
    minEdad,
    maxEdad,
    acommodationTime,
    previousAccomodation,
    noHomeState,
    assaultState,
    problematic,
    colectividadVivienda,
    intermitenciaApoyos,
    autonomiaEconomia,
    copago,
    identidadJuridica,
    atencionPsicologica,
    asesoramientoJuridico,
    itinerarioFormativo,
    planMentoria,
    asambleasConvivenciales,
    ocioIndividual,
    ocioGrupal,
    voluntariado,
    incipitMapeo,
    incipitCollage,
    incipitPensar,
    discoForum,
    carpeDiem,
    ecomapa,
    lineaDeFuturo,
    podcast,
    contextualizacionCierre,
    pactosCuidados,
    mapasAlma,
    tallerCuidados,
    mapasCorporales,
  ]);

  useEffect(() => {
    const filtersToApply = Object.keys(filtersUsed);
    const ageField = document.querySelector('select[name="ageRange"]');
    const checks = document.querySelectorAll("input[type=checkbox]");
    const checksChecked = Array.prototype.slice.call(checks).filter((check) => check.checked === true);

    if(checksChecked.length < 2){
      checks.forEach((check) => {
        check.disabled = false;
      });
      ageField.disabled = false;
    }
      

    if (
      Number(ageField.value) == 10 &&
      checksChecked.length >= 2
    ) {
      checks.forEach((check) => {
        if (!check.checked) {
          check.disabled = true;
        }
      });
      ageField.disabled = true;      
      // confirm('Atencion: Sólo puedes seleccionar 2 filtros por reporte');
    }

    if (
      Number(ageField.value) !== 10 &&
      checksChecked.length >= 1
    ) {
      checks.forEach((check) => {
        if (!check.checked) {
          check.disabled = true;
        }
      });
            console.log(checksChecked, ageField.value)
      
      ageField.disabled = true;      
      
      // confirm('Atencion: Sólo puedes seleccionar 2 filtros por reporte');
    }
  });

  const filterParticipants = () => {
    const filtersToApply = Object.keys(filtersUsed);
    setAllFilters([...filtersToApply]);
    console.log(allFilters, filtersToApply);
    let filteredByAge;
    const edadMinima = filtersToApply.indexOf("edadMinima") != -1;
    const edadMaxima = filtersToApply.indexOf("edadMaxima") != -1;
    if (edadMinima || edadMaxima) {
      filteredByAge = filterByAge(participants);
      edadMinima
        ? filtersToApply.splice(filtersToApply.indexOf("edadMinima"), 1)
        : "";
      edadMaxima
        ? filtersToApply.splice(filtersToApply.indexOf("edadMaxima"), 1)
        : "";

      setFilteredParticipants(
        getParticipantsFiltered(filtersToApply, filteredByAge, true)
      );
      setSingleDimension(false);
    } else {
      if (filtersToApply.length > 1) {
        setFilteredParticipants(
          getParticipantsFiltered(filtersToApply, participants)
        );
        setSingleDimension(false);
      } else {
        setFilteredParticipants(
          getParticipantsFiltered(filtersToApply, participants)
        );
        setSingleDimension(true);
      }
    }
    setFiltersUsed({});
    setNacionalidad("");
    setGenero(false);
    setMinEdad(0);
    setMaxEdad(0);
    setAcommodationTime(false);
    setPreviousAcommodationTime(false);
    setNoHomeState(false);
    setAssaultState(false);
    setProblematic(false);
    setColectividadVivienda(false);
    setIntermitenciaApoyos(false);
    setAutonomiaEconomia(false);
    setCopago(false);
    setIdentidadJuridica(false);
    setAtencionPsicologica(false);
    setAsesoramientoJuridico(false);
    setItinerarioFormativo(false);
    setPlanMentoria(false);
    setAsambleasConvivenciales(false);
    setOcioIndividual(false);
    setOcioGrupal(false);
    setVoluntariado(false);
    setIncipitMapeo(false);
    setIncipitCollage(false);
    setIncipitPensar(false);
    setDiscoForum(false);
    setCarpeDiem(false);
    setEcomapa(false);
    setLineaDeFuturo(false);
    setPodcast(false);
    setContextualizacionCierre(false);
    setPactosCuidados(false);
    setMapasAlma(false);
    setTallerCuidados(false);
    setMapasCorporales(false);
    setAgeRange('');
  };

  const setCuidadoAutocuidado = (participants = {}) => {
    if (participants) {
      const data = [];
      participants.map((participant) => {
        participant?.Answers?.forEach((answer, aIdx) => {
          const acceptedIdx = [
            0, 1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 16, 17, 18, 25, 26, 27,
            29, 30, 31, 32, 33,
          ];
          const groupIdx = groups.indexOf(
            answer.Question.question_group.toUpperCase()
          );
          const arrAnswers = [];
          if (acceptedIdx.includes(groupIdx)) {
            arrAnswers.push(Number(answer.answer_value) || 0);
            data.push(arrAnswers.reduce((a, b) => Number(a) + Number(b)));
          }
        });
      });
      if (data.length >= 1) {
        return (
          (data.reduce((a, b) => Number(a) + Number(b)) / (data.length * 3)) *
          100
        );
      } else {
        return 0;
      }
    }
  };

  const setTrabajoPersonal = (participants = {}) => {
    if (participants) {
      const data = [];
      participants.map((participant) => {
        participant?.Answers?.forEach((answer, aIdx) => {
          const acceptedIdx = [
            21, 22, 24, 25, 26, 27, 32, 33, 30, 31, 14, 0, 1, 2, 3, 4, 5, 6, 7,
            8, 9, 12, 13,
          ];
          const groupIdx = groups.indexOf(
            answer.Question.question_group.toUpperCase()
          );
          const arrAnswers = [];
          if (acceptedIdx.includes(groupIdx)) {
            arrAnswers.push(Number(answer.answer_value) || 0);
            data.push(arrAnswers.reduce((a, b) => Number(a) + Number(b)));
          }
        });
      });
      if (data.length >= 1) {
        return (
          (data.reduce((a, b) => Number(a) + Number(b)) / (data.length * 3)) *
          100
        );
      } else {
        return 0;
      }
    }
  };

  const setParticipacion = (participants = {}) => {
    if (participants) {
      const data = [];
      participants.map((participant) => {
        participant?.Answers?.forEach((answer, aIdx) => {
          const acceptedIdx = [
            0, 9, 10, 11, 13, 15, 17, 18, 20, 21, 24, 26, 27, 28, 29, 30, 31,
            33,
          ];
          const groupIdx = groups.indexOf(
            answer.Question.question_group.toUpperCase()
          );
          const arrAnswers = [];
          if (acceptedIdx.includes(groupIdx)) {
            arrAnswers.push(Number(answer.answer_value) || 0);
            data.push(arrAnswers.reduce((a, b) => Number(a) + Number(b)));
          }
        });
      });
      if (data.length >= 1) {
        return (
          (data.reduce((a, b) => Number(a) + Number(b)) / (data.length * 3)) *
          100
        );
      } else {
        return 0;
      }
    }
  };

  const setEmancipacion = (participants = {}) => {
    if (participants) {
      const data = [];
      participants.map((participant) => {
        participant?.Answers?.forEach((answer, aIdx) => {
          const acceptedIdx = [
            12, 1, 4, 6, 8, 10, 14, 15, 16, 17, 18, 19, 20, 21, 22, 24, 25, 26,
            27, 28, 29, 30, 31, 32, 33, 34,
          ];
          const groupIdx = groups.indexOf(
            answer.Question.question_group.toUpperCase()
          );
          const arrAnswers = [];
          if (acceptedIdx.includes(groupIdx)) {
            arrAnswers.push(Number(answer.answer_value) || 0);
            data.push(arrAnswers.reduce((a, b) => Number(a) + Number(b)));
          }
        });
      });
      if (data.length >= 1) {
        return (
          (data.reduce((a, b) => Number(a) + Number(b)) / (data.length * 3)) *
          100
        );
      } else {
        return 0;
      }
    }
  };

  const setIndiceGeneral = (participants = {}) => {
    if (participants) {
      const data = [];
      participants.map((participant) => {
        participant?.Answers?.forEach((answer, aIdx) => {
          const arrAnswers = [];
          arrAnswers.push(
            Number(answer.answer_value) > 3
              ? 3
              : Number(answer.answer_value) || 0
          );
          data.push(arrAnswers.reduce((a, b) => Number(a) + Number(b)));
        });
      });

      if (data.length >= 1) {
        return (
          (data.reduce((a, b) => Number(a) + Number(b)) / (data.length * 3)) *
          100
        );
      } else {
        return 0;
      }
    }
  };

  const renderData = (allFilters) => {
    if (singleDimension) {
      return (
        <div>
          {allFilters && (
            <div>
              Filtros utilizados:
              {allFilters.map((filter) => (
                <li>{filterNames[filter]}</li>
              ))}
            </div>
          )}
          <div className="row">
          <SingleReportButton
            body={targetRef}
            orientation={"l"}
            participant_name={"Reporte comparativo de participantes"}
            text={"Descargar informe PDF"}
          />
          <DownloadTableExcel
            filename="participantesFiltrado"
            sheet="participantes"
            currentTableRef={targetRef.current}
          >
            <button
              className="btn btn-success btn-cons m-t-10 col-4 text-sm"
              style={{ minWidth: "250px" }}
            >
              {" "}
              Exportar informe Excel{" "}
            </button>
          </DownloadTableExcel>
        </div>
          <table
            ref={targetRef}
            className="table table-striped"
            style={{ border: "1px solid #ccc", width: "100%" }}
          >
            <thead>
              <th>Filtros</th>
              <th>Cuidado y autocuidado</th>
              <th>Trabajo personal y activación de competencias</th>
              <th>Participación y vida en la Comunidad</th>
              <th>Emancipación</th>
              <th>Indice general de resultados</th>
            </thead>
            <tbody>
              {Object.keys(filteredParticipants).map((key, idx) => (
                <tr>
                  <td>{key}</td>
                  <td>
                    {setCuidadoAutocuidado(filteredParticipants[key]).toFixed(
                      2
                    )}
                  </td>
                  <td>
                    {setTrabajoPersonal(filteredParticipants[key]).toFixed(2)}
                  </td>
                  <td>
                    {setParticipacion(filteredParticipants[key]).toFixed(2)}
                  </td>
                  <td>
                    {setEmancipacion(filteredParticipants[key]).toFixed(2)}
                  </td>
                  <td>
                    {setIndiceGeneral(filteredParticipants[key]).toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return (
      <div>
        {allFilters && (
          <div>
            Filtros utilizados:
            {allFilters.map((filter) => (
              <div>{filterNames[filter]}</div>
            ))}
          </div>
        )}
        <div className="row">
          <SingleReportButton
            body={targetRef}
            orientation={"l"}
            participant_name={"Reporte comparativo de participantes"}
            text={"Descargar informe PDF"}
          />
          <DownloadTableExcel
            filename="participantesFiltrado"
            sheet="participantes"
            currentTableRef={targetRef.current}
          >
            <button
              className="btn btn-success btn-cons m-t-10 col-4 text-sm"
              style={{ minWidth: "250px" }}
            >
              {" "}
              Exportar informe Excel{" "}
            </button>
          </DownloadTableExcel>
        </div>
        <table
          ref={targetRef}
          className="table table-striped"
          style={{ border: "1px solid #ccc" }}
        >
          <thead>
            <th>Filtros</th>
            <th>Cuidado y autocuidado</th>
            <th>Trabajo personal y activación de competencias</th>
            <th>Participación y vida en la Comunidad</th>
            <th>Emancipación</th>
            <th>Indice general de resultados</th>
          </thead>
          <tbody>
            {Object.keys(filteredParticipants).map((key, idx) => (
              <>
                <tr>
                  <td className="filter1">{key}</td>
                  <td className="filter1"></td>
                  <td className="filter1"></td>
                  <td className="filter1"></td>
                  <td className="filter1"></td>
                  <td className="filter1"></td>
                </tr>
                {Object.keys(filteredParticipants[key]).map((item, id) => (
                  <tr>
                    <>
                      <td>{item}</td>
                      <td>
                        {setCuidadoAutocuidado(
                          filteredParticipants[key][item]
                        ).toFixed(2)}
                      </td>
                      <td>
                        {setTrabajoPersonal(
                          filteredParticipants[key][item]
                        ).toFixed(2)}
                      </td>
                      <td>
                        {setParticipacion(
                          filteredParticipants[key][item]
                        ).toFixed(2)}
                      </td>
                      <td>
                        {setEmancipacion(
                          filteredParticipants[key][item]
                        ).toFixed(2)}
                      </td>
                      <td>
                        {setIndiceGeneral(
                          filteredParticipants[key][item]
                        ).toFixed(2)}
                      </td>
                    </>
                  </tr>
                ))}
              </>
            ))}
          </tbody>
        </table>
        {allFilters && (
          <div>
            Filtros utilizados:
            {allFilters.map((filter,idx) => (
              <div style={{backgroundColor: idx < 1 ? '#addc71' : 'none', width: '150px'}} >{filterNames[filter]}</div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const getParticipantsFiltered = (
    filtersToApply,
    data,
    usePreviousGroup = false
  ) => {
    let filtersToUse = filtersToApply;
    let filterFinished;
    const dataGrouped = {};
    if (data && filtersToUse.length >= 1) {
      if (usePreviousGroup) {
        let previousGroup = Object.keys(data);
        if (previousGroup.length >= 1) {
          previousGroup.map((item) => {
            let result;
            const filterKey = filterKeys[filtersToUse[0]];
            if (Number(filterKey)) {
              result = data[item].reduce(function (r, a) {
                const find = a.Answers?.find(
                  (element) => element.question_id === filterKey
                );
                if (find) {
                  r[find.answer_value] = r[find.answer_value] || [];
                  r[find.answer_value].push(a);
                }
                return r;
              }, Object.create(null));
            } else {
              result = data[item].reduce(function (r, a) {
                if (filtersToUse[0].includes("history")) {
                  if (
                    a.InstitutionalizationHistories?.[0]?.[filterKey] !=
                    undefined
                  ) {
                    r[a.InstitutionalizationHistories?.[0]?.[filterKey]] =
                      r[a.InstitutionalizationHistories?.[0]?.[filterKey]] ||
                      [];
                    r[a.InstitutionalizationHistories?.[0]?.[filterKey]].push(
                      a
                    );
                  }
                } else {
                  r[a[filterKey]] = r[a[filterKey]] || [];
                  r[a[filterKey]].push(a);
                }
                return r;
              }, Object.create(null));
            }
            dataGrouped[item] = result;
            filterFinished = dataGrouped;
          });
        }
      } else {
        let result;
        let filterKey = filterKeys[filtersToUse[0]];
        if (Number(filterKey)) {
          result = data.reduce(function (r, a) {
            const find = a.Answers?.find(
              (element) => element.question_id === filterKey
            );
            if (find) {
              r[find.answer_value] = r[find.answer_value] || [];
              r[find.answer_value].push(a);
            }
            return r;
          }, Object.create(null));
        } else {
          result = data.reduce(function (r, a) {
            if (filtersToUse[0].includes("history")) {
              if (
                a.InstitutionalizationHistories?.[0]?.[filterKey] != undefined
              ) {
                r[a.InstitutionalizationHistories?.[0]?.[filterKey]] =
                  r[a.InstitutionalizationHistories?.[0]?.[filterKey]] || [];
                r[a.InstitutionalizationHistories?.[0]?.[filterKey]].push(a);
              }
            } else {
              r[a[filterKey]] = r[a[filterKey]] || [];
              r[a[filterKey]].push(a);
            }
            return r;
          }, Object.create(null));
        }
        filterFinished = result;
      }
      filtersToUse.shift();
      if (filtersToUse.length >= 1) {
        return getParticipantsFiltered(filtersToUse, filterFinished, true);
      }
      return filterFinished;
    } else {
      return null;
    }
  };

  const filterByAge = (data) => {
    let result;
    if (minEdad && maxEdad) {
      const filterResult = data.filter(
        (value) =>
          value.participante_age >= Number(minEdad) &&
          value.participante_age <= Number(maxEdad)
      );
      result = filterResult.reduce(function (r, a) {
        r[a.participante_age] = r[a.participante_age] || [];
        r[a.participante_age].push(a);
        return r;
      }, Object.create(null));
      return result;
    } else if (minEdad && !maxEdad) {
      const filterResult = data.filter(
        (value) => value.participante_age >= Number(minEdad)
      );
      result = filterResult.reduce(function (r, a) {
        r[a.participante_age] = r[a.participante_age] || [];
        r[a.participante_age].push(a);
        return r;
      }, Object.create(null));
      return result;
    } else if (maxEdad && !minEdad) {
      const filterResult = data.filter(
        (value) => value.participante_age <= Number(maxEdad)
      );
      result = filterResult.reduce(function (r, a) {
        r[a.participante_age] = r[a.participante_age] || [];
        r[a.participante_age].push(a);
        return r;
      }, Object.create(null));
      return result;
    } else {
      return data;
    }
  };

  return (
    <div className="page-content-wrapper">
      <div className="content" style={{ paddingTop: "60px" }}>
        <PageContainer>
          <h2>Generador de reporte con filtros</h2>
          <div className="fields m-4">
            <h3 className="row">Información del participante</h3>
            <div className="row fieldGroup">
              <div className="col-2">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="gender"
                    onClick={() => setGenero((prevState) => !prevState)}
                    checked={genero}
                    onChange={() => {}}
                  />
                  <Label htmlFor="gender">Género</Label>
                </div>
              </div>
              <div className="col-2">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="nacionalidad"
                    onClick={() => setNacionalidad((prevState) => !prevState)}
                    checked={nacionalidad}
                    onChange={() => {}}
                  />
                  <Label htmlFor="nacionalidad">Nacionalidad</Label>
                </div>
              </div>
              
              <div className="col-3">
                <Label>Rango de edad</Label>
                <select
                  name="ageRange"
                  id=""
                  className="form-control"
                  value={ageRange}
                  onChange={(e) => {
                    setAgeRange(e.target.value);
                    setMinEdad(edadMinMap[e.target.value]);
                    setMaxEdad(edadMaxMap[e.target.value]);
                  console.log(edadMinMap[e.target.value], e.target.value, minEdad)
                  }}
                >
                  <option value="10" selected>Selecciona una opción</option>
                  <option value="0">0 - 11</option>
                  <option value="1">12 - 15</option>
                  <option value="2">16 - 17</option>
                  <option value="3">18 - 21</option>
                  <option value="4">22 - 25</option>
                  <option value="5">26 - 29</option>
                  <option value="6">30 - 35</option>
                  <option value="7">36 - 50</option>
                  <option value="8">50 - 65</option>
                  <option value="9">+ de 65</option>
                </select>
              </div>
              {/* <div className="col-3">
                <Label>Edad mínima</Label>
                <InputMask
                  mask="99"
                  maskChar={null}
                  value={minEdad}
                  onChange={(e) => setMinEdad(e.target.value)}
                >
                  {() => (
                    <input
                      type="text"
                      data-v-min="0"
                      data-v-max="99"
                      className="autonumeric form-control"
                    />
                  )}
                </InputMask>
              </div>
              <div className="col-3">
                <Label>Edad Máxima</Label>
                <InputMask
                  mask="99"
                  maskChar={null}
                  value={maxEdad}
                  onChange={(e) => setMaxEdad(e.target.value)}
                >
                  {() => (
                    <input
                      type="text"
                      data-v-min="0"
                      data-v-max="99"
                      className="autonumeric form-control"
                    />
                  )}
                </InputMask>
              </div> */}  
                
            </div>
            <h3 className="row">Historia de institucionalización</h3>
            <div className="row mt-2 fieldGroup">
              <div className="col-2">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="previousAcomodation"
                    onClick={() =>
                      setPreviousAcommodationTime((prevState) => !prevState)
                    }
                    checked={previousAccomodation}
                    onChange={() => {}}
                  />
                  <Label htmlFor="previousAcomodation">
                    ¿Dónde vivías antes de entrar en este programa?
                  </Label>
                </div>
              </div>
              <div className="col-2">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="noHomeState"
                    onClick={() => setNoHomeState((prevState) => !prevState)}
                    checked={noHomeState}
                    onChange={() => {}}
                  />
                  <Label htmlFor="noHomeState">
                    ¿Has estado en situación de calle?
                  </Label>
                </div>
              </div>
              <div className="col-2">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="problematic"
                    onClick={() => setProblematic((prevState) => !prevState)}
                    checked={problematic}
                    onChange={() => {}}
                  />
                  <Label htmlFor="problematic">Problematicas</Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="assaultState"
                    onClick={() => setAssaultState((prevState) => !prevState)}
                    checked={assaultState}
                    onChange={() => {}}
                  />
                  <Label htmlFor="assaultState">
                    ¿Has sufrido alguna situación previa de agresión en el
                    entorno de vivienda o alojamiento en el que has estado?
                  </Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="accomodationTime"
                    onClick={() =>
                      setAcommodationTime((prevState) => !prevState)
                    }
                    checked={acommodationTime}
                    onChange={() => {}}
                  />
                  <Label htmlFor="accomodationTime">
                    ¿Cuánto tiempo lleva viviendo sin un alojamiento que puedas
                    considerar propio y estable?
                  </Label>
                </div>
              </div>
            </div>
            <h3 className="row">Sistema de apoyos</h3>
            <div className="row mt-2 fieldGroup">
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="colectividadVivienda"
                    onClick={() =>
                      setColectividadVivienda((prevState) => !prevState)
                    }
                    checked={colectividadVivienda}
                    onChange={() => {}}
                  />
                  <Label htmlFor="colectividadVivienda">
                    Grado de colectividad de la vivienda
                  </Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="intermitencia"
                    onClick={() =>
                      setIntermitenciaApoyos((prevState) => !prevState)
                    }
                    checked={intermitenciaApoyos}
                    onChange={() => {}}
                  />
                  <Label htmlFor="intermitencia">
                    Grado de intermitencia de apoyos
                  </Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="autonomia"
                    onClick={() =>
                      setAutonomiaEconomia((prevState) => !prevState)
                    }
                    checked={autonomiaEconomia}
                    onChange={() => {}}
                  />
                  <Label htmlFor="autonomia">
                    Grado de autonomía en la economía doméstica
                  </Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="copago"
                    onClick={() => setCopago((prevState) => !prevState)}
                    checked={copago}
                    onChange={() => {}}
                  />
                  <Label htmlFor="intermitencia">Copago</Label>
                </div>
              </div>
            </div>
            <div className="row mt-2 fieldGroup">
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="identidadJuridica"
                    onClick={() =>
                      setIdentidadJuridica((prevState) => !prevState)
                    }
                    checked={identidadJuridica}
                    onChange={() => {}}
                  />
                  <Label htmlFor="identidadJuridica">Identidad jurídica</Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="atencionPsicologica"
                    onClick={() =>
                      setAtencionPsicologica((prevState) => !prevState)
                    }
                    checked={atencionPsicologica}
                    onChange={() => {}}
                  />
                  <Label htmlFor="atencionPsicologica">
                    Apoyos - Atención Psicológica
                  </Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="asesoramiento"
                    onClick={() =>
                      setAsesoramientoJuridico((prevState) => !prevState)
                    }
                    checked={asesoramientoJuridico}
                    onChange={() => {}}
                  />
                  <Label htmlFor="asesoramiento">
                    Apoyos - Asesoramiento Jurídico
                  </Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="itinerarioFormativo"
                    onClick={() =>
                      setItinerarioFormativo((prevState) => !prevState)
                    }
                    checked={itinerarioFormativo}
                    onChange={() => {}}
                  />
                  <Label htmlFor="itinerarioFormativo">
                    Apoyos - Itinerario Formativo Laboral
                  </Label>
                </div>
              </div>
            </div>
            <div className="row mt-2 fieldGroup">
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="planMentoria"
                    onClick={() => setPlanMentoria((prevState) => !prevState)}
                    checked={planMentoria}
                    onChange={() => {}}
                  />
                  <Label htmlFor="planMentoria">Plan de mentoría</Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="asambleasConvivenciales"
                    onClick={() =>
                      setAsambleasConvivenciales((prevState) => !prevState)
                    }
                    checked={asambleasConvivenciales}
                    onChange={() => {}}
                  />
                  <Label htmlFor="asambleasConvivenciales">
                    Participación - Asambleas convivenciales
                  </Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="ocioIndividual"
                    onClick={() => setOcioIndividual((prevState) => !prevState)}
                    checked={ocioIndividual}
                    onChange={() => {}}
                  />
                  <Label htmlFor="ocioIndividual">
                    Participación - Ocio Individual
                  </Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="ocioGrupal"
                    onClick={() => setOcioGrupal((prevState) => !prevState)}
                    checked={ocioGrupal}
                    onChange={() => {}}
                  />
                  <Label htmlFor="ocioGrupal">
                    Participación - Ocio Grupal
                  </Label>
                </div>
              </div>
            </div>
            <div className="row mt-2 fieldGroup">
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="voluntariado"
                    onClick={() => setVoluntariado((prevState) => !prevState)}
                    checked={voluntariado}
                    onChange={() => {}}
                  />
                  <Label htmlFor="voluntariado">Voluntariado</Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="incipitMapeo"
                    onClick={() => setIncipitMapeo((prevState) => !prevState)}
                    checked={incipitMapeo}
                    onChange={() => {}}
                  />
                  <Label htmlFor="incipitMapeo">
                    Autonarrativas - Incipit - Mapeo
                  </Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="incipitCollage"
                    onClick={() => setIncipitCollage((prevState) => !prevState)}
                    checked={incipitCollage}
                    onChange={() => {}}
                  />
                  <Label htmlFor="incipitCollage">
                    Autonarrativas - Incipit - Collage
                  </Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="incipitPensar"
                    onClick={() => setIncipitPensar((prevState) => !prevState)}
                    checked={incipitPensar}
                    onChange={() => {}}
                  />
                  <Label htmlFor="incipitPensar">
                    Autonarrativas - Incipit - Bueno para pensar
                  </Label>
                </div>
              </div>
            </div>
            <div className="row mt-2 fieldGroup">
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="discoForum"
                    onClick={() => setDiscoForum((prevState) => !prevState)}
                    checked={discoForum}
                    onChange={() => {}}
                  />
                  <Label htmlFor="discoForum">
                    Autonarrativas - Ruit - Disco Forum
                  </Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="carpeDiem"
                    onClick={() => setCarpeDiem((prevState) => !prevState)}
                    checked={carpeDiem}
                    onChange={() => {}}
                  />
                  <Label htmlFor="carpeDiem">
                    Autonarrativas - Ruit - Carpe Diem
                  </Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="ecomapa"
                    onClick={() => setEcomapa((prevState) => !prevState)}
                    checked={ecomapa}
                    onChange={() => {}}
                  />
                  <Label htmlFor="ecomapa">
                    Autonarrativas - Ruit - Ecomapa
                  </Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="lineaFuturo"
                    onClick={() => setLineaDeFuturo((prevState) => !prevState)}
                    checked={lineaDeFuturo}
                    onChange={() => {}}
                  />
                  <Label htmlFor="lineaFuturo">
                    Autonarrativas - Exit - Línea de futuro
                  </Label>
                </div>
              </div>
            </div>
            <div className="row mt-2 fieldGroup">
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="podcast"
                    onClick={() => setPodcast((prevState) => !prevState)}
                    checked={podcast}
                    onChange={() => {}}
                  />
                  <Label htmlFor="podcast">
                    Autonarrativas - Exit - Podcast
                  </Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="contextualizacion"
                    onClick={() =>
                      setContextualizacionCierre((prevState) => !prevState)
                    }
                    checked={contextualizacionCierre}
                    onChange={() => {}}
                  />
                  <Label htmlFor="contextualizacion">
                    Autonarrativas - Exit - Contextualización de cierre
                  </Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="pactos"
                    onClick={() => setPactosCuidados((prevState) => !prevState)}
                    checked={pactosCuidados}
                    onChange={() => {}}
                  />
                  <Label htmlFor="pactos">Salud - Pactos de cuidados</Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="mapasAlma"
                    onClick={() => setMapasAlma((prevState) => !prevState)}
                    checked={mapasAlma}
                    onChange={() => {}}
                  />
                  <Label htmlFor="mapasAlma">Salud - Mapas del Alma</Label>
                </div>
              </div>
            </div>
            <div className="row mt-2 fieldGroup">
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="tallerCuidados"
                    onClick={() => setTallerCuidados((prevState) => !prevState)}
                    checked={tallerCuidados}
                    onChange={() => {}}
                  />
                  <Label htmlFor="tallerCuidados">
                    Salud - Taller de cuidados
                  </Label>
                </div>
              </div>
              <div className="col-3">
                <div className="form-check complete">
                  <Input
                    type="checkbox"
                    id="mapasCorporales"
                    onClick={() =>
                      setMapasCorporales((prevState) => !prevState)
                    }
                    checked={mapasCorporales}
                    onChange={() => {}}
                  />
                  <Label htmlFor="mapasCorporales">
                    Salud - Mapas corporales
                  </Label>
                </div>
              </div>
            </div>

            <div className="row">
              <button
                className="col-2 m-4 btn btn-primary btn-cons"
                onClick={filterParticipants}
              >
                Generar reporte
              </button>
            </div>
          </div>
        </PageContainer>
        <PageContainer>
          {filteredParticipants && renderData(allFilters)}
        </PageContainer>
      </div>
    </div>
  );
};

export default Filtrado;
