import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { dateFormat } from "../../../../../helpers/utils";
import { CONSTANTS } from "../../../../../helpers/constants";

const ViewFormModal = ({ visible, currentData, closeHandler }) => {
  const [conditionalEntities, setConditionalEntities] = useState([]);

  const getEntities = async () => {
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/entity`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': CONSTANTS.TOKEN
      }
    });
    const res = await req.json();
    if (res.success && res.data.length) return res.data;
    return null;
  }

  useEffect(() => {
    (async () => {
      if (currentData?.question_allowed_entities) {
        let entitiesList = null;
        const parsedEntities = JSON.parse(currentData?.question_allowed_entities);
        if (parsedEntities.length) entitiesList = await getEntities();
        const entities = parsedEntities.map(id => entitiesList.filter(entity => id === entity.entity_id ?? entity)[0]?.entity_name);
        setConditionalEntities(entities);
      }
    })()
  }, [currentData])


  return (
    <Modal show={visible} onHide={closeHandler} onEscapeKeyDown={closeHandler} onExit={closeHandler} size="lg">
      <Modal.Header closeButton>
        <h4>Pregunta: <b>{currentData.question_key}</b></h4>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6">
            <p>Creada el: <b>{dateFormat(currentData.createdAt, {includeHours: true})}</b></p>
          </div>
          <div className="col-md-6">
            <p>Ultima actualización: <b>{dateFormat(currentData.updatedAt, {includeHours: true})}</b></p>
          </div>
          <div className="col-md-6">
            <p>Tipo de pregunta: {currentData.question_type}</p>
          </div>
          <div className="col-md-6">
            <p>Estado: pregunta {currentData.question_active ? 'activa' : 'inactiva'}</p>
          </div>
        </div>
        <div className="row m-t-10 m-b-10">
          <div className="col-md-12">
            <p>Texto: {currentData.question_text}</p>
          </div>
        </div>
        {conditionalEntities && conditionalEntities.length ?
          <div className="row">
            <div className="col-md-12">
              <h5>Pregunta condicionada a las siguientes entidades:</h5>
              <div>{conditionalEntities.map((entity, index) => <p key={index}>{index + 1}.- {entity}</p>)}</div>
            </div>
          </div> : null
        }
      </Modal.Body>
      <Modal.Footer>
        <div className="btn btn-cons" onClick={closeHandler}>Cerrar</div>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewFormModal;
