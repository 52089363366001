import React, { useEffect, useState } from "react";

import Select from "react-select";
import { stageOptions } from "../Elements/selectData";
import data from "../Meddi/data.json";

const Component = ({ onSelectTabs }) => {
    const [options, setOptions] = useState([])

    useEffect(() => {
        const tabOptions = []
        data.tabs.map((tab) => {
            tabOptions.push({
                value: `${tab.tab_id}`,
                label: tab.tab_name
            })
        });
        setOptions(tabOptions)
    }, [])
  
  return (
    <Select
      defaultValue={{label: data.tabs[0].tab_name, value: data.tabs[0].tab_id}}
      options={options}
      onChange={(optionSelected) => {
        return onSelectTabs(optionSelected.value);
      }}
    />
  );
};

export default Component;
