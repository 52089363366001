import React, { useState, useEffect } from "react";
import PageContainer from "../../UIElements/Containers";
import "react-quill/dist/quill.snow.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import FlipBarNotifyModule from "../../UIElements/Notification/FlipBarNotification/FlipBarNotifyModule";
import { CONSTANTS } from "../../../helpers/constants";
import "../Elements/styles.css";

import { ValidatorForm } from "react-form-validator-core";
import FormDataRecord from "./FormDataRecord";

const Record = ({ path }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [notification, setNotification] = useState(null);
  const [participant, setParticipant] = useState();
  const [dynamicForm, setDynamicForm] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);

  //getParticipant
  useEffect(() => {
    (async () => {
      const req = await fetch(`${CONSTANTS.base_url}api/v1/data/participants/${urlParams.get('participante_id')}`, {
        headers: {
          'x-access-token': CONSTANTS.TOKEN
        }
      });
      const res = await req.json();
      setParticipant(res.data)
    })()
  }, []);

  useEffect(() => {
    (async () => {
      const req = await fetch(`${CONSTANTS.base_url}api/v1/data/form/1`, { headers: { 'x-access-token': CONSTANTS.TOKEN }});
      const reqAnswers = await fetch(`${CONSTANTS.base_url}api/v1/data/answer/created/${urlParams.get('date')}/${urlParams.get('participante_id')}`, { headers: { 'x-access-token': CONSTANTS.TOKEN }});
      const res = await req.json();
      const resAnswers = await reqAnswers.json();
      if (res.data && res.data.Questions.length) {
        const groups = res.data.Questions.reduce((acc, currentValue) => {
          let groupKey = currentValue.question_group;
          if (currentValue.question_allowed_entities && JSON.parse(currentValue.question_allowed_entities).length) {
            if (JSON.parse(currentValue.question_allowed_entities).includes(JSON.parse(sessionStorage.getItem('user')).entity)) {
              if (!acc[groupKey]) acc[groupKey] = [];
              acc[groupKey].push(currentValue);
            }
          } else {
            if (!acc[groupKey]) acc[groupKey] = [];
            acc[groupKey].push(currentValue);
          }
          return acc;
        }, {});
        setDynamicForm(groups);
      }
      if (resAnswers.data && resAnswers.data.length) {
        const preloadAnswers = {};
        resAnswers.data.map(answer => {
          preloadAnswers[`question_id_${answer.question_id}`] = {
            participante_id: answer.participante_id.toString(),
            question_id: answer.question_id,
            answer_value: answer.answer_value,
            answer_createdAt: answer.answer_createdAt,
            answer_date: answer.answer_date,
            answer_id: answer.answer_id
          }
        })
        setFormData(preloadAnswers);
        console.log(formData)
      } else {
        setFormData({});
      }
    })()
  }, [])

  useEffect(() => {
    if (notification && notification.timeout) {
      setTimeout(() => {
        setNotification(null);
      }, notification.timeout);
    }
  }, [notification])

  const handleFormSubmit = async () => {
    const answers = Object.values(formData);
    answers.map(async (answer) => {
      const urlToFetch = `${CONSTANTS.base_url}api/v1/data/answer${answer.answer_id ? `/${answer.answer_id}` : ''}`
      const req = await fetch(urlToFetch,
        {
          method: answer.answer_id ? "PUT" : 'POST',
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-access-token": CONSTANTS.TOKEN,
          },
          body: JSON.stringify(answer),
        }
      );
      const res = await req.json();
      if (res.success) {
        setNotification({ message: res.mensaje, timeout: 5000 });
      } else {
        setNotification({ message: res.mensaje, type: "error", timeout: 5000 });
      }
    });
  };

  return (
    <div className="page-content-wrapper ">
      {notification && <FlipBarNotifyModule notifications={[{ type: notification.type || 'success', desc: notification.message }]} position={notification.position || 'top-right'} />}
      {/* START PAGE CONTENT */}
      {participant &&
        <div
            className="content "
            style={{paddingTop: "60px",}}
        >
          <PageContainer>
            <div className="row">
                <div className="col-8">
                <h3>Sistema de Apoyos de {participant.participante_name}</h3>
                <small>Registrado el: {urlParams.get('date')}</small>
                </div>
            </div>

            <ValidatorForm instantValidate={true} onSubmit={handleFormSubmit} className="p-t-15">
              <div className="row">
                {(dynamicForm && formData) && Object.keys(dynamicForm).map((key, index) => (
                  <FormDataRecord key={index} data={dynamicForm[key]} group={key} handleChange={setFormData} formData={formData} preCreatedAt={urlParams.get('date')} />
                ))}
              </div>
              <button aria-label="" className="btn btn-primary btn-cons m-t-10" type="submit" disabled={loading}>{!loading ? 'Guardar' : 'Guardando...'}</button>
            </ValidatorForm>
          </PageContainer>
        </div>
      }
      {/* END PAGE CONTENT */}
    </div>
  );
};

export default Record;