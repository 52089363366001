import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import { Radar } from "react-chartjs-2";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export const radarChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      fullSize: false,
      labels: {
        color: 'rgb(255, 99, 132)'
    }
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    }
  },
  scales: {
    r: {
      max: 3,
      min: 0,
      ticks: {
        stepSize: 0.5,
      },
    },
  },
};

const chartStyles = {
  display: "flex",
  justifyContent: "center",
  width: 'auto',
  height: '1800px'
};

const RadarChart = ({ data, id }) => {
  return (
    <div className="page-content-wrapper" style={chartStyles} id="radarChart">
      <Radar options={radarChartOptions} data={data} tabIndex={id} />
    </div>
  );
};

export default RadarChart;
