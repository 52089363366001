import React, { useEffect, useState } from "react";
import Select from "react-select";
import Label from "../Elements/Label";
import WithoutMsgValidation from "../FormLayouts/WithoutMsgValidation";
// import DatePicker from "reactstrap-date-picker";
import DatePickerAlias from "react-datepicker";
// import moment from "moment";
import "./formData.scss";

const FormDataRecord = ({
  data,
  group = "",
  handleChange,
  formData,
  preCreatedAt,
}) => {
  const [userId, setUserId] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setUserId(urlParams.get("participante_id"));
    console.log(formData);
  }, []);

  const handleAddResponse = (value, row) => {
    handleChange({
      ...formData,
      [`question_id_${row.question_id}`]: {
        participante_id: userId,
        question_id: row.question_id,
        answer_id: formData[`question_id_${row.question_id}`]?.answer_id,
        answer_value: value,
        answer_createdAt:
          preCreatedAt ?? new Date().toISOString().split("T")[0],
        answer_date: formData[`question_id_${row.question_id}`]?.answer_date ?? null
      },
    });
  };

  const handleAddAnswerDate = (value, row) => {
    console.log(formData[`question_id_${row.question_id}`])
    handleChange({
      ...formData,
      [`question_id_${row.question_id}`]: {
        participante_id: userId,
        question_id: row.question_id,
        answer_id: formData[`question_id_${row.question_id}`]?.answer_id,
        answer_value: formData[`question_id_${row.question_id}`].answer_value,
        answer_createdAt:
          preCreatedAt ?? new Date().toISOString().split("T")[0],
        answer_date: value,
      },
    });
  };

  return (
    <div className="col-lg-12">
      <div className="card card-default">
        <div className="card-body">
          <h4>{group}</h4>
          <div className="row">
            {data.map((row, index) => {
              let options = [];
              if (row.question_type === "multiple")
                options = JSON.parse(row.question_options).map((option) => ({
                  value: option,
                  label: option,
                }));
              return (
                <div
                  key={index}
                  className={`col-md-${12 / (row.question_width || 3)} m-b-20`}
                >
                  {row.question_type === "abierta" ? (
                    <WithoutMsgValidation
                      onChange={(e) => handleAddResponse(e.target.value, row)}
                      value={
                        formData[`question_id_${row.question_id}`]
                          ?.answer_value || ""
                      }
                      placeholder={"Introduzca los datos"}
                      type={"text"}
                      id={`question_id_${row.question_id}`}
                      validators={["required"]}
                      errorMessages={["Este campo es obligatorio"]}
                      className={"form-control"}
                      label={row.question_text}
                      require="true"
                    />
                  ) : (
                    <div>
                      <Label>{row.question_text}</Label>
                      <Select
                        options={options}
                        className="answerOptions"
                        defaultValue={{
                          label:
                            formData[`question_id_${row.question_id}`]
                              ?.answer_value || "Selecciona una opción",
                          value:
                            formData[`question_id_${row.question_id}`]
                              ?.answer_value || "",
                        }}
                        onChange={(e) => handleAddResponse(e.value, row)}
                      />
                      <div className="relative answeredDate">
                        Contestada el:
                        <DatePickerAlias
                          selected={
                            formData[`question_id_${row.question_id}`]
                              ?.answer_date
                              ? formData[`question_id_${row.question_id}`]
                                  ?.answer_date !== "0000-00-00"
                                ? new Date(formData[`question_id_${row.question_id}`]
                                    ?.answer_date)
                                : null
                              : null
                          }
                          minDate={"01/01/1980"}
                          placeholderText="Selecciona una fecha"
                          onChange={(value) => handleAddAnswerDate(value, row)}
                          showClearButton={false}
                          // maxDate={new Date().toISOString().split('T')[0]}
                          disabled={!formData[`question_id_${row.question_id}`]}
                        />
                        {/* <DatePicker
                          value={
                            formData[`question_id_${row.question_id}`]
                              ?.answer_date
                              ? formData[`question_id_${row.question_id}`]
                                  ?.answer_date !== "0000-00-00"
                                ? formData[`question_id_${row.question_id}`]
                                    ?.answer_date
                                : null
                              : null
                          }
                          onChange={(value) => handleAddAnswerDate(value, row)}
                          showClearButton={false}
                          // maxDate={new Date().toISOString().split('T')[0]}
                          disabled={!formData[`question_id_${row.question_id}`]}
                        /> */}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormDataRecord;
