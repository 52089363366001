import React from "react";
import generatePDF, { Margin } from "react-to-pdf";

const SingleReportButton = ({ body, participant_name, orientation, text = "Descargar informe del participante" }) => {
  const date = new Date();
  const options = {
    filename: `${participant_name}_${date.getDate() + 1}-${
      date.getMonth() + 1
    }-${date.getFullYear()}.pdf`,
    // resolution: Resolution.HIGH,
    page: {
      margin: Margin.MEDIUM,
      // format: "letter",
      orientation: orientation || "portrait" ,
    },
    // canvas: {
    //   mimeType: "image/png",
    //   qualityRatio: 1,
    // },
    // overrides: {
    //   pdf: {
    //     compress: true,
    //   },
    //   canvas: {
    //     useCORS: true,
    //   },
    // },
  };

  return (
    <div className="w-25 text-center">
      <button onClick={() => generatePDF(body, options)} className="btn btn-secondary btn-cons m-t-10 col-4 text-sm" style={{minWidth: '250px'}}>{text}</button>
    </div>
  );
};

export default SingleReportButton;
