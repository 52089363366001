import React from "react";
import DashboardsWrapperComponent from "./Content";
import Evolutivo from "./Evolutivo";
import Filtrado from "./Filtrado";
import Comparativo from "./Comparativo";

const Component = ({ location }) => {
  let path = location.pathname;
	return (
    <div className={path.includes("/executive") || path.includes("/casual") ? "no-padding page-container" : "page-container"}>
		{
			path.includes('/evolutivo') ?  <Evolutivo/> : 
			path.includes('/filtrado') ? <Filtrado /> :
			path.includes('/comparativo') ? <Comparativo /> :
			<DashboardsWrapperComponent path={path} />
		}
		</div>
	);
};

export default Component;
