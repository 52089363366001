import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ValidatorForm } from "react-form-validator-core";
import WithoutMsgValidation from "../../../../Forms/FormLayouts/WithoutMsgValidation";
import Select from 'react-select';
import { Typeahead } from "react-bootstrap-typeahead";
import { CONSTANTS } from "../../../../../helpers/constants";
import { Input } from "reactstrap";

const NewQuestionModal = ({ visible, currentForm, closeHandler, successCallback, failCallback }) => {
  const [questionData, setQuestionData] = useState({});
  const [checkEntities, setCheckEntities] = useState(false);
  const [entitiesList, setEntitiesList] = useState([]);
  const questionGroups = [];
  currentForm?.Questions?.map(({ question_group }) => question_group && !questionGroups.includes(question_group) && questionGroups.push(question_group));

  const handleSendForm = async () => {
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/question`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': CONSTANTS.TOKEN
      },
      body: JSON.stringify({
        form_id: currentForm.form_id,
        question_text: questionData?.questionText,
        question_type: questionData?.questionType || 'abierta',
        question_options: JSON.stringify(questionData?.questionOptions) || '',
        question_group: questionData?.questionGroup || '',
        question_width: questionData?.questionWidth || 1,
        question_allowed_entities: JSON.stringify(questionData?.showInEntities || [])
      })
    });
    const res = await req.json();
    res.success ? successCallback(res.data) : failCallback(res);
    setQuestionData({});
  }
  const handleClose = () => {
    setQuestionData({});
    closeHandler();
  }
  const getEntities = async () => {
    const req = await fetch(`${CONSTANTS.base_url}api/v1/data/entity`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': CONSTANTS.TOKEN
      }
    });
    const res = await req.json();
    if (res.success && res.data.length) setEntitiesList(res.data);
  }

  useEffect(() => {
    if (checkEntities && !entitiesList.length) getEntities();
    if (!checkEntities) setQuestionData({ ...questionData, showInEntities: [] });
  }, [checkEntities, entitiesList])

  return (
    <Modal show={visible} onHide={handleClose} onEscapeKeyDown={handleClose} onExit={handleClose}>
      <ValidatorForm instantValidate={true} onSubmit={handleSendForm} className="p-t-15">
        <Modal.Header closeButton>
          <h4>Nueva pregunta</h4>
        </Modal.Header>
        <Modal.Body>
          <p className="small-text">Crea una nueva pregunta para el formulario: <b>{currentForm.form_name}</b></p>
          <div className="row">
            <div className="col-md-12">
              <WithoutMsgValidation
                onChange={(e) => setQuestionData({ ...questionData, questionText: e.target.value })} value={questionData?.questionText || ''}
                name="questionText" placeholder="Texto de la pregunta" type="text"
                validators={["required"]} errorMessages={["Este campo es obligatorio"]} className={"form-control"} label="Pregunta" require="true"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 m-b-10">
              <Select name="questionType" defaultValue={{ value: '', label: 'Tipo de pregunta' }} options={[
                { value: 'abierta', label: 'Abierta' },
                { value: 'multiple', label: 'Opción multiple' },
              ]}
                onChange={(e) => setQuestionData({ ...questionData, questionType: e.value })}
              />
            </div>
          </div>
          {questionData?.questionType === 'multiple' &&
            <div className="row">
              <div className="col-md-12 m-b-10">
                <Typeahead id="questionOptions" allowNew multiple options={[]}
                  onChange={(e) => setQuestionData({ ...questionData, questionOptions: e.map(option => option.label) })}
                  newSelectionPrefix="Agregar opción: " placeholder="Escribe las opciones de respuesta..."
                />
              </div>
            </div>
          }
          <div className="row">
            <div className="col-md-12 m-b-10">
              <Typeahead id="questionGroup" allowNew options={questionGroups} clearButton={true}
                onChange={(selected) => setQuestionData({ ...questionData, questionGroup: selected[0]?.label || selected[0] })}
                newSelectionPrefix="Agregar grupo: " placeholder="Escribe el grupo..."
              />
              {/* <Select name="questionGroup" defaultValue={{ value: '', label: 'Grupo' }}
                options={questionGroups.map(group => ({ value: group, label: group }))}
                onChange={(e) => setQuestionData({ ...questionData, questionGroup: e.value })}
              /> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 m-b-10">
              <WithoutMsgValidation
                onChange={(e) => setQuestionData({ ...questionData, questionWidth: e.target.value })} value={questionData?.questionWidth || ''}
                name="questionWidth" placeholder="Medida que tomara la pregunta en el formulario" type="text"
                validators={["isNumber"]} errorMessages={["solo numeros"]} className={"form-control"} label="Medida de la pregunta"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 m-b-10">
              <div className="form-check form-check-inline complete">
                <Input type="checkbox" name="conditionalEntityCheck" id="conditionalEntityCheck" value="Default" onChange={() => setCheckEntities(!checkEntities)} />
                <label htmlFor="conditionalEntityCheck">Activación condicional por entidad</label>
              </div>
              {checkEntities &&
                <Typeahead id="conditionalEntityList" multiple options={entitiesList.map(entity => ({ value: entity.entity_id, label: entity.entity_name }))}
                  onChange={(e) => setQuestionData({ ...questionData, showInEntities: e.map(option => option.value) })}
                  placeholder="Seleccionar entidad(es)..."
                />
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary btn-cons" type="submit">Guardar</button>
          <div className="btn btn-cons" onClick={closeHandler}>Cancelar</div>
        </Modal.Footer>
      </ValidatorForm>
    </Modal>
  );
};

export default NewQuestionModal;
