import { CONSTANTS } from "../helpers/constants";

const login = async (userName, password) => {
  const req = await fetch(
    `${CONSTANTS.base_url}${CONSTANTS.api_url}/auth/login`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        correo: userName,
        password: password,
      }),
    }
  );
  const res = await req.json();
  if (res.success) {
    sessionStorage.setItem("logged", res.token);
    sessionStorage.setItem("user", JSON.stringify({ ...res.usuario, rol: res.rol }));
    location.assign("/home");
    return true;
  }

  return false;
};

const logOut = async () => {
    sessionStorage.clear();
    location.assign('/');
};

const updatePasswordForgotten = async (userEmail) => {
  const req = await fetch(
    `${CONSTANTS.base_url}${CONSTANTS.api_url}/data/user/forgotPassword/${userEmail}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    }
  );
  const res = await req.json();
  if (res.success) {
    console.log(res)
    return true;
  }

  return false;
};

export { 
    login,
    logOut,
    updatePasswordForgotten
};
