import { CONSTANTS } from "./constants";

export const isLogged = async () => {
    if(sessionStorage.getItem("logged")){
        const checkToken = await fetch(`${CONSTANTS.base_url}${CONSTANTS.api_url}/auth/check-token`, {
            method: 'GET',
            headers: {
                'x-access-token': sessionStorage.getItem("logged")
            }
        });
        const response = await checkToken.json();

        return response.success;
    }
    return false;
};

export const transformPasswordField = (id) => {
    const input = document.getElementById(id);
    if (!input ) return;

    if(input.type === 'password') {
        input.type = 'text';
        return false;
    }else if(input.type === 'text') {
        input.type = 'password';
        return true;
    }

}

export const dateFormat = (timeStamp, args) => {
  if (!timeStamp) return;
  const config = { separator: ' de ', fullMonth: false, format: 'd/m/y', includeHours: false, ...args };
  const date = new Date(timeStamp);
  const MONTHS = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  const day = date.getDate();
  let month = MONTHS[date.getMonth()];
  const year = date.getFullYear();
  if (!config.fullMonth) month = month.substring(0, 3);
  let hour = date.getHours();
  if (hour < 10) hour = `0${hour}`;
  let minutes = date.getMinutes();
  if (minutes < 10) minutes = `0${minutes}`;
  const arrFormat = config.format.split('/');
  let formatFull = [];
  arrFormat.map((item, i) => {
    if (item.toLowerCase() == 'd') {
      item = day;
    } else if (item.toLowerCase() == 'm') {
      item = month;
    } else if (item.toLowerCase() == 'y') {
      item = year;
    }
    if (i == arrFormat.length - 1) {
      formatFull.push(item);
    } else {
      formatFull.push(item, config.separator);
    }
  })
  formatFull = formatFull.join('');
  if (config.includeHours) {
    return `${formatFull}. ${hour}:${minutes}`;
  } else {
    return formatFull;
  }
}

export const loadImage = (e, callback) => {
  const file = e.target.files[0];
  if (!file.type.match(/image.*/)) return callback(null, 'Tipo de archivo incorrecto');
  const reader = new FileReader();
  reader.onerror = (error) => callback(null, error.message ?? 'unknown');
  reader.onloadend = () => callback(reader.result);
  reader.readAsDataURL(file);
}

export const getAge = (birthDate) => {
  var today = new Date();
  var birth = new Date(birthDate);
  var age = today.getFullYear() - birth.getFullYear();
  var m = today.getMonth() - birth.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birth.getDate())) {
    age--;
  }

  return age;
}

export const getEntities = async () => {
  const req = await fetch(
    `${CONSTANTS.base_url}api/v1/data/entity`,
    {
      headers: {
        "x-access-token": CONSTANTS.TOKEN,
      },
    }
  );

  const res = await req.json();

  return res.data;
}

export const removeConsecutiveDuplicates = (str) => {
  str=str.toString();
    return str.split('').filter((el, i) => i==0 || str[i-1]!=el).join('');
}