import { CONSTANTS } from '../helpers/constants'

export const getMatrizForm = async () => {
  const req = await fetch(`${CONSTANTS.base_url}api/v1/data/form/2`, {
    headers: {
      'x-access-token': CONSTANTS.TOKEN
    }
  });
  const res = await req.json();
  // console.log(res)
  if (res.data && res.data.Questions.length) {
    // const questionsGrouped = Object.groupBy(res.data.Questions, ({ question_group }) => question_group);
    const grouped = res.data.Questions.reduce((acc, currentValue) => {
      let groupKey = currentValue.question_group;
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(currentValue);
      return acc;
    }, {});
    return grouped;
  }
};
