import React, { useState, useEffect } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import DataTable from "../../Tables/DataTable";
import { CONSTANTS } from "../../../helpers/constants";
import "./style.css";

const Content = () => {
  const TABLE_COLUMNS = [
    {
      dataField: "participante_code",
      text: "Código del participante",
      headerAlign: "center",
      align: "center",
      headerStyle: { backgroundColor: "#f0f0f073" },
      sort: true,
      editable: false,
    },
    {
      dataField: "participante_name",
      text: "NOMBRE",
      headerAlign: "center",
      align: "center",
      headerStyle: { backgroundColor: "#f0f0f073" },
      headerTitle: true,
      sort: true,
      editable: false,
    },
    {
      dataField: "participante_lastname",
      text: "Apellido",
      headerAlign: "center",
      align: "center",
      headerStyle: { backgroundColor: "#f0f0f073" },
      headerTitle: true,
      sort: true,
      editable: false,
    },
    {
      dataField: "participante_second_surname",
      text: "Segundo apellido",
      headerAlign: "center",
      align: "center",
      headerStyle: { backgroundColor: "#f0f0f073" },
      headerTitle: true,
      sort: true,
      editable: false,
    },
  ];
  const [participantsSelected, setParticipantsSelected] = useState([]);
  const [participants, setParticipants] = useState();
  const [loader, setLoader] = useState(true);
  const { ExportCSVButton } = CSVExport;
  const { SearchBar } = Search;

  const selectRow = {
    mode: "checkbox",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (
        participantsSelected &&
        participantsSelected.includes(row.participante_id)
      ) {
        const participantFound = participantsSelected.indexOf(
          row.participante_id
        );
        const newsSelected = participantsSelected.splice(participantFound, 1);
        setParticipantsSelected(participantsSelected);
      } else {
        setParticipantsSelected([...participantsSelected, row.participante_id]);
      }
    },
  };

  useEffect(() => {
    const userLogged = JSON.parse(sessionStorage.getItem("user"));
    console.log("userInfo", userLogged);
    (async () => {
      if (userLogged.rol === "admin") {
        const req = await fetch(
          `${CONSTANTS.base_url}api/v1/data/participants/`,
          {
            headers: {
              "x-access-token": CONSTANTS.TOKEN,
            },
          }
        );
        const res = await req.json();
        setParticipants(res.data);
        setLoader(!loader);
        return;
      } else {
        const req = await fetch(
          `${CONSTANTS.base_url}api/v1/data/participants/byUser/${userLogged.entity}`,
          {
            headers: {
              "x-access-token": CONSTANTS.TOKEN,
            },
          }
        );
        const res = await req.json();
        setParticipants(res.data);
        setLoader(!loader);
        return;
      }
    })();
  }, []);

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      location.assign(
        `/participants_form?participant_id=${row.participante_id}`
      );
    },
  };

  return (
    <div className="page-content-wrapper ">
      {participants ? (
        <div className="container p-5">
          <div className="row">
            <div className="col-12  pt-5">
              <div className="widget-11-2 card  no-margin widget-loader-circle full-height d-flex flex-column">
                <div className="p-l-20 p-r-20 p-b-10 p-t-5">
                  <div className="clearfix"></div>
                </div>
                {loader ? (
                  <h3>Cargando Participantes...</h3>
                ) : (
                  <ToolkitProvider
                    keyField="participante_code"
                    bootstrap4
                    data={participants || []}
                    columns={TABLE_COLUMNS}
                    columnToggle={true}
                    search
                    exportCSV={{
                      fileName: `${"participants_spreadsheet"}.csv`,
                    }}
                  >
                    {(props) => {
                      return (
                        <React.Fragment>
                          {/* <ExportCSVButton {...props.csvProps}>
                            Export CSV
                          </ExportCSVButton> */}
                          <div className="card-header">
                            <div className="row">
                              <div className="col-6">
                                <h3 className="text-primary no-margin">
                                  Participantes
                                </h3>
                              </div>
                              <div className="col-1"></div>
                              <div className="col-5 d-flex justify-content-center align-items-center">
                                <a
                                  className="btn btn-primary btn-cons"
                                  href="/participants_form"
                                >
                                  Crear nuevo participante
                                </a>
                                <a
                                  className="btn btn-primary btn-cons"
                                  href="/dashboards/filtrado"
                                >
                                  Generar reporte con filtros
                                </a>
                              </div>
                            </div>
                          </div>
                          <SearchBar
                            {...props.searchProps}
                            placeholder="Buscar... "
                            style={{
                              marginBottom: "5px",
                            }}
                          />
                          <DataTable
                            {...props.baseProps}
                            hover={true}
                            bordered={false}
                            condensed={true}
                            key={"participantes"}
                            noDataIndication={
                              "No tienes participantes registrados."
                            }
                            selectRow={selectRow}
                            rowEvents={tableRowEvents}
                            pagination={paginationFactory({
                              hideSizePerPage: true,
                              hidePageListOnlyOnePage: false,
                              showTotal: true,
                              paginationTotalRenderer: (from, to, size) => (
                                <span className="react-bootstrap-table-pagination-total">
                                  Mostrando {from} - {to} de {size} registros
                                </span>
                              ),
                            })}
                          />
                        </React.Fragment>
                      );
                    }}
                  </ToolkitProvider>
                )}
                {participantsSelected && participantsSelected.length > 1 && (
                  <a
                    className="btn btn-primary btn-cons m-t-10 col w-25 m-b-10"
                    href={`/dashboards/comparativo?participants_selected=${participantsSelected}`}
                  >
                    Generar reporte comparativo
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container p-5">
          <div className="row">
            <div className="col-12 pt-5">
              <div className="widget-11-2 card  no-margin widget-loader-circle full-height d-flex flex-column">
                <div className="p-l-20 p-r-20 p-b-10 p-t-5">
                  <div className="clearfix"></div>
                </div>
                <ToolkitProvider
                  keyField="participante_code"
                  bootstrap4
                  data={participants || []}
                  columns={TABLE_COLUMNS}
                  search
                  exportCSV={{
                    fileName: `${"participants_spreadsheet"}.csv`,
                  }}
                >
                  {(props) => {
                    return (
                      <React.Fragment>
                        {/* <ExportCSVButton {...props.csvProps}>
                            Export CSV
                          </ExportCSVButton> */}
                        <div className="card-header">
                          <div className="row">
                            <div className="col-6">
                              <h3 className="text-primary no-margin">
                                Participantes
                              </h3>
                            </div>
                            <div className="col-2"></div>
                            <div className="col-4 d-flex justify-content-center align-items-center">
                              <a
                                className="btn btn-primary btn-cons"
                                href="/participants_form"
                              >
                                Crear nuevo participante
                              </a>
                            </div>
                          </div>
                        </div>
                        <h3>
                          {loader
                            ? "Cargando Participantes..."
                            : "No tienes participantes registrados"}
                        </h3>
                      </React.Fragment>
                    );
                  }}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* END PAGE CONTENT */}
    </div>
  );
};

export default Content;
