import React, { useState, useEffect } from "react";
import Input from "../Elements/Input";
import { Typeahead } from "react-bootstrap-typeahead";
import Label from "../Elements/Label";
import WithoutMsgValidation from "../FormLayouts/WithoutMsgValidation";
import "./style.css";

const Question = ({ question, options, setAnswer, answerSaved = {} }) => {
  const [value, setValue] = useState("");
  const [multiSelectOne, setMultiSelectOne] = useState([]);
  const [previousOptions, setPreviousOptions] = useState('')
  const [optionActive, setOptionActive] = useState();

  useEffect(() => {
    if (answerSaved) {
      if(question.question_type !== 'multiple'){
        setPreviousOptions(answerSaved.answer_value)
      }else {
        const answerValue = document.getElementById(
          `${answerSaved.question_id}${Number(answerSaved.answer_value)}`
        );
        if (answerValue) {
          answerValue.checked = true;
        }
      }
    }
  }, []);

  return (
    <React.Fragment>
      <h4>{question.question_text}</h4>
      <div style={{ display: "none", border: "0px" }}>
        <WithoutMsgValidation
          name={`text_${question.question_text}`}
          value={value}
          placeholder={"Introzuca los datos"}
          type={"text"}
          id={`question_id_${question.question_id}`}
          // validators={["required"]}
          errorMessages={["Este campo es obligatorio"]}
          require="true"
        />
      </div>
      <br />
      <div className="row no-margin required">
        {question.question_type !== "multiple" ? (
          options && (
            <div style={{ marginBottom: "5rem", width: "100%" }}>
              <Typeahead
                id="multi-select-one"
                labelKey="name"
                clearButton
                // defaultSelected={answerSaved && answerSaved.length >= 1 ? answerSaved.answer_value.split('-') : ''}
                onChange={(e) => {
                  setMultiSelectOne(e);
                  if(answerSaved){
                    setAnswer(
                      question.question_id,
                      question.question_text,
                      `${question.question_id}multi-select-one`,
                      multiSelectOne.join("-"),
                      answerSaved.answer_id
                    );
                  }else{
                    setAnswer(
                      question.question_id,
                      question.question_text,
                      `${question.question_id}multi-select-one`,
                      multiSelectOne.join(",")
                    );
                  }
                }}
                multiple={true}
                options={options}
                placeholder="Selecciona las opciones correspondientes"
              />
              {previousOptions &&
              <div>Opciones seleccionadas previamente{previousOptions}</div>
              }
            </div>
          )
        ) : (
          <React.Fragment>
            {options.map((answer, idx) => (
              <div
                key={idx}
                className={`form-check checkbox-circle complete col-lg-3 col-md-6 answerButton ${
                  optionActive === `${question.question_id}${idx}`
                    ? "checkActive"
                    : ""
                }`}
                style={{
                  padding: "0.2rem",
                  textAlign: "center",
                }}
              >
                <Input
                  type="checkbox"
                  name={`${question.question_text}${question.question_id}`}
                  id={`${question.question_id}${idx}`}
                  value={answer}
                  onClick={() => {
                    setOptionActive(`${question.question_id}${idx}`);
                    if (answerSaved) {
                      setAnswer(
                        question.question_id,
                        question.question_text,
                        `${question.question_id}${idx}`,
                        idx,
                        answerSaved.answer_id
                      );
                    } else {
                      setAnswer(
                        question.question_id,
                        question.question_text,
                        `${question.question_id}${idx}`,
                        idx
                      );
                    }
                    setValue(`${idx}`);
                  }}
                />
                <Label htmlFor={`${question.question_id}${idx}`}>
                  {`${idx}.  ${answer}`}
                </Label>
              </div>
            ))}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default Question;
